import { PercentSectionCard } from '@/entities/statistics/ui'
import React, { useState } from 'react'
import styles from './MarketingStatistic.module.scss'
import { Table } from '@/shared/ui/table'
import { Pagination } from '@/shared/ui/pagination'
import { useTextLocality } from '@/shared/hooks'

export const MarketingStatistic = () => {
	const { getMessage } = useTextLocality()

	const defaultFilter = {
		page: 1,
		count: 10
	}
	const [filter, setFilter] = useState<any>(defaultFilter)
	const sectionsMarketing = [
		{ title: getMessage('conversion'), value: 20 },
		{ title: getMessage('retention_rate'), value: 35 }
	]

	const columns = [
		{
			title: getMessage('trafficSource'),
			dataIndex: 'traffic_source'
		},
		{
			title: getMessage('numberVisitor'),
			dataIndex: 'visitors'
		},
		{
			title: getMessage('numberRegistration'),
			dataIndex: 'registrations'
		},
		{
			title: getMessage('conversion'),
			dataIndex: 'conversion'
		},
		{
			title: getMessage('retention_rate'),
			dataIndex: 'rate'
		},
		{
			title: getMessage('referrals'),
			dataIndex: 'referrals'
		}
	]

	const data = [
		{
			traffic_source: 'Social network',
			visitors: '1 500',
			registrations: '100',
			conversion: '20%',
			rate: '15%',
			referrals: 70
		},

		{
			traffic_source: 'Affiliates',
			visitors: '1 500',
			registrations: '100',
			conversion: '20%',
			rate: '15%',
			referrals: 70
		},
		{
			traffic_source: 'Social network',
			visitors: '1 500',
			registrations: '100',
			conversion: '20%',
			rate: '15%',
			referrals: 70
		}
	]

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.backgroundWrap}>
				<div className={styles.sections}>
					{sectionsMarketing.map(item => (
						<PercentSectionCard key={item.title} data={item} />
					))}
				</div>
				<div className={styles.table}>
					<Table
						modifiers={['header', 'border']}
						// isLoading={isFetching}
						columns={columns}
						dataSource={data}
					/>
				</div>
			</div>
			<Pagination pageCount={10 / filter.count} onPageChange={onChangePage} />
		</div>
	)
}
