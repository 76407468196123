import { Children, FC, ReactElement, useState } from 'react'
import styles from './Tabs.module.scss'
import { ITab } from './tabs.interface'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

interface IProps {
	children?: ReactElement<ITab>
	activeTabId: string
	redirect?: string
}

export const Tabs: FC<IProps> = ({ children, activeTabId, redirect }) => {
	const [activeTab, setActiveTab] = useState<string | undefined>(activeTabId)
	const navigate = useNavigate()

	const onNavigateTabs = (name: string) => {
		if (redirect) navigate(redirect + `?tabs=${name}`)
		setActiveTab(name)
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.headersWrapper}>
				<div className={styles.headers}>
					{Children.map(children, child => {
						const tabId = child?.props.id?.replace('/', '') || '*'
						return (
							<div
								className={clsx(
									styles.header,
									activeTab?.includes(tabId) && styles.tabActive
								)}
								onClick={() => {
									onNavigateTabs(child?.props.id || '')
								}}
							>
								{child?.props.title}
							</div>
						)
					})}
				</div>
			</div>
			<div className={styles.content}>
				{Children.map(children, child => {
					const tabId = child?.props.id?.replace('/', '') || '*'
					if (!child?.props || !activeTab?.includes(tabId)) return null
					return <div>{child}</div>
				})}
			</div>
		</div>
	)
}

export const Tab: FC<ITab> = ({ children }) => children
