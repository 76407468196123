import {
	EmojiIconJSX,
	PaperClipJSX,
	PaperPlaneJSX,
	backgroundChat
} from '@/shared/assets/chat/svg'
import { FormWrapper, Input } from '@/shared/ui/form'
import clsx from 'clsx'
import { FC, useEffect, useRef, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import styles from './ChatForm.module.scss'
import { MessageManager } from '@/entities/chat'
import { useChatScroll } from '../../hooks'
import { IMessageSocket } from '@/shared/services/types/chat.types'
import { useTextLocality } from '@/shared/hooks'
import EmojiPicker from 'emoji-picker-react'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { TextArea } from '@/shared/ui/form/textarea'
interface IProps {
	sendMess: any
	chat: IMessageSocket[]
	isSelectedChat?: boolean
}

export const ChatForm: FC<IProps> = ({ sendMess, chat, isSelectedChat }) => {
	const { isActive, ref: refEmoji } = useOutsideClick(true)
	const [file, setFile] = useState<File | undefined>()
	const ref = useChatScroll(chat)
	const refButton = useRef<HTMLButtonElement>(null)
	const [showEmoji, setShowEmoji] = useState(false)
	const { getMessage } = useTextLocality()

	useEffect(() => {
		if (!isActive) setShowEmoji(false)
	}, [isActive])

	const form = useForm({
		mode: 'onBlur',
		defaultValues: {
			message: '',
			file: null
		}
	})

	const onSubmit = (data: FieldValues) => {
		const { message } = data

		sendMess(message)
		form.reset({ message: '', file: null })
	}

	const onKeyDown = () => {
		if (refButton.current) {
			refButton.current.click()
		}
	}

	return (
		<>
			<div
				className={clsx(styles.container, styles.containerBackground)}
				style={{ backgroundImage: backgroundChat }}
				ref={ref}
			>
				<div
					className={styles.chat}
					style={{ backgroundImage: backgroundChat }}
				>
					{chat.map((item, i) => {
						const connectManager = `------Manager Connected------`
						const isEmptyMessage = item.message.trim()

						if (item.message === connectManager || !isEmptyMessage) return null

						return (
							<MessageManager data={item} isUser={!item.is_manager} key={i} />
						)
					})}
				</div>
			</div>
			<FormWrapper
				className={clsx(styles.form, styles.container)}
				methods={form}
				onSubmit={onSubmit}
			>
				<button type='button' disabled={!isSelectedChat}>
					<label>
						<PaperClipJSX />
						<input
							type='file'
							disabled={!isSelectedChat}
							onChange={e => setFile(e?.target?.files?.[0])}
							style={{ display: 'none' }}
						/>
					</label>
				</button>
				<TextArea
					containerClassName={styles.inputContainer}
					className={styles.input}
					placeholder={getMessage('sendMessage')}
					name='message'
					disabled={!isSelectedChat}
					connect
					maxRow={2}
					onKeyDownHandler={onKeyDown}
					registerConfig={{ required: true }}
				/>

				<div className={styles.wrapperEmoji} ref={refEmoji}>
					<button
						type='button'
						disabled={!isSelectedChat}
						onClick={() => setShowEmoji(prev => !prev)}
					>
						<EmojiIconJSX />
					</button>
					{showEmoji && (
						<div className={styles.emoji}>
							<EmojiPicker
								skinTonesDisabled
								height={400}
								previewConfig={{ showPreview: false }}
								onEmojiClick={({ emoji }) => {
									form.setValue('message', form.watch('message') + emoji)
								}}
							/>
						</div>
					)}
				</div>
				<button type='submit' ref={refButton}>
					<PaperPlaneJSX />
				</button>
			</FormWrapper>
		</>
	)
}
