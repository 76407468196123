import { GeneralStatistic } from '@/features/statistics/general'
import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { FC } from 'react'

const General: FC = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description={getMessage('general_statistic')}
		>
			<GeneralStatistic />
		</Layout>
	)
}

export default General
