import { MyOptionType } from '@/shared/ui/form/form.interface'

export const updateSelectOptions = (
	data: any[] | null,
	setter: React.Dispatch<React.SetStateAction<MyOptionType[]>>,
	fields: { label: string; value: string }
) => {
	const result =
		data?.map(item => ({
			value: String(item[fields.value]),
			label: item[fields.label]
		})) || []
	setter(result)
}
