import { CSSProperties, FC, PropsWithChildren } from 'react'
import styles from './DashboardCard.module.scss'
import clsx from 'clsx'
import { ArrowSnakeJSX, ArrowUpJSX } from '@/shared/assets/analytic/svg'
import ProgressBar from 'react-customizable-progressbar'
import { TStaticCard } from './dashboard-card.interface'
import { reFormatNum } from '@/shared/utils'

interface IProps extends PropsWithChildren {
	data: TStaticCard
	isLoading?: boolean
	filter?: JSX.Element
	modifier?: ('hugo' | 'full' | 'large')[]
	stylesWrapper?: CSSProperties
}

export const DashboardCard: FC<IProps> = ({
	data,
	isLoading,
	filter,
	modifier,
	children,
	stylesWrapper
}) => {
	const colors = data.colors || {
		main: '#695CB8',
		light: '#E2E6FF',
		boxShadow: `0px 3px 23.64179039001465px rgba(105, 92, 184, 0.40);`
	}

	const stonks = Number(data.stonks) < 100 ? Math.abs(Number(data.stonks)) : 100

	const mod = {
		hugo: false,
		large: false,
		full: false
	}

	modifier?.forEach(item => {
		mod[item] = true
	})

	return (
		<div
			className={clsx(
				styles.wrapper,
				!data.isFall && data.stonks && styles.greenWrapper,
				mod.large && styles.large,
				mod.hugo && styles.hugo,
				mod.full && styles.full,
				data.isFall && styles.redWrapper
			)}
			style={stylesWrapper}
		>
			<div
				className={clsx(styles.contentWrapper, filter && styles.filterWrapper)}
			>
				<div
					className={clsx(
						styles.infoBlock,
						data.isBetting && styles.alignCenter
					)}
				>
					{data.stonks && (
						<>
							<div className={styles.title}>{data.title}</div>
							<div className={styles.numbersStat}>
								{reFormatNum(data.number)} {data.currency}
							</div>
						</>
					)}
					{!data.stonks && <div className={styles.title}>{data.title}</div>}

					<div className={styles.radioBetting}>
						{data.statuses?.map(item => (
							<div
								key={item.name}
								className={clsx(
									styles.radio,
									item.isActive && styles.activeRadio
								)}
							>
								<span />
								{item.name}
							</div>
						))}
					</div>

					{data.stonks && (
						<div className={styles.infoStonks}>
							<div
								className={clsx(styles.stonks, data.isFall && styles.isFall)}
							>
								<ArrowUpJSX />
								{data.stonks}%
							</div>
							<span className={styles.text}>vs {data.periodName}</span>
						</div>
					)}
				</div>

				{!children && (
					<div
						className={clsx(
							styles.progressBarWrapper,
							data.stonks && styles.difference,
							data.isBetting && styles.large,
							mod.large && styles.large,
							mod.hugo && styles.huge
						)}
					>
						<div className={styles.circleBar}>
							<ProgressBar
								radius={100}
								progress={
									data.isBetting ? data.number : data.stonks ? stonks : 0
								}
								cut={data.isBetting ? 120 : 0}
								rotate={data.isBetting ? -210 : -90}
								strokeWidth={12}
								strokeColor={colors.main}
								trackStrokeWidth={12}
								trackStrokeColor={data.isBetting ? colors.light : 'inherit'}
								trackStrokeLinecap='round'
								pointerRadius={0}
								initialAnimation={true}
								transition='1.5s ease 0.5s'
								trackTransition='0s ease'
								strokeLinecap='round'
							/>
							{data.tooltipCount && (
								<>
									<div className={styles.tooltipWrapper}>
										{data.tooltipCount[1] !== '0 ' && (
											<>
												<div
													className={styles.progressUp}
													style={{ background: colors.main }}
												>
													{data.tooltipCount[0]}
												</div>
												<div
													className={styles.progressRes}
													style={{ background: colors.light }}
												>
													{data.tooltipCount[1]}
												</div>
											</>
										)}
									</div>
								</>
							)}
							{!data.tooltipCount && (
								<div
									className={clsx(
										styles.circleInner,
										(data.stonks || data.isBetting) && styles.circleStatic,
										data.isFall && styles.circleFall
									)}
									style={{ boxShadow: colors.boxShadow }}
								>
									{!data.stonks && (
										<div className={styles.numbersStat}>
											{data.number}
											{data.isBetting && '%'}
										</div>
									)}
									{data.stonks && <ArrowSnakeJSX />}
								</div>
							)}

							{!data.isBetting && (
								<div
									className={styles.backgroundCircle}
									style={{ background: colors.light }}
								/>
							)}
						</div>
						<div className={styles.children}>{filter}</div>
					</div>
				)}
				{children}
			</div>
			<div className={styles.line} style={{ background: colors.main }} />

			{isLoading && <div className={styles.skeleton} />}
		</div>
	)
}
