import {typeAction, typePaginator} from "../typesStore";

export declare module Blog {

    export interface Author {
        id: number;
        name: string;
    }

    export interface Article {
        id: number;
        created_at: Date;
        is_active: boolean
    }

    export interface Data {
        title: string;
        short: string;
        body?: string,
        image?: any;
        second_image?: any;
    }

    export interface Item {
        author: Author;
        article: Article;
        data: Data;
    }

    export interface RootObject extends typePaginator{
        items: Item[];
        total_count: number;
    }

}



const defaultBlogState: Blog.RootObject = {
    items: [],
    total_count: 0,
    page: 1,
    count: 12,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultBlogState, action: typeAction) {
    const {type, value} = action;
    switch (type) {
        case "SET_BLOG_LIST":
            return {...value}
        default:
            return state
    }
}
