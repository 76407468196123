import { useAlertMessage } from '@/shared/hooks'
import SchoolService from '@/shared/services/school.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useAddCategory = () => {
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const { sendAlertError, sendAlertMessage } = useAlertMessage()
	
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['create category school'],
		(data: FormData) => SchoolService.createCategory(data)
	)
	const createCategory = async (data: FormData, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				sendAlertMessage('Success')
				navigate(`/content/school`)
				queryClient.invalidateQueries({ queryKey: ['categories list'] })

				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, createCategory }
}
