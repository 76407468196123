import { FC, useEffect, useState } from 'react'
import { SchoolForm } from '../school-form'
import '@/Assets/sass/Pages/School/Form.sass'
import styles from './TotalForm.module.scss'
import { FieldValues, useForm } from 'react-hook-form'
import { Button, Checkbox, FormWrapper, Input } from '@/shared/ui/form'
import clsx from 'clsx'
import { IArticle } from '@/shared/services/types/school.types'
import { useTextLocality } from '@/shared/hooks'
import { typeLangForm } from '@/shared/constants.interface'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { PublishIconJSX } from '@/shared/assets/common/svg'

interface ITotalForm {
	languages: typeLangForm[]
	onSubmit: (val: FieldValues) => void
}

export const TotalForm: FC<ITotalForm> = ({ languages, onSubmit }) => {
	const { getMessage } = useTextLocality()
	const [articleItem, setArticleItem] = useState<IArticle>()
	const { locale } = useAppSelector(state => state.defaultReducer)
	const form = useForm({ mode: 'onChange' })

	const {
		formState: { errors }
	} = form

	const onSubmitForm = (values: FieldValues) => {
		const data = {
			...values,
			...(articleItem?.id ? { id_article: articleItem.id } : {}),
			typeSubmit: articleItem ? 'article' : 'new_article'
		}

		onSubmit(data)
	}

	useEffect(() => {
		if (!articleItem) return
		form.reset({
			...articleItem
		})
	}, [articleItem])

	return (
		<div className={styles.wrapper}>
			<FormWrapper
				className={clsx('generalFormBlock', styles.form)}
				methods={form}
				onSubmit={onSubmitForm}
			>
				<div className={styles.wrapperHead}>
					<Input
						name={'rating_read_text'}
						type='number'
						connect
						placeholder={'0'}
						label={getMessage('rating_read_text')}
						required={true}
						errorText={errors?.['rating_read_text']?.message}
						registerConfig={{
							required: messages[locale].required_field
						}}
					/>
					<Input
						name={'rating_view_video'}
						type='number'
						connect
						placeholder={'0'}
						label={getMessage('video')}
						required={true}
						errorText={errors?.['rating_view_video']?.message}
						registerConfig={{
							required: messages[locale].required_field
						}}
					/>
					<Checkbox name='isActive' title={getMessage('publish_now')} connect />
				</div>

				<div className={styles.wrapperForm}>
					{languages.map(item => (
						<SchoolForm
							key={item.lang}
							form={form}
							headingPlaceholder={item.headingPlaceholder}
							lang={item.lang}
							short={item.short}
							onSubmit={onSubmitForm}
							previewPlaceholder={item.previewPlaceholder}
							textPlaceholder={item.textPlaceholder}
							setArticleItem={setArticleItem}
						/>
					))}
				</div>
				<Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish_all')}
				</Button>
			</FormWrapper>
		</div>
	)
}
