import { FC } from 'react'

interface IProps {
	message?: string
}
const IconInfo: FC<IProps> = () => (
	<div className='typeAlert'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
		>
			<path
				d='M19 4H13C12.4477 4 12 4.44772 12 5C12 5.55228 12.4477 6 13 6H19C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4Z'
				fill='#676C79'
			/>
			<path
				d='M19 11H13C12.4477 11 12 11.4477 12 12C12 12.5523 12.4477 13 13 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11Z'
				fill='#676C79'
			/>
			<path
				d='M19 18H13C12.4477 18 12 18.4477 12 19C12 19.5523 12.4477 20 13 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18Z'
				fill='#676C79'
			/>
			<path
				d='M3 5L5 7L9 3'
				stroke='#676C79'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13 3.5H19C19.8284 3.5 20.5 4.17157 20.5 5C20.5 5.82843 19.8284 6.5 19 6.5H13C12.1716 6.5 11.5 5.82843 11.5 5C11.5 4.17157 12.1716 3.5 13 3.5Z'
				stroke='#676C79'
				strokeLinecap='round'
			/>
			<path
				d='M3 12L5 14L9 10'
				stroke='#676C79'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19 10.5H13C12.1716 10.5 11.5 11.1716 11.5 12C11.5 12.8284 12.1716 13.5 13 13.5H19C19.8284 13.5 20.5 12.8284 20.5 12C20.5 11.1716 19.8284 10.5 19 10.5Z'
				stroke='#676C79'
				strokeLinecap='round'
			/>
			<path
				d='M3 19L5 21L9 17'
				stroke='#676C79'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19 17.5H13C12.1716 17.5 11.5 18.1716 11.5 19C11.5 19.8284 12.1716 20.5 13 20.5H19C19.8284 20.5 20.5 19.8284 20.5 19C20.5 18.1716 19.8284 17.5 19 17.5Z'
				stroke='#676C79'
				strokeLinecap='round'
			/>
		</svg>
	</div>
)

const IconSuccess: FC<IProps> = () => (
	<div className='typeAlert'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
		>
			<path
				d='M16 2H8C4.691 2 2 4.691 2 8V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H16C19.309 22 22 19.309 22 16V8C22 4.691 19.309 2 16 2ZM20 16C20 18.206 18.206 20 16 20H4V8C4 5.794 5.794 4 8 4H16C18.206 4 20 5.794 20 8V16Z'
				fill='#54C86E'
			/>
			<path
				d='M11 13.586L8.70697 11.293L7.29297 12.707L11 16.414L17.207 10.207L15.793 8.79297L11 13.586Z'
				fill='#54C86E'
			/>
		</svg>
	</div>
)

const IconError: FC<IProps> = ({ message }) => (
	<div className='typeAlert'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
		>
			<path
				d='M16 2H8C4.691 2 2 4.691 2 8V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H16C19.309 22 22 19.309 22 16V8C22 4.691 19.309 2 16 2ZM20 16C20 18.206 18.206 20 16 20H4V8C4 5.794 5.794 4 8 4H16C18.206 4 20 5.794 20 8V16Z'
				fill='#DC0000'
			/>
			<path d='M11 6H13V14H11V6ZM11 16H13V18H11V16Z' fill='#DC0000' />
		</svg>
	</div>
)

export { IconInfo, IconSuccess, IconError }
