import {useEffect, useState} from "react";
import {useSelector} from "react-redux";


export function useMainBlockWidth () {
    const [widthMainBlock, setWidthMainBlock] = useState(0);
    const [widthFullMainBlock, setWidthFullMainBlock] = useState(false);

    // @ts-ignore
    const showNavigation = useSelector(state => state.defaultReducer.showNavigation)

    useEffect(() => {
        // @ts-ignore
        const widthMainBlockNow = Number.parseInt(getComputedStyle(document.getElementById('AppMainBlock')).width.slice(0, -2))
        if(widthMainBlockNow > 950){
            setWidthMainBlock((widthMainBlockNow / 2) - 60)
            setWidthFullMainBlock(false)
        } else {
            if(showNavigation){
                setWidthFullMainBlock(true)
            } else setWidthFullMainBlock(false)
            setWidthMainBlock((widthMainBlockNow) - 60)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showNavigation])
    return {widthFullMainBlock, widthMainBlock}
}
