import { Button, FormWrapper, Input, RadioGroup } from '@/shared/ui/form'
import styles from './UsersFilter.module.scss'
import { useForm } from 'react-hook-form'
import {
	FilterIconJSX,
	SearchInput,
	SortIconJSX
} from '@/shared/assets/common/svg'
import { DropDownMenu } from '@/shared/ui/drop-down-menu'
import clsx from 'clsx'
import { useRef } from 'react'
import { useTextLocality } from '@/shared/hooks'

export const UsersFilter = () => {
	const { getMessage } = useTextLocality()

	const form = useForm()
	const sortRef = useRef(null)
	const filterRef = useRef(null)
	const { watch, getValues } = form

	const options = [
		{
			label: getMessage('sortAZ'),
			value: 'Sort from A to Z'
		},
		{
			label: getMessage('sortZA'),
			value: 'Sort from Z to A'
		}
	]

	const options2 = [
		{
			label: getMessage('active'),
			value: 'Active'
		},
		{
			label: getMessage('inactive'),
			value: 'Inactive'
		}
	]

	const resetSort = () => {
		form.reset({ ...getValues(), sort: undefined })
	}
	const resetFilter = () => {
		form.reset({ ...getValues(), filter: undefined })
	}

	return (
		<FormWrapper methods={form} className={styles.wrapper}>
			<Input
				containerClassName={styles.input}
				name='search'
				modifiers={['small']}
				connect
				placeholder={getMessage('searchByNameContact')}
				icon={<SearchInput />}
			/>
			<div className={styles.buttons}>
				<DropDownMenu
					refs={[filterRef]}
					header={
						<button
							type='button'
							className={clsx(
								styles.filterButton,
								watch('filter') && styles.activeButton
							)}
						>
							<FilterIconJSX />
						</button>
					}
					modifiers={['arrow']}
				>
					<div className={styles.wrapperFilter}>
						<div className={styles.title}>{getMessage('filters')}</div>
						<div className={styles.blockFilter}>
							<div className={styles.titleFilter}>{getMessage('activity')}</div>
							<RadioGroup connect name='filter' options={options2} />
						</div>
						<div className={styles.buttons} ref={filterRef}>
							<Button
								modifiers={['inverse']}
								type='reset'
								onClick={resetFilter}
							>
								{getMessage('clear')} {getMessage('filters')}
							</Button>
							<Button modifiers={['inverse', 'red']}>
								{getMessage('save')}
							</Button>
						</div>
					</div>
				</DropDownMenu>
				<DropDownMenu
					refs={[sortRef]}
					header={
						<button
							type='button'
							className={clsx(
								styles.filterButton,
								watch('sort') && styles.activeButton
							)}
						>
							<SortIconJSX />
						</button>
					}
					modifiers={['arrow']}
				>
					<div className={styles.wrapperFilter}>
						<div className={styles.title}>{getMessage('sort')}</div>
						<div className={styles.blockFilter}>
							<div className={styles.titleFilter}>{getMessage('name')}</div>
							<RadioGroup connect name='sort' options={options} />
						</div>
						<div className={styles.buttons} ref={sortRef}>
							<Button modifiers={['inverse']} type='reset' onClick={resetSort}>
								{getMessage('clear')}
							</Button>
							<Button modifiers={['inverse', 'red']}>
								{getMessage('save')}
							</Button>
						</div>
					</div>
				</DropDownMenu>
			</div>
		</FormWrapper>
	)
}
