import '@/Assets/sass/Pages/Chat.sass'
import { useState, FC, PropsWithChildren, useEffect } from 'react'
import useWebSocket from 'react-use-websocket'

import { ChatForm, UserInfo } from '@/features/chat/ui'
import { ChatCategory } from '@/features/chat/ui/chat-category'

import styles from './ChatData.module.scss'
import { useCloseRoom, useWorkspaceAppeals } from '@/features/chat/api'
import { Loader } from '@/shared/ui/loader'
import { IAppeal, IMessageSocket } from '@/shared/services/types/chat.types'
import { useUserData } from '@/features/auth/api'
import { REACT_APP_API_URL } from '@/shared/api/interceptors/axiosInstance'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useQueryParams } from '@/shared/hooks/useQueryParams'

const SOCKET_URL = `${REACT_APP_API_URL.replace('https:', 'wss:')}chat/ws/`

export const ChatData: FC<PropsWithChildren> = () => {
	const token = localStorage.getItem('access') || 'token'

	const { id } = useQueryParams()
	const { refetch: refetchWorkSpaceAppeals } = useWorkspaceAppeals()

	const { data: userData } = useUserData()
	const [socketURl, setSocketUrl] = useState<string | null>(null)
	const [isActiveId, setIsActiveId] = useState<IAppeal | null>(null)
	const [isActivePrevId, setIsActivePrevId] = useState<number>()
	const [chat, setChat] = useState<IMessageSocket[]>([])

	const {
		refetch,
		isFetching: isLoading,
		fetchStatus
	} = useCloseRoom(isActiveId?.room_id)

	useEffect(() => {
		if (fetchStatus === 'fetching') {
			setIsActiveId(null)
		}
	}, [fetchStatus])

	const { sendMessage, readyState, lastMessage } = useWebSocket(socketURl, {
		onOpen: () => console.log('opened websocket'),
		onClose: () => {
			console.log('close websocket')
		}
	})

	useEffect(() => {
		if (!isActiveId?.room_id) return
		if (isActivePrevId !== isActiveId.room_id) {
			setSocketUrl(null)
			setChat([])
			setTimeout(() => {
				setSocketUrl(SOCKET_URL)
			}, 1000)
		}
	}, [isActiveId?.room_id])

	useEffect(() => {
		if (socketURl) {
			setIsActivePrevId(isActiveId?.room_id)

			sendMessage(
				JSON.stringify({
					token: token,
					room_id: isActiveId?.room_id,
					message: ''
				})
			)
		}
	}, [socketURl])

	useEffect(() => {
		if (lastMessage?.data) {
			const wsData = JSON.parse(lastMessage.data)

			setChat(prev => [
				...prev,
				{
					create_date: wsData.create_date,
					message: wsData.message,
					is_manager: wsData.is_manager
				}
			])
		}
	}, [lastMessage])

	const sendMess = (text_message: string) => {
		const changeMessage = text_message.replace(/\n/g, '<br/>')
		const message = changeMessage.replace(/(<br\/>)+(?![^<]*\S)/g, '')

		if (readyState === 3) {
			setChat(prevState => {
				return [
					...prevState,
					{
						create_date: format(
							utcToZonedTime(new Date(), 'UTC'),
							'yyyy-MM-dd HH:mm:ss'
						),
						message,
						is_manager: true,
						user_id: userData!.id
					}
				]
			})
		}

		sendMessage(
			JSON.stringify({
				token: token,
				room_id: isActiveId?.room_id,
				message
			})
		)

		setTimeout(() => {
			refetchWorkSpaceAppeals()
		}, 1000)
	}

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.left}>
					<ChatCategory
						isActiveId={Number(id) || null}
						setIsActiveId={setIsActiveId}
					/>
				</div>
				<div className={styles.center}>
					{(!readyState || isLoading) && <Loader absolute isMini transparent />}
					<ChatForm
						sendMess={sendMess}
						chat={chat}
						isSelectedChat={!!isActiveId}
					/>
				</div>
				<div className={styles.right}>
					{isActiveId && (
						<UserInfo dataAppeal={isActiveId} onCloseRoom={refetch} />
					)}
				</div>
			</div>
		</>
	)
}
