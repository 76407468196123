import clsx from 'clsx'
import { FC, PropsWithChildren, useState } from 'react'

import styles from './Accordion.module.scss'
import { ArrowBack } from '@/shared/assets/common/svg'

type TProps = PropsWithChildren & {
	header: string
	disabled?: boolean
}

export const Accordion: FC<TProps> = ({ children, header, disabled }) => {
	const [isActive, setIsActive] = useState(false)

	return (
		<div
			className={clsx(
				styles.wrapper,
				isActive && styles.show,
				disabled && styles.disabled
			)}
		>
			<div
				className={styles.title}
				onClick={() => setIsActive(prevState => !prevState)}
			>
				<button className={clsx(styles.button, isActive && styles.show)}>
					<ArrowBack />
					<span>{header}</span>
				</button>
			</div>
			{isActive && <div className={clsx(styles.content)}>{children}</div>}
		</div>
	)
}
