import { ShopForm } from '@/features/shop/shop-form'
import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { PageForm } from '@/widgets/page-form'
import { useState } from 'react'
import { FieldValues } from 'react-hook-form'

const ShopAddProduct = () => {
	const [confirmationSubmit, setConfirmationSubmit] = useState<boolean>(false)
	const [eventForm, setEventForm] = useState<FieldValues>({})
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			description={getMessage('add-product')}
			title={getMessage('shop')}
		>
			<PageForm
				confirmationSubmit={confirmationSubmit}
				eventForm={eventForm}
				setConfirmationSubmit={setConfirmationSubmit}
			>
				<>
					<ShopForm
						setEventForm={setEventForm}
						setConfirmationSubmit={setConfirmationSubmit}
					/>
				</>
			</PageForm>
		</Layout>
	)
}

export default ShopAddProduct
