import SchoolService from '@/shared/services/school.service'
import { useQuery } from '@tanstack/react-query'
import { useAlertMessage } from '@/shared/hooks'

export const useArticleDetail = (id: string) => {
	const { sendAlertError } = useAlertMessage()

	return useQuery(
		['category detail school', id],
		() => SchoolService.getArticleById(+id!),
		{
			select: ({ data }) => data,
			enabled: !!id,
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)
}
