import { MarketingStatistic } from '@/features/statistics/marketing'
import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { FC } from 'react'

const Marketing: FC = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description={getMessage('marketing_statistic')}
		>
			<MarketingStatistic />
		</Layout>
	)
}

export default Marketing
