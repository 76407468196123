import { useQuery } from '@tanstack/react-query'
import { useAlertMessage } from '@/shared/hooks'
import AnalyticsService from '@/shared/services/analytics.service'

export const useAnalyticDetail = (id: string) => {
	const { sendAlertError } = useAlertMessage()

	return useQuery(
		['detail analytic', id],
		() => AnalyticsService.getById(+id!),
		{
			select: ({ data }) => data,
			enabled: !!id,
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)
}
