import { useAlertMessage } from '@/shared/hooks'
import NewsService from '@/shared/services/news.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteNew = () => {
	const { sendAlertError } = useAlertMessage()
	const client = useQueryClient()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['delete new'],
		(id: number) => NewsService.delete(id)
	)
	const onDeleteNew = async (id: number, callback?: () => void) => {
		mutateAsync(id)
			.then(res => {
				callback?.()
				client.invalidateQueries({ queryKey: ['news list'] })
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, onDeleteNew }
}
