import axiosInstance from '@/shared/store/axiosGlobal'

import { getChatUrl } from '../api/config'
import { IAppeal, IMessage } from './types/chat.types'

const ChatService = {
	async getNewAppeals() {
		return axiosInstance.get<IAppeal[]>(getChatUrl(`/new_appeals`))
	},
	async getWorkplaceAppeals() {
		return axiosInstance.get<IAppeal[]>(getChatUrl(`/workplace_appeals`))
	},
	async getHistoryAppeals() {
		return axiosInstance.get<IAppeal[]>(getChatUrl(`/history`))
	},

	async getCloseRoom(room_id: number) {
		return axiosInstance.get<IAppeal>(getChatUrl(`/close_room`), {
			params: { room_id }
		})
	},

	async getRoomsMessage(room_id: number, messages_count: number) {
		return axiosInstance.get<IMessage[]>(`/chatroom_messages_history`, {
			params: { room_id, messages_count }
		})
	}
}

export default ChatService
