import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { IPropCheckbox } from '../form.interface'

import styles from './Checkbox.module.scss'
import { ConnectCheckbox } from '../form-connect'
import { CheckboxDefault } from './checkbox-default'

export const Checkbox: FC<IPropCheckbox> = ({
	className,
	title,
	link,
	url,
	onChange,
	checked,
	name,
	id,
	defaultChecked,
	disabled,
	modifier,
	onBlur,
	connect
}) => {
	const mod = {
		full: false,
		card: false,
		red: false,
		center: false
	}

	modifier?.forEach(str => {
		mod[str] = true
	})

	const CheckboxComponent = connect ? ConnectCheckbox : CheckboxDefault

	return (
		<label
			className={clsx(
				className,
				styles.checkbox,
				disabled && styles.disabled,
				mod.full && styles.fullWidth,
				mod.center && styles.centerPosition,
				mod.red && styles.redWrapper,
				mod.card && styles.card
			)}
		>
			<CheckboxComponent
				className={styles.checkbox__input}
				disabled={disabled}
				checked={checked}
				onChange={onChange}
				onBlur={onBlur}
				name={name}
				defaultChecked={defaultChecked}
				id={id}
			/>

			<span className={clsx(styles.checkbox__check)} />

			{title && (
				<div className={styles.checkbox__textBlock}>
					<div className={styles.checkbox__text}>{title}</div>

					{link && (
						<Link
							to={url || `#`}
							className={styles.checkbox__link}
							target='_blank'
						>
							{link}
						</Link>
					)}
				</div>
			)}
		</label>
	)
}
