import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { ConnectForm } from './ConnectForm'
import { IProsImage } from '../form.interface'
import { InputImageDefault } from '../input-image/input-image-default'

export const ConnectInputImage: FC<IProsImage> = ({
	registerConfig,
	...props
}) => {
	return (
		<ConnectForm>
			{({ register, control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name}
					render={({ field: { onChange, value, ref } }) => {
						return (
							<InputImageDefault
								{...props}
								onChange={onChange}
								value={value}
								img={value}
								imageRef={ref}
							/>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}
