import React, {forwardRef} from "react";

// @ts-ignore
export const ExampleCustomInput = forwardRef<HTMLButtonElement>(({ value, onClick}, ref) => {
    return <button onClick={onClick} ref={ref}>
        {value}
        <svg style={{marginLeft: '8px'}} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M4.95825 7.79175H6.37492V9.20841H4.95825V7.79175ZM4.95825 10.6251H6.37492V12.0417H4.95825V10.6251ZM7.79159 7.79175H9.20825V9.20841H7.79159V7.79175ZM7.79159 10.6251H9.20825V12.0417H7.79159V10.6251ZM10.6249 7.79175H12.0416V9.20841H10.6249V7.79175ZM10.6249 10.6251H12.0416V12.0417H10.6249V10.6251Z" fill="#676C79"/>
            <path d="M3.54167 15.5834H13.4583C14.2396 15.5834 14.875 14.948 14.875 14.1667V4.25008C14.875 3.46879 14.2396 2.83341 13.4583 2.83341H12.0417V1.41675H10.625V2.83341H6.375V1.41675H4.95833V2.83341H3.54167C2.76037 2.83341 2.125 3.46879 2.125 4.25008V14.1667C2.125 14.948 2.76037 15.5834 3.54167 15.5834ZM13.4583 5.66675L13.459 14.1667H3.54167V5.66675H13.4583Z" fill="#676C79"/>
        </svg>
    </button>
});
