import clsx from 'clsx'
import { FC } from 'react'

import { ConnectInputImage } from '../form-connect'
import { IProsImage } from '../form.interface'

import styles from './InputImage.module.scss'
import { InputImageDefault } from './input-image-default'

const RegularInputImage = (props: IProsImage) => <InputImageDefault {...props} />

export const InputImage: FC<IProsImage> = ({
	connect,
	errorText,
	...inputProps
}) => {
	const InputImageComponent: FC<IProsImage> = connect
		? ConnectInputImage
		: RegularInputImage

	return (
		<div className={clsx(styles.wrapper)}>
			<InputImageComponent {...inputProps} />

			{errorText && typeof errorText === 'string' && (
				<div className={styles.error}>
					<span>{errorText}</span>
				</div>
			)}
		</div>
	)
}
