import { useAlertMessage } from '@/shared/hooks'
import SportService from '@/shared/services/sport.service'
import { useQuery } from '@tanstack/react-query'

export const useMarketList = (id: number) => {
	const { sendAlertError } = useAlertMessage()

	const { data, isFetching, isLoading } = useQuery(
		['market list by sportId', id],
		() => SportService.getMarketsByGameId(id),
		{
			select: ({ data }) => data,
			enabled: !!id,
			onError: (error: any) => {
				sendAlertError(error)
			},
			retry: 0
		}
	)

	return {
		data: data || null,
		isFetching,
		isLoading
	}
}
