import React, { FC, useState } from 'react'
import styles from './Pagination.module.scss'
import RCPagination from 'react-paginate'
import 'rc-pagination/assets/index.css'

import './Pagination.scss'
import clsx from 'clsx'

interface IProps {
	onPageChange: (data: { selected: number }) => void
	pageCount: number
	setCountItems?: (count: number) => void
	countItems?: number
	current?: number
}

export const Pagination: FC<IProps> = ({
	onPageChange,
	pageCount,
	setCountItems,
	countItems,
	current
}) => {
	const [selectedItems, setSelectedItems] = useState(countItems || 10)
	const pageCountList = [10, 20, 30, 40]

	const onSelectedItems = (count: number) => {
		setSelectedItems(count)
		setCountItems!(count)
	}

	return (
		<div className={clsx('wrapper', !setCountItems && 'center')}>
			{setCountItems && <div className={'mock'} />}
			{!!pageCount && (
				<div className={'pagination'}>
					<RCPagination
						nextLabel='>'
						onPageChange={onPageChange}
						pageCount={pageCount}
						previousLabel='< '
						forcePage={current}
						pageRangeDisplayed={1}
						marginPagesDisplayed={4}
						renderOnZeroPageCount={null}
					/>
				</div>
			)}

			{setCountItems && (
				<div className={'pages'}>
					<ul className={'items__list'}>
						{pageCountList.map(item => (
							<li
								key={item}
								onClick={() => onSelectedItems(item)}
								className={clsx(item === selectedItems && 'selected')}
							>
								{item}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	)
}
