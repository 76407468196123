import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { SchoolList } from '@/widgets/school-list'

const Schools = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description={getMessage('school')}
		>
			<SchoolList />
		</Layout>
	)
}

export default Schools
