import { transformDateToString } from '@/shared/utils'
import { addDays, addMonths, addWeeks, addYears } from 'date-fns'

const currentDate = new Date()
const currentFilterDate = transformDateToString(new Date())

const lastYearDate = transformDateToString(addYears(currentDate, -1))
const lastMonthDate = transformDateToString(addMonths(currentDate, -1))
const lastWeekDate = transformDateToString(addWeeks(currentDate, -1))
const lastDaysDate = transformDateToString(addDays(currentDate, -1))

const lastPrevYearDate = transformDateToString(addYears(currentDate, -2))
const lastPrevMonthDate = transformDateToString(addMonths(currentDate, -2))
const lastPrevWeekDate = transformDateToString(addWeeks(currentDate, -2))
const lastPrevDaysDate = transformDateToString(addDays(currentDate, -2))

export const defaultFilter = {
	current: { fromDate: lastMonthDate, toDate: currentFilterDate },
	prev: { fromDate: lastPrevMonthDate, toDate: lastMonthDate }
}

export const filterDataMap = {
	month: defaultFilter,
	annually: {
		current: { fromDate: lastYearDate, toDate: currentFilterDate },
		prev: { fromDate: lastPrevYearDate, toDate: lastYearDate }
	},
	weekly: {
		current: { fromDate: lastWeekDate, toDate: currentFilterDate },
		prev: { fromDate: lastPrevWeekDate, toDate: lastWeekDate }
	},
	daily: {
		current: { fromDate: lastDaysDate, toDate: currentFilterDate },
		prev: { fromDate: lastPrevDaysDate, toDate: lastDaysDate }
	}
}

export const filterDataDAy = {
	month: 30,
	annually: 365,
	weekly: 7,
	daily: 1
}
