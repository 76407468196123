import { format, parseISO } from 'date-fns'

export const transformDateToString = (date: Date, isEndDay?: boolean) => {
	const startFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
	const endFormat = "yyyy-MM-dd'T'23:59:59.999'Z'"

	const formattedDate = format(date, isEndDay ? endFormat : startFormat)

	return formattedDate
}

export const transformDateLocale = (dateString?: string, isTime?: boolean) => {
	if (!dateString) return '-'
	const date = parseISO(dateString)

	const timeString = isTime ? `HH:mm:ss` : ''
	const formattedDate = format(date, `MM/dd/yyyy ${timeString}`)
	return formattedDate
}
