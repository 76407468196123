import { useAlertMessage } from '@/shared/hooks'
import SchoolService from '@/shared/services/school.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useAddArticle = (category_id: number) => {
	const navigate = useNavigate()
	const queryClient = useQueryClient()


	const { sendAlertError, sendAlertMessage } = useAlertMessage()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['create article school'],
		(data: FormData) => SchoolService.createArticle(data)
	)
	const createArticle = async (data: FormData, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				sendAlertMessage('Success')
				callback?.()
				navigate(`/content/school/${category_id}/article`)
				queryClient.invalidateQueries({ queryKey: ['articles list'] })

				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, createArticle }
}
