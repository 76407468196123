import { NotifyIconJSX, SettingIconJSX } from '@/shared/assets/common/svg'
import { DropDownMenu } from '@/shared/ui/drop-down-menu'

import styles from './ButtonGroup.module.scss'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { setLang } from '@/shared/store/actions/default'
import { RadioGroup } from '@/shared/ui/form'

export const ButtonGroup = () => {
	const { locale } = useAppSelector(state => state.defaultReducer)

	const dispatch = useAppDispatch()

	const selectLang = (val: string) => {
		dispatch(setLang(val))
	}

	const LangNow = () => {
		const optionsLang = {
			ru: [
				{ label: 'Русский (рус)', value: 'ru' },
				{ label: 'English (en)', value: 'en' },
				{ label: 'Chinese (中文)', value: 'cn' }
			],
			en: [
				{ label: 'English (en)', value: 'en' },
				{ label: 'Chinese (中文)', value: 'cn' },
				{ label: 'Русский (рус)', value: 'ru' }
			],
			cn: [
				{ label: 'Chinese (中文)', value: 'cn' },
				{ label: 'English (en)', value: 'en' },
				{ label: 'Русский (рус)', value: 'ru' }
			]
		}

		const localeLangRadio = {
			ru: (
				<>
					<RadioGroup
						name='group'
						options={optionsLang.ru}
						onChange={selectLang}
						checked={locale}
					/>
				</>
			),
			en: (
				<>
					<RadioGroup
						name='group'
						options={optionsLang.ru}
						onChange={selectLang}
						checked={locale}
					/>
				</>
			),
			cn: (
				<>
					<RadioGroup
						name='group'
						options={optionsLang.ru}
						onChange={selectLang}
						checked={locale}
					/>
				</>
			)
		}

		return localeLangRadio[locale]
	}

	return (
		<div className={styles.buttonGroup}>
			<DropDownMenu
				header={
					<div className={styles.langPrev}>
						<FormattedMessage id={locale} />
					</div>
				}
			>
				<div className={styles.lang}>
					<div className='triangle' />
					<LangNow />
				</div>
			</DropDownMenu>
			<DropDownMenu header={<NotifyIconJSX />}>
				<div className={styles.notification}>
					<div className='triangle' />
					Notification
					<div className='toClose'>To close</div>
				</div>
			</DropDownMenu>

			<button className='settings'>
				<SettingIconJSX />
			</button>
		</div>
	)
}
