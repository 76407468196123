import React from 'react'

import DatePicker from 'react-datepicker'
import { ExampleCustomInput } from './DataPickerCustomInput'

import 'react-datepicker/dist/react-datepicker.css'
import '@/Assets/sass/Elements/DataButton.sass'

export const DataButton: (props: {
	selectedDate: any
	startDate?: any
	endDate?: any
	setDate: any
}) => JSX.Element = props => {
	const { selectedDate, startDate, endDate, setDate } = props
	return (
		<DatePicker
			selected={selectedDate}
			onChange={(date: any) => setDate(date)}
			selectsStart
			startDate={startDate}
			endDate={endDate}
			customInput={<ExampleCustomInput />}
		/>
	)
}
