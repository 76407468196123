import {typeAction, typeAnalyticState} from "../typesStore";




const defaultAnalyticState: typeAnalyticState = {
    items: [],
    total_count: 21,
    page: 1,
    count: 21,
}



export default function analytic(state = defaultAnalyticState, action: typeAction) {
    const {type, value} = action;
    switch (type) {
        case "SET_ANALYTICS":
            return {...value}
        default:
            return state;
    }
}
