import { useAlertMessage } from '@/shared/hooks'
import SchoolService from '@/shared/services/school.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useEditArticle = (id: number) => {
	const navigate = useNavigate()
	
	const queryClient = useQueryClient()

	const { sendAlertError } = useAlertMessage()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['edit article school', id],
		(data: FormData) => SchoolService.editArticle(id, data)
	)
	const onEditArticle = async (data: FormData, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				navigate(`/content/school/${data.get('category_id')}/article`)
				queryClient.invalidateQueries({ queryKey: ['articles list'] })

				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, onEditArticle }
}
