import { useEffect, useRef, useState } from 'react'

const useOutsideClick = (initialValue?: boolean, refs?: any[]) => {
	const calendarWindow = document.getElementsByClassName(
		'react-datepicker__tab-loop'
	)?.length

	const [isActive, setIsActive] = useState(initialValue)
	const ref = useRef<HTMLDivElement>(null)

	const handleClick = (e: globalThis.MouseEvent) => {
		const target = e.target as Node
		const refNodes = refs?.map(item => item.current) || []

		const isClickRef =
			!!refNodes.length && refNodes.every(item => item?.contains(target))

		if (
			(ref.current && !ref.current.contains(target) && !calendarWindow) ||
			isClickRef
		) {
			setIsActive(false)
		} else {
			setIsActive(true)
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClick)
		return () => {
			document.removeEventListener('click', handleClick)
		}
	})

	return { ref, isActive, setIsActive }
}

export default useOutsideClick
