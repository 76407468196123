import { FC } from 'react'

import styles from './Confirmation.module.scss'
import { FieldValues } from 'react-hook-form'
import Modal from '../modal/Modal'
import { DeleteIconJSX } from '@/shared/assets/common/svg'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	event?: FieldValues
	isOpen: boolean
	setConfirmationState: (event?: any) => void
	setConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>
	title: string
	svg?: JSX.Element
}

export const Confirmation: FC<IProps> = ({
	setConfirmationState,
	setConfirmationOpen,
	title,
	isOpen,
	svg,
	event
}) => {
	const { getMessage } = useTextLocality()
	
	return (
		<Modal
			isOpen={isOpen}
			disabledPortal
			modifers={['conformation', 'simple']}
			onClose={() => {
				setConfirmationOpen(false)
			}}
		>
			<div className={styles.confirmationBlock}>
				<div className={styles.icon}>{svg || <DeleteIconJSX />}</div>
				<div className={styles.title}>{title}</div>
				{/* <div className={styles.articleName}>1 article</div> */}
				<div className={styles.groupButton}>
					<button
						onClick={() => {
							setConfirmationOpen(false)
							event ? setConfirmationState(event) : setConfirmationState(true)
						}}
					>
						{getMessage('confirm_yes')}
					</button>
					<button
						className={styles.colorRed}
						onClick={() => setConfirmationOpen(false)}
					>
						{getMessage('confirm_no')}
					</button>
				</div>
			</div>
		</Modal>
	)
}
