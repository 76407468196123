import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { UserDetailDashboard } from '@/widgets/user-detail-dashboard'

const UserDetail = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description={getMessage('user_statistic')}
		>
			<UserDetailDashboard />
		</Layout>
	)
}

export default UserDetail
