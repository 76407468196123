import { setMessage } from '@/shared/store/actions/message'
import { useAppDispatch } from '../model'
import { AxiosError } from 'axios'

type TTypeMessage = 'error' | 'info' | 'success'
export function useAlertMessage(type: TTypeMessage = 'success') {
	const dispatch = useAppDispatch()

	const sendAlertError = (error: AxiosError<any>) => {
		if (!error.response) {
			return dispatch(setMessage({ message: 'Unauthorized', type: 'error' }))
		}
		if (error.response?.data?.detail) {
			const message = error.response.data.detail
			return dispatch(setMessage({ message, type: 'error' }))
		}

		if (error.response.status === 400) {
			dispatch(setMessage({ message: 'Bad request', type: 'error' }))
		} else if (error.response.status === 401) {
			dispatch(setMessage({ message: 'Unauthorized', type: 'error' }))
		} else if (error.response.status === 402) {
			dispatch(setMessage({ message: 'Payment Required', type: 'error' }))
		} else if (error.response.status === 403) {
			dispatch(setMessage({ message: 'Access denied', type: 'error' }))
		} else if (error.response.status === 404) {
			dispatch(setMessage({ message: 'Not Found', type: 'error' }))
		}
	}

	const sendAlertMessage = (message: string) => {
		return dispatch(setMessage({ message: message, type: type }))
	}

	return { sendAlertError, sendAlertMessage }
}
