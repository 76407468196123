import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'
import { ITransactionList } from '@/shared/services/types/statistic.types'
import { useAlertMessage } from '@/shared/hooks'

export const useTransactionBonus = (data: ITransactionList) => {
	const { sendAlertError } = useAlertMessage()
	return useQuery(
		['get bonus transaction list' + data],
		() =>
			StatisticService.getTransactionList({
				tr_filter: 'daily_bonus',
				...data
			}),

		{
			select: ({ data }) => data,
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)
}
