import { DashboardCard, StatusTable } from '@/entities/users/ui'
import { BettingHistoryFilter } from '../betting-filter'
import { TColumnsTable, Table } from '@/shared/ui/table'
import styles from './BettingHistory.module.scss'
import store from '@/shared/store/store'
import { messages } from '@/i18n/messages'
import { useGetBets, useSumBetsUsers } from '@/features/users/api'
import { FC, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { transformDateLocale, transformDateToString } from '@/shared/utils'
import { Pagination } from '@/shared/ui/pagination'
import { useTextLocality } from '@/shared/hooks'
import { STATUS_BET } from '@/shared/constants'

interface IProps {
	id: number
}

interface ITableBets {
	id: number
	date: string
	amount: number
	event: string
	type: string
	bet: string
	isWin: boolean
	bet_status: 'True' | 'False' | string
}

export const BettingHistory: FC<IProps> = ({ id }) => {
	const defaultFilter = {
		user_id: id,
		page: 1,
		count: 10
	}
	const [filter, setFilter] = useState<any>(defaultFilter)
	const { getMessage } = useTextLocality()
	const form = useForm({ mode: 'onBlur' })
	const [dataBetList, setDataBetList] = useState<ITableBets[]>([])
	const { data: winStatics, isLoading } = useSumBetsUsers({ user_id: id })
	const { data: betsData, refetch, isFetching, isError } = useGetBets(filter)

	const onSearchHandler = (form: FieldValues) => {
		const res = {
			...form,
			fromDate: form.fromDate && transformDateToString(form.fromDate),
			toDate: form.toDate && transformDateToString(form.toDate)
		}
		setFilter({ ...defaultFilter, ...res })
	}

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}

	useEffect(() => {
		refetch()
	}, [filter])

	const number = winStatics?.total_bets || 0
	const data = {
		title: getMessage('statisticsWinAndLoss'),
		number: number,
		period: `previous month`,
		statuses: [
			{ name: getMessage('winning'), isActive: number > 50 },
			{ name: getMessage('loss'), isActive: number <= 50 }
		],
		isBetting: true
	}

	const columns: TColumnsTable[] = [
		{
			title: getMessage('id'),
			dataIndex: 'id'
		},
		{
			title: getMessage('date'),
			dataIndex: 'date',
			render: (date: string) => {
				return transformDateLocale(date, true)
			}
		},
		{
			title: getMessage('amount'),
			dataIndex: 'amount'
		},
		{
			title: getMessage('event'),
			dataIndex: 'event'
		},
		{
			title: getMessage('sport_type'),
			dataIndex: 'type'
		},
		{
			title: getMessage('bet'),
			dataIndex: 'bet'
		},
		{
			title: getMessage('result'),
			dataIndex: 'isWin',
			width: '180px',
			align: 'center',
			render: (isWin: boolean, { bet_status }) => {
				const status = isWin
					? 'win'
					: !isWin && STATUS_BET.includes(bet_status)
					? 'loss'
					: 'wait'
				const locale = store.getState().defaultReducer.locale
				const testData = {
					win: { text: messages[locale].win, color: 'active' as 'active' },
					loss: { text: messages[locale].loss, color: 'fall' as 'fall' },
					wait: {
						text: messages[locale].not_calculated,
						color: 'wait' as 'wait'
					}
				}

				return (
					<div className={styles.status}>
						<StatusTable
							isActive={isWin}
							text={testData[status].text}
							colors={[testData[status].color]}
						/>
					</div>
				)
			}
		}
	]

	useEffect(() => {
		const res =
			betsData?.bets.map(item => ({
				id: item.id,
				date: item.createdAt,
				amount: item.amount,
				event: item.opponent_one_name_en + ' - ' + item.opponent_two_name_en,
				type: item.tournament_name_en,
				bet: item.markets.name_en,
				isWin: item.isWin,
				bet_status: item.bet_status
			})) || []

		if (!isError) setDataBetList(res)
		if (isError) setDataBetList([])
	}, [betsData, isError])

	return (
		<div className={styles.wrapper}>
			<div className={styles.background}>
				<div style={{ width: 496 }}>
					<DashboardCard data={data} isLoading={isLoading} />
				</div>
				<BettingHistoryFilter form={form} onSubmit={onSearchHandler} />
				<Table
					isLoading={isFetching}
					styleWrapper={{ height: 400 }}
					columns={columns}
					dataSource={dataBetList}
					wrapperClass={styles.table}
					modifiers={['border', 'header']}
				/>
			</div>
			<div className={styles.pagination}>
				<Pagination
					pageCount={(betsData?.total_bets || 10) / filter.count}
					onPageChange={onChangePage}
				/>
			</div>
		</div>
	)
}
