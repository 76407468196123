import { ItemChat } from '@/entities/chat'
import { Accordion } from '@/shared/ui/accordion'
import { Dispatch, FC, useEffect, useState } from 'react'

import styles from './ChatCategory.module.scss'
import clsx from 'clsx'
import { Input } from '@/shared/ui/form'
import {
	useHistoryAppeals,
	useNewAppeals,
	useWorkspaceAppeals
} from '../../api'
import { IAppeal, IMessage } from '@/shared/services/types/chat.types'
import { useTextLocality } from '@/shared/hooks'
import { useNavigate } from 'react-router-dom'

interface IProps {
	setChat?: Dispatch<React.SetStateAction<IMessage[]>>
	isActiveId?: number | null
	setIsActiveId: React.Dispatch<React.SetStateAction<IAppeal | null>>
}

export const ChatCategory: FC<IProps> = ({ isActiveId, setIsActiveId }) => {
	const readMessage = JSON.parse(
		localStorage.getItem('readMessage') || '[]'
	) as number[]

	const navigate = useNavigate()
	const [searchShow, setSearchShow] = useState(false)
	const { data: news, error } = useNewAppeals()
	const { data: works, error: errorWorks } = useWorkspaceAppeals()
	const { data: history } = useHistoryAppeals()
	const { getMessage } = useTextLocality()
	const [searchText, setSearchText] = useState('')
	const [searchList, setSearchList] = useState<IAppeal[]>([])
	const [newsList, setNewsList] = useState<IAppeal[]>([])
	const [worksList, setWorksList] = useState<IAppeal[]>([])

	useEffect(() => {
		if (errorWorks?.response?.status === 404) setWorksList([])
	}, [errorWorks?.response?.status])

	useEffect(() => {
		if (error?.response?.status === 404) setNewsList([])
	}, [error?.response?.status])

	useEffect(() => {
		if (news) setNewsList(news)
		if (works) setWorksList(works)
	}, [news, works])

	useEffect(() => {
		const result = [
			...(newsList || []),
			...(worksList || []),
			...(history || [])
		].filter(item => {
			const test = searchText.toLowerCase()
			
			return (
				String(item.room_id).includes(test) ||
				String(item.user_name).toLowerCase().includes(test) ||
				String(item.user).toLowerCase().includes(test)||
				String(item.message).toLowerCase().includes(test)
			)
		})

		setSearchList(!searchText.length ? [] : result)
	}, [searchText])

	useEffect(() => {
		const result =
			[...(newsList || []), ...(worksList || []), ...(history || [])].find(
				item => item.room_id === Number(isActiveId)
			) || null

		setIsActiveId(result)
	}, [isActiveId, newsList, worksList, history])

	useEffect(() => {
		const dataId = newsList.map(item => item.room_id)
		const actuallyIdChat = readMessage.filter(id => dataId.includes(id))

		localStorage.setItem('readMessage', JSON.stringify(actuallyIdChat))
	}, [newsList])

	useEffect(() => {
		if (isActiveId) {
			localStorage.setItem(
				'readMessage',
				JSON.stringify([
					...readMessage.filter(id => id !== isActiveId),
					isActiveId
				])
			)
		}
	}, [isActiveId])

	const onSelectAppeal = (item: IAppeal) => {
		navigate(`?id=${item.room_id}`)
	}

	return (
		<>
			<div className={styles.search}>
				<Input
					containerClassName={styles.input}
					name='text'
					modifiers={['white']}
					onChange={e => {
						setSearchText(e.target.value)
						setSearchShow(true)
					}}
					icon={
						<svg
							width='34'
							height='34'
							viewBox='0 0 34 34'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M20.4396 5.06384C18.2854 5.06592 16.2126 5.88576 14.6397 7.35772C13.0668 8.82968 12.1117 10.8438 11.967 12.993C11.8224 15.1423 12.4992 17.2663 13.8607 18.9357L5.36066 27.4357C5.18079 27.5898 5.07321 27.8117 5.06411 28.0483C5.055 28.2851 5.14493 28.5149 5.31247 28.6822C5.48 28.8498 5.70958 28.9397 5.94637 28.9306C6.18297 28.9215 6.40514 28.8139 6.5592 28.634L15.0592 20.134C16.4693 21.2914 18.2143 21.9644 20.0363 22.0534C21.8583 22.1426 23.6608 21.6432 25.1771 20.6289C26.6935 19.6148 27.8432 18.1396 28.4567 16.4216C29.0699 14.7036 29.1143 12.8337 28.5832 11.0884C28.0519 9.34326 26.9735 7.81535 25.5069 6.73026C24.0405 5.64537 22.2638 5.06081 20.4394 5.06349L20.4396 5.06384ZM20.4396 20.3638C18.6385 20.3631 16.9113 19.6476 15.6371 18.3749C14.1411 16.8805 13.4254 14.7753 13.7005 12.6789C13.9758 10.5824 15.2106 8.73326 17.0415 7.67557C18.8724 6.61783 21.0911 6.47212 23.0444 7.28131C24.9981 8.09031 26.464 9.76204 27.0112 11.8045C27.5582 13.847 27.1239 16.0278 25.8362 17.705C24.5485 19.3822 22.5538 20.3648 20.4394 20.3639L20.4396 20.3638Z'
								fill='#676C79'
							/>
						</svg>
					}
				/>
				<div className={clsx(styles.list, searchShow && styles.show)}>
					{!!searchList?.length && (
						<div className={styles.listAppeals}>
							<>
								{searchList?.map(item => (
									<ItemChat
										data={item}
										key={item.room_id}
										isActive={isActiveId === item.room_id}
										onClick={() => {
											onSelectAppeal(item)
										}}
									/>
								))}
							</>
						</div>
					)}
				</div>
			</div>
			<Accordion header={getMessage('newAppeals')} disabled={!newsList?.length}>
				{!!newsList?.length && (
					<div className={styles.listAppeals}>
						<>
							{newsList?.map(item => (
								<ItemChat
									data={item}
									key={item.room_id}
									isRead={!readMessage.includes(item.room_id)}
									isActive={isActiveId === item.room_id}
									onClick={() => {
										onSelectAppeal(item)
									}}
								/>
							))}
						</>
					</div>
				)}
			</Accordion>
			<Accordion
				header={getMessage('workspaceAppeals')}
				disabled={!worksList?.length}
			>
				{!!worksList?.length && (
					<div className={styles.listAppeals}>
						{worksList?.map(item => (
							<ItemChat
								data={item}
								key={item.room_id}
								isActive={isActiveId === item.room_id}
								onClick={() => {
									onSelectAppeal(item)
								}}
							/>
						))}
					</div>
				)}
			</Accordion>
			<Accordion header={getMessage('history')} disabled={!history?.length}>
				{history?.length && (
					<div className={styles.listAppeals}>
						{history?.map(item => (
							<ItemChat
								data={item}
								key={item.room_id}
								isActive={isActiveId === item.room_id}
								onClick={() => {
									onSelectAppeal(item)
								}}
							/>
						))}
					</div>
				)}
			</Accordion>
		</>
	)
}
