import { useAlertMessage } from '@/shared/hooks'
import SchoolService from '@/shared/services/school.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteArticle = () => {
	const { sendAlertError } = useAlertMessage()
	const client = useQueryClient()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['delete product shop'],
		(id: number) => SchoolService.deleteArticle(id)
	)
	const onDeleteArticle = async (id: number, callback?: () => void) => {
		mutateAsync(id)
			.then(res => {
				callback?.()
				client.invalidateQueries({ queryKey: ['articles list'] })
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, onDeleteArticle }
}
