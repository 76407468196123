import { Layout } from '@/shared/ui/layout'
import { ChatData } from '@/widgets/chat-data'
import { useTextLocality } from '@/shared/hooks'

const ChatPage = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='left'
			title={getMessage('dashboard')}
			description={getMessage('chat')}
		>
			<ChatData />
		</Layout>
	)
}

export default ChatPage
