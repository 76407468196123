import clsx from 'clsx'
import { FC, useState } from 'react'
import InputMask from 'react-input-mask'

import { ConnectedInput } from '../form-connect'
import { IPropInput } from '../form.interface'

import styles from './Input.module.scss'
import { HidePassIcon, ShowPassIcon } from '@/shared/assets/common/svg'

const RegularInput = (props: IPropInput) => (
	<InputMask {...props} mask={props.mask!} />
)

export const Input: FC<IPropInput> = ({
	className,
	containerClassName,
	disabled,
	onChange,
	placeholder,
	type,
	value,
	defaultValue,
	checked,
	id,
	name,
	required,
	errorText,
	connect,
	mask = '',
	maskChar,
	registerConfig,
	icon,
	isPassword,
	modifiers,
	label,
	style = {},
	...inputProps
}) => {
	const mod = {
		white: false,
		full: false,
		small: false,
		animatePlaceHolder: false
	}

	modifiers?.forEach(item => {
		mod[item] = true
	})

	const [isViewPassword, setViewPassword] = useState(false)

	const InputComponent: FC<IPropInput> = connect ? ConnectedInput : RegularInput

	return (
		<div className={clsx(styles.wrapper, containerClassName)}>
			{label && (
				<label htmlFor={id || name} className={styles.label}>
					<span>
						{label} {required && '*'}
					</span>
				</label>
			)}
			<InputComponent
				className={clsx(
					className,
					styles.input,
					mod.white && styles.white,
					mod.full && styles.full,
					mod.small && styles.small,
					mod.animatePlaceHolder && styles.animate,
					icon && styles.iconInput,
					disabled && styles.disabled,
					placeholder && styles.placeholderInput,
					errorText && styles.inputError
				)}
				type={isViewPassword ? 'text' : type || 'text'}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				checked={checked || false}
				id={id}
				name={name}
				disabled={disabled}
				mask={mask}
				required={required || false}
				defaultValue={defaultValue}
				maskChar={maskChar}
				style={style}
				{...(connect
					? {
							registerConfig
					  }
					: {})}
				{...inputProps}
			/>
			{required && <div className={styles.required}></div>}

			{icon && <div className={styles.icon}>{icon}</div>}
			{isPassword && (
				<div
					className={styles.glassWrapper}
					onClick={() => {
						setViewPassword(prev => !prev)
					}}
				>
					{isViewPassword ? <HidePassIcon /> : <ShowPassIcon />}
				</div>
			)}
			{errorText && typeof errorText === 'string' && (
				<div className={styles.error}>
					<span>{errorText}</span>
				</div>
			)}
		</div>
	)
}
