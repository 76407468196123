import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { IPropCheckbox } from '../form.interface'

import { ConnectForm } from './ConnectForm'

export const ConnectCheckbox: FC<IPropCheckbox> = ({
	registerConfig,
	...props
}) => {
	return (
		<ConnectForm>
			{({ register, control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name}
					render={({ field: { onChange, onBlur, value } }) => {
						return (
							<input
								onBlur={onBlur}
								{...props}
								type='checkbox'
								onChange={onChange}
								value={value}
								checked={value}
							/>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}
