import { BackgroundAuthJSX } from '@/shared/assets/auth/svg'
import { FC, PropsWithChildren } from 'react'

import styles from './AuthWrapper.module.scss'

export const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>{children}</div>
			<div className={styles.background}>
				<BackgroundAuthJSX />
			</div>
		</div>
	)
}
