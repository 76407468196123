import clsx from 'clsx'
import styles from './TransactionHistory.module.scss'
import { TColumnsTable, Table } from '@/shared/ui/table'
import { useForm } from 'react-hook-form'
import { CalendarInput } from '@/shared/ui/form/calendar-input'
import { Button, FormWrapper } from '@/shared/ui/form'
import { FC, useEffect, useState } from 'react'
import {
	reFormatNum,
	transformDateLocale,
	transformDateToString
} from '@/shared/utils'
import { Pagination } from '@/shared/ui/pagination'
import { useTransactionHistory } from '../../api'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	id: number
}

interface IData {
	id: number
	date: string
	deposits: number
	description: string
	isFall: boolean
}

export const TransactionHistory: FC<IProps> = ({ id }) => {
	const defaultFilter = {
		user_id: id,
		page: 1,
		count: 10
	}
	const [filter, setFilter] = useState<any>(defaultFilter)
	const form = useForm({ mode: 'onChange' })
	const fromDate = form.watch('fromDate')
	const toDate = form.watch('toDate')
	const { getMessage } = useTextLocality()
	const {
		data: transactionData,
		refetch,
		isFetching
	} = useTransactionHistory(filter)

	const [transactionList, setTransactionList] = useState<IData[]>([])

	useEffect(() => {
		refetch()
	}, [filter])

	useEffect(() => {
		const res = {
			fromDate: fromDate && transformDateToString(fromDate),
			toDate: toDate && transformDateToString(toDate, true)
		}

		setFilter({ ...defaultFilter, ...res })
	}, [fromDate, toDate])

	useEffect(() => {
		const res =
			transactionData?.transactions.map(item => ({
				id: item.id,
				date: transformDateLocale(item.timestamp, true),
				deposits: item.amount,
				description: item.model_by,
				isFall: !!item.model_by
			})) || []
		setTransactionList(res)
	}, [transactionData])

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}

	const columns: TColumnsTable[] = [
		{
			title: getMessage('id'),
			dataIndex: 'id'
		},
		{
			title: getMessage('date'),
			dataIndex: 'date'
		},
		{
			title: getMessage('deposits_purchases'),
			dataIndex: 'deposits',
			render: (amount, { isFall }) => {
				return (
					<div className={clsx(styles.amount, isFall && styles.isFall)}>
						{amount ? '$' + reFormatNum(amount) : ''}
					</div>
				)
			}
		},
		{
			title: getMessage('description'),
			dataIndex: 'description'
		},
		{
			title: '',
			dataIndex: 'calendar',
			width: '241px',
			renderHeader: () => {
				return (
					<FormWrapper methods={form}>
						<div className={styles.calendar}>
							<CalendarInput
								connect
								modifier={['white']}
								name='fromDate'
								containerClassName={styles.calendarInput}
								selectsStart
								maxDate={form.watch('toDate')}
								startDate={form.watch('fromDate')}
								selected={form.watch('fromDate')}
								endDate={form.watch('toDate')}
								placeholder={getMessage('from_date')}
							/>
							<span>-</span>
							<CalendarInput
								connect
								name='toDate'
								containerClassName={styles.calendarInput}
								selectsEnd
								modifier={['white']}
								startDate={form.watch('fromDate')}
								endDate={form.watch('toDate')}
								selected={form.watch('toDate')}
								placeholder={getMessage('to_date')}
								minDate={form.watch('fromDate')}
							/>
						</div>
					</FormWrapper>
				)
			}
		}
	]

	return (
		<div className={styles.wrapper}>
			<div className={styles.background}>
				<Table
					isLoading={isFetching}
					styleWrapper={{ height: 600 }}
					columns={columns}
					dataSource={transactionList}
					wrapperClass={styles.table}
					modifiers={['border', 'header']}
				/>
			</div>
			<Pagination
				pageCount={(transactionData?.total_transactions || 10) / filter.count}
				onPageChange={onChangePage}
			/>
		</div>
	)
}
