import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'

import { UsersDashboard } from '@/widgets/users-dashboard'

const Users = () => {
	const { getMessage } = useTextLocality()
	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description='users'
		>
			<UsersDashboard />
		</Layout>
	)
}

export default Users
