import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'
import { ITransactionList } from '@/shared/services/types/statistic.types'
import { useAlertMessage } from '@/shared/hooks'

export const usePaymentList = (data: ITransactionList) => {
	const { sendAlertError } = useAlertMessage()

	return useQuery(
		['get payment list' + JSON.stringify(data)],
		() => StatisticService.getTransactionList(data),
		{
			select: ({ data }) => data,
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)
}
