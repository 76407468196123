import { FormWrapper } from '@/shared/ui/form'
import { CalendarInput } from '@/shared/ui/form/calendar-input'
import { TColumnsTable, Table } from '@/shared/ui/table'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import styles from './GameHistory.module.scss'
import { FC, useEffect, useState } from 'react'
import { Pagination } from '@/shared/ui/pagination'
import { useTransactionGame } from '../../api'
import {
	reFormatNum,
	transformDateLocale,
	transformDateToString
} from '@/shared/utils'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	id: number
}

interface IData {
	id: number
	date: string
	amount: number
	description: string
	isFall: boolean
}

export const GameHistory: FC<IProps> = ({ id }) => {
	const defaultFilter = {
		user_id: id,
		page: 1,
		count: 10
	}
	const [filter, setFilter] = useState<any>(defaultFilter)
	const { getMessage } = useTextLocality()
	const form = useForm({ mode: 'onChange' })
	const { watch } = form
	const fromDate = form.watch('fromDate')
	const toDate = form.watch('toDate')
	const { data: gamesData, refetch, isFetching } = useTransactionGame(filter)

	const [transactionList, setTransactionList] = useState<IData[]>([])

	useEffect(() => {
		refetch()
	}, [filter])

	useEffect(() => {
		const res = {
			fromDate: fromDate && transformDateToString(fromDate),
			toDate: toDate && transformDateToString(toDate, true)
		}


		setFilter({ ...defaultFilter, ...res })
	}, [fromDate, toDate])

	useEffect(() => {
		const res =
			gamesData?.transactions.map(item => ({
				id: item.id,
				date: transformDateLocale(item.timestamp, true),
				amount: item.amount,
				description: item.model_by,
				isFall: !!item.model_by
			})) || []
		setTransactionList(res)
	}, [gamesData])

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}

	const columns: TColumnsTable[] = [
		{
			title: getMessage('id'),
			dataIndex: 'id'
		},
		{
			title: getMessage('date'),
			dataIndex: 'date'
		},
		{
			title: getMessage('amount'),
			dataIndex: 'amount',
			render: (amount, { isFall }) => {
				return (
					<div className={clsx(styles.amount, isFall && styles.isFall)}>
						{amount ? '$' + reFormatNum(amount) : ''}
					</div>
				)
			}
		},
		{
			title: getMessage('game'),
			dataIndex: 'game'
		},
		{
			title: '',
			dataIndex: 'calendar',
			width: '241px',
			renderHeader: () => {
				return (
					<FormWrapper methods={form}>
						<div className={styles.calendar}>
							<CalendarInput
								connect
								modifier={['white']}
								name='fromDate'
								containerClassName={styles.calendarInput}
								selectsStart
								maxDate={watch('toDate')}
								startDate={watch('fromDate')}
								selected={watch('fromDate')}
								endDate={watch('toDate')}
								placeholder={getMessage('from_date')}
							/>
							<span>-</span>
							<CalendarInput
								connect
								name='toDate'
								containerClassName={styles.calendarInput}
								selectsEnd
								modifier={['white']}
								startDate={watch('fromDate')}
								endDate={watch('toDate')}
								selected={watch('toDate')}
								minDate={watch('fromDate')}
								placeholder={getMessage('to_date')}
							/>
						</div>
					</FormWrapper>
				)
			}
		}
	]
	return (
		<div>
			<div className={styles.wrapper}>
				<div className={styles.background}>
					<Table
						styleWrapper={{ height: 675 }}
						isLoading={isFetching}
						columns={columns}
						dataSource={transactionList}
						wrapperClass={styles.table}
						modifiers={['border', 'header']}
					/>
				</div>
				<Pagination
					pageCount={(gamesData?.total_transactions || 0) / filter.count}
					onPageChange={onChangePage}
				/>
			</div>
		</div>
	)
}
