import { axiosInstance } from '@/shared/api'
import { IPaginationDataWithDate } from './types/global.types'

import { getAnalyticsUrl } from '../api/config'
import { IAnalytics, ICreateAnalyticResponse } from './types/analytics.types'
import { format } from 'date-fns'

const AnalyticsService = {
	async getAll({
		page = 1,
		count = 21,
		fromDate = '2023-01-01T00:00:01' ||
			format(new Date('2020-01-24T13:17:31.718321'), 'yyy-MM-dd\'T\'HH:mm:ss'),
		toDate = format(new Date(), 'yyy-MM-dd\'T\'HH:mm:ss')
	}: IPaginationDataWithDate) {
		return axiosInstance.get<IAnalytics[]>(
			getAnalyticsUrl(
				`/list?page=${page}&count=${count}&fromDate=${fromDate}&toDate=${toDate}`
			)
		)
	},

	async getById(id: number) {
		return axiosInstance.get<IAnalytics>(getAnalyticsUrl(`/get/${id}`))
	},

	async buy(id: number) {
		return axiosInstance.get<string>(getAnalyticsUrl(`/buy/${id}`))
	},

	async create(data: FormData) {
		return axiosInstance.post<ICreateAnalyticResponse>(
			getAnalyticsUrl(`/new`),
			data
		)
	},

	async edit(id: number, data: Partial<FormData>) {
		return axiosInstance.patch<string>(getAnalyticsUrl(`/edit/${id}`), data)
	},

	async delete(id: number) {
		return axiosInstance.delete<string>(getAnalyticsUrl(`/delete/${id}`))
	}
}

export default AnalyticsService
