import { FC } from 'react'

import styles from './ContentPanel.module.scss'
import { setLangBackend } from '@/shared/store/actions/default'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { useTextLocality } from '@/shared/hooks'
import { Button, Checkbox } from '@/shared/ui/form'
import { DeleteIconJSX } from '@/shared/assets/common/svg'
import { LineOrTiles } from '@/features/line-or-title/LineOrTitles'
import clsx from 'clsx'
import { Confirmation } from '@/shared/ui/confirmation'

interface IProps {
	setConfirmationDeletion: React.Dispatch<React.SetStateAction<boolean>>
	confirmationRow: boolean
	setConfirmationRow: React.Dispatch<React.SetStateAction<boolean>>
	newTemplateBtn: JSX.Element
	checked: boolean
	onChecked: () => void
	selectedItems: number[]
}

const ContentPanel: FC<IProps> = ({
	setConfirmationDeletion,
	confirmationRow,
	setConfirmationRow,
	newTemplateBtn,
	checked,
	onChecked,
	selectedItems
}) => {
	const { localeInfoWithBackend } = useAppSelector(
		state => state.defaultReducer
	)
	const { getMessage } = useTextLocality()

	const dispatch = useAppDispatch()
	return (
		<div className={styles.wrapper}>
			<div className={styles.leftBlock}>
				<Checkbox
					name='selectAll'
					modifier={['red']}
					checked={checked}
					onChange={onChecked}
				/>

				<Button
					modifiers={['inverse']}
					svg={<DeleteIconJSX />}
					disabled={!selectedItems.length}
					onClick={() => {
						setConfirmationDeletion(true)
					}}
				>
					{getMessage('delete')}
				</Button>
			</div>
			<div className={styles.rightBlock}>
				<div className={styles.buttons}>
					<Button
						className={clsx(
							localeInfoWithBackend === 'en' && styles.buttonActive
						)}
						modifiers={['inverse']}
						onClick={() => dispatch(setLangBackend('en'))}
					>
						{getMessage('english')}
					</Button>
					<Button
						className={clsx(
							localeInfoWithBackend === 'cn' && styles.buttonActive
						)}
						modifiers={['inverse']}
						onClick={() => dispatch(setLangBackend('cn'))}
					>
						{getMessage('chinese')}
					</Button>
				</div>
				<LineOrTiles
					confirmationRow={confirmationRow}
					setConfirmationRow={setConfirmationRow}
				/>
				{newTemplateBtn}
			</div>
		</div>
	)
}

export default ContentPanel
