import { FC } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

import { IPropsSelect } from '../form.interface'

import { ConnectForm } from './ConnectForm'

export const ConnectSelect: FC<IPropsSelect> = ({
	registerConfig,
	...props
}) => {
	return (
		<ConnectForm>
			{({ control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name}
					render={({ field: { onChange, onBlur, ref, value } }) => {
						return (
							<Select
								{...props}
								onBlur={onBlur}
								onChange={data => {
									onChange(data?.value)
								}}
								onInputChange={props?.onInputChange}
								inputValue={props.inputValue}
								classNamePrefix={'react-select'}
								value={
									props.options.find(c => c.value === value) ||
									props.defaultValue
								}
								ref={ref}
							/>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}
