import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'
import { IUserBets } from '@/shared/services/types/statistic.types'
import { useAlertMessage } from '@/shared/hooks'

export const useGetBetsAll = (dataBet: IUserBets, count = 21) => {
	const { sendAlertError } = useAlertMessage()
	const { data, refetch, isFetching, isLoading, isError } = useQuery(
		['get all bets'],
		({ pageParam = 1 }) => StatisticService.getUsersBets({ ...dataBet }),
		{
			getNextPageParam: (lastPage, pages) => {
				if (!lastPage.data.bets.length || lastPage.data.bets.length < count)
					return null
				return pages.length + 1
			},
			select: ({ data }) => {
				return data
			},
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)

	return {
		data,
		isFetching,
		isLoading,
		refetch,
		isError
	}
}
