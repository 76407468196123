import SchoolService from '@/shared/services/school.service'
import { IArticle } from '@/shared/services/types/school.types'
import { sortDataInfinityPages } from '@/shared/utils'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export const useArticlesList = (value: number, category_id: number) => {
	const [dataList, setDataList] = useState<IArticle[] | null>(null)

	const {
		data,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		['articles list', category_id],
		({ pageParam = 1 }) =>
			SchoolService.getArticles({ count: value, page: pageParam, category_id }),
		{
			getNextPageParam: (lastPage, pages) => {
				if (!lastPage.data.length || lastPage.data.length < value) return null
				return pages.length + 1
			},
			select: data => {
				const res = data.pages.map(item => item.data)
				return { ...data, pages: res }
			}
		}
	)

	useEffect(() => {
		const result = sortDataInfinityPages(data)

		if (!isLoading) setDataList(result)
	}, [data?.pages])

	return {
		data: dataList,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	}
}
