import { setNavigation } from '@/shared/store/actions/default'
import { SearchInput } from '@/features/header/ui/search'
import { useAppDispatch, useAppSelector } from '@/shared/model'

import { ButtonGroup, ProfileBlock } from '@/features/header/ui'

export function Header() {
	const { showNavigation } = useAppSelector(state => state.defaultReducer)

	const dispatch = useAppDispatch()

	return (
		<>
			<div className='App-header'>
				<div className='header-leftBlock'>
					<button onClick={() => dispatch(setNavigation(!showNavigation))}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='18'
							viewBox='0 0 24 18'
							fill='none'
						>
							<path
								d='M0.333252 1.99409C0.333252 1.35242 0.853585 0.833252 1.49409 0.833252H10.8391C11.147 0.833252 11.4422 0.955553 11.6599 1.17325C11.8776 1.39095 11.9999 1.68621 11.9999 1.99409C11.9999 2.30196 11.8776 2.59722 11.6599 2.81492C11.4422 3.03262 11.147 3.15492 10.8391 3.15492H1.49409C1.18621 3.15492 0.890951 3.03262 0.673252 2.81492C0.455554 2.59722 0.333252 2.30196 0.333252 1.99409ZM0.333252 8.99992C0.333252 8.35825 0.853585 7.83909 1.49409 7.83909H22.5058C22.8136 7.83909 23.1089 7.96139 23.3266 8.17909C23.5443 8.39679 23.6666 8.69205 23.6666 8.99992C23.6666 9.30779 23.5443 9.60306 23.3266 9.82075C23.1089 10.0385 22.8136 10.1608 22.5058 10.1608H1.49409C1.18621 10.1608 0.890951 10.0385 0.673252 9.82075C0.455554 9.60306 0.333252 9.30779 0.333252 8.99992ZM1.49409 14.8449C1.18621 14.8449 0.890951 14.9672 0.673252 15.1849C0.455554 15.4026 0.333252 15.6979 0.333252 16.0058C0.333252 16.3136 0.455554 16.6089 0.673252 16.8266C0.890951 17.0443 1.18621 17.1666 1.49409 17.1666H15.5058C15.8136 17.1666 16.1089 17.0443 16.3266 16.8266C16.5443 16.6089 16.6666 16.3136 16.6666 16.0058C16.6666 15.6979 16.5443 15.4026 16.3266 15.1849C16.1089 14.9672 15.8136 14.8449 15.5058 14.8449H1.49409Z'
								fill='#F4F4F9'
							/>
						</svg>
					</button>
					<SearchInput />
				</div>
				<div className='header-rightBlock'>
					<ButtonGroup />
					<ProfileBlock />
				</div>
			</div>
		</>
	)
}
