import { useAlertMessage } from '@/shared/hooks'
import ChatService from '@/shared/services/chat.service'
import { useQuery } from '@tanstack/react-query'

export const useNewAppeals = (notMessage?: boolean) => {
	const { sendAlertError, sendAlertMessage } = useAlertMessage('info')
	return useQuery(
		['get new appeals', notMessage],
		() => ChatService.getNewAppeals(),
		{
			select: ({ data }) => {
				return data
			},
			retry: 0,
			cacheTime: 0,
			onError: (error: any) => {
				if (notMessage) return
				if (error.response?.status === 404) {
					sendAlertMessage(error.response.data.detail || '')
					return []
				}
				sendAlertError(error)
			}
		}
	)
}
