import { FC } from 'react'
import { Controller } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

import { IPropInput } from '../form.interface'

import { ConnectForm } from './ConnectForm'

export const ConnectedInput: FC<IPropInput> = ({
	registerConfig,
	...props
}) => {
	return (
		<ConnectForm>
			{({ register, control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name} //@ts-ignore
					ref={{ ...register(props.name, registerConfig) }}
					render={({ field: { onChange, onBlur, value, ref } }) => {
						return (
							<ReactInputMask
								{...props}
								onBlur={() => {
									onBlur()
									props.onBlur?.()
								}}
								onChange={onChange}
								value={value}
								inputRef={ref}
								mask={props.mask!}
							/>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}
