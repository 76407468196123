import { AnalyticForm, HeadingAnalyticForm } from '@/entities/analytic'
import { useTextLocality } from '@/shared/hooks'
import { Button, FormWrapper } from '@/shared/ui/form'
import React, { FC, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import styles from './AnalyticForm.module.scss'
import { languages } from '@/shared/constants'
import { PublishIconJSX } from '@/shared/assets/common/svg'

interface IProps {
	setConfirmationSubmit: React.Dispatch<React.SetStateAction<boolean>>
	setEventForm: React.Dispatch<React.SetStateAction<FieldValues>>
}

export const AnalyticsForm: FC<IProps> = ({
	setConfirmationSubmit,
	setEventForm
}) => {
	const [valuesHead, setValuesHead] = useState<FieldValues>()
	const { getMessage } = useTextLocality()

	const form = useForm()

	const onSubmitHandler = (values: FieldValues) => {
		setEventForm({ ...values })
		setConfirmationSubmit(true)
	}

	return (
		<FormWrapper
			className={styles.wrapper}
			methods={form}
			onSubmit={onSubmitHandler}
		>
			<HeadingAnalyticForm setHeadForm={setValuesHead} form={form} />
			<div className={styles.forms}>
				{languages.map((item, index) => (
					<AnalyticForm
						key={index}
						{...item}
						form={form}
						onSubmit={(values: FieldValues) => {
							setConfirmationSubmit(true)
							setEventForm({ ...values, ...valuesHead })
						}}
					/>
				))}
			</div>

			<div>
				<Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish_all')}
				</Button>
			</div>
		</FormWrapper>
	)
}
