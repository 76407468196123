import clsx from 'clsx'
import { FC } from 'react'

import { Calendar } from '../calendar'
import { ConnectCalendar } from '../form-connect'
import { IPropCalendar } from '../form.interface'

import styles from './CalendarInput.module.scss'

export const CalendarInput: FC<IPropCalendar> = ({
	connect,
	registerConfig,
	containerClassName,
	name,
	onChange,
	defaultValue,
	value,
	calendarRef,
	modifier,
	...restProps
}) => {
	const CalendarComponent = connect ? ConnectCalendar : Calendar
	const mod = {
		white: false
	}

	modifier?.forEach(item => {
		mod[item] = true
	})

	return (
		<div
			className={clsx(
				styles.wrapper,
				mod.white && styles.white,
				containerClassName
			)}
		>
			<CalendarComponent
				name={name}
				value={value}
				defaultValue={defaultValue}
				calendarRef={calendarRef}
				onChange={onChange}
				{...(connect
					? {
							registerConfig
					  }
					: {})}
				{...restProps}
			/>
		</div>
	)
}
