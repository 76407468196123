import { TLanguages, typeAction, typeDefaultState } from '../typesStore'
import { LOCALES } from '@/i18n/locales'

const locale = localStorage.getItem('locale') as TLanguages
const localeInfoWithBackend = localStorage.getItem('localeInfoWithBackend')

const defaultState: typeDefaultState = {
	urlBackend: 'https://api.lebao.fun/',
	urlAdminPath: 'api/admin/',
	locale: locale ? locale : LOCALES.RUSSIA,
	localeInfoWithBackend: localeInfoWithBackend ? localeInfoWithBackend : 'en',
	loader: false,
	showNavigation: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: typeAction) {
	const { type, value } = action
	switch (type) {
		case 'SET_LOCALE': {
			const { value } = action
			return {
				...state,
				locale: value as TLanguages
			}
		}
		case 'SET_LOCALE_WITH_BACKEND':
			return {
				...state,
				localeInfoWithBackend: value
			}
		case 'SET_LOADER':
			return {
				...state,
				loader: value
			}
		case 'SET_NAVIGATION':
			return {
				...state,
				showNavigation: value
			}
		default:
			return state
	}
}
