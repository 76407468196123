import cn from 'clsx'
import { forwardRef } from 'react'
import 'react-datepicker/src/stylesheets/datepicker.scss'

import styles from './CustomInput.module.scss'
import { ICustomInput } from './calendar.interface'
import { CalendarIconJSX, DeleteIconJSX } from '@/shared/assets/common/svg'

const CustomInput = forwardRef<HTMLInputElement, ICustomInput>(
	(
		{ onClick, onClickHandler, onChange, className, onClearDate, ...rest },
		ref
	) => {
		return (
			<div className={styles.wrapper}>
				<label
					className={cn(styles.input, className)}
					onClick={() => {
						onClickHandler()
					}}
				>
					<input
						ref={ref}
						onChange={onChange}
						placeholder=''
						{...rest}
						autoComplete='off'
					/>

					{!rest.value && <CalendarIconJSX />}
				</label>
				<div className={styles.icon}>
					{rest.value && (
						<div className={styles.delete} onClick={onClearDate}>
							<DeleteIconJSX />
						</div>
					)}
				</div>
			</div>
		)
	}
)

export default CustomInput
