import { AnalyticsForm } from '@/features/analytics/analytics-form'
import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { PageForm } from '@/widgets/page-form'
import { useState } from 'react'
import { FieldValues } from 'react-hook-form'

const AnalyticDetailEdit = () => {
	const [confirmationSubmit, setConfirmationSubmit] = useState(false)
	const [eventForm, setEventForm] = useState<FieldValues>({})
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			title={getMessage('analytics')}
			description={getMessage('edit_analytic')}
		>
			<PageForm
				confirmationSubmit={confirmationSubmit}
				eventForm={eventForm}
				setConfirmationSubmit={setConfirmationSubmit}
			>
				<>
					<AnalyticsForm
						setConfirmationSubmit={setConfirmationSubmit}
						setEventForm={setEventForm}
					/>
				</>
			</PageForm>
		</Layout>
	)
}

export default AnalyticDetailEdit
