import { IPaginationData } from './types/global.types'
import {
	IArticle,
	ICategory,
	IGetArticlesData,
	ISingleCategory,
} from './types/school.types'
import { axiosInstance } from '@/shared/api'

import { getSchoolUrl } from '../api/config'

const SchoolService = {
	async getCategories({ page, count }: IPaginationData) {
		return axiosInstance.get<ICategory[]>(getSchoolUrl(`/category/list`), {
			params: {
				page,
				count
			}
		})
	},

	async getCategoryById(id: number) {
		return axiosInstance.get<ISingleCategory>(getSchoolUrl(`/category/${id}`))
	},

	async getArticles({ count, page, category_id }: IGetArticlesData) {
		return axiosInstance.get<IArticle[]>(
			getSchoolUrl(`/by_category/${category_id}`),
			{
				params: {
					page,
					count
				}
			}
		)
	},

	async getArticleById(id: number) {
		return axiosInstance.get<IArticle>(getSchoolUrl(`/get/${id}`))
	},

	async buyCategory(id: number) {
		return axiosInstance.get<string>(getSchoolUrl(`/category/buy/${id}`))
	},

	async createCategory(data: FormData) {
		return axiosInstance.post<ISingleCategory>(
			getSchoolUrl('/category/new'),
			data
		)
	},

	async editCategory(id: number, data: FormData) {
		return axiosInstance.patch<ISingleCategory>(
			getSchoolUrl(`/category/edit/${id}`),
			data
		)
	},

	async activateCategory(id: number, isActive: boolean) {
		return axiosInstance.patch<string>(
			getSchoolUrl(`/category/is_active/${id}?is_active=${isActive}`)
		)
	},
	async createArticle(data: FormData) {
		return axiosInstance.post<any>(getSchoolUrl('/new'), data)
	},

	async activateArticle(id: number, isActive: boolean) {
		return axiosInstance.patch<IArticle>(
			getSchoolUrl(`/is_active/${id}?is_active=${isActive}`)
		)
	},

	async editArticle(id: number, data: Partial<FormData>) {
		return axiosInstance.patch<IArticle>(getSchoolUrl(`/edit/${id}`), data)
	},

	async deleteArticle(id: number) {
		return axiosInstance.delete<string>(getSchoolUrl(`/delete/${id}`))
	}
}

export default SchoolService
