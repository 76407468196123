import { FC } from 'react'
import { Controller } from 'react-hook-form'
import ReactTextareaAutosize from 'react-textarea-autosize'

import { ITextArea } from '../form.interface'

import { ConnectForm } from './ConnectForm'

export const ConnectedTextArea: FC<ITextArea> = ({
	registerConfig,
	...props
}) => {
	return (
		<ConnectForm>
			{({ register, control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name}
					render={({ field: { onChange, onBlur, ref, value } }) => {
						return (
							<ReactTextareaAutosize
								{...props}
								value={value}
								onBlur={onBlur}
								ref={ref}
								onChange={onChange}
							/>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}
