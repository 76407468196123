import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { EditorFields, FormWrapper } from '@/shared/ui/form'
import { useAnalyticDetail } from '../../api'
import { Loader } from '@/shared/ui/loader'
import { useTextLocality } from '@/shared/hooks'
import { FormProps } from '@/shared/constants.interface'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'

interface IPropsDefaultForm {
	lang: string
	typeSubmit: string
	id: string
	text_en: string
	text_cn: string
}

interface IProps extends FormProps {
	form: UseFormReturn<FieldValues, any, undefined>
}

export const AnalyticForm: FC<IProps> = ({
	lang,
	short,
	textPlaceholder,
	form,
	onSubmit
}) => {
	const { id } = useParams()
	const { getMessage } = useTextLocality()
	const { data, isFetching } = useAnalyticDetail(id!)

	const { locale } = useAppSelector(state => state.defaultReducer)

	const {
		formState: { errors }
	} = form

	const defaultValues = {
		lang: short,
		typeSubmit: id ? 'analytic' : 'new_analytic',
		id: id
	} as IPropsDefaultForm

	const onSubmitForm = (values: FieldValues) => {
		onSubmit(values)
	}

	useEffect(() => {
		if (!id || !data) {
			form.reset({
				...defaultValues
			})
		} else {
			form.reset({
				...defaultValues,
				...form.getValues(),
				text_cn: data.text_cn,
				text_en: data.text_en
			})
		}
	}, [data])

	return (
		<>
			<FormWrapper
				className={'PageEditOrNewForm'}
				methods={form}
				onSubmit={onSubmitForm}
			>
				{isFetching && <Loader absolute />}
				<div className='langTitle'>{lang}</div>
				<div className='required'>* {getMessage('required_field_tool')}</div>
				<EditorFields
					connect
					name={'text_' + short}
					label={getMessage('description')}
					required
					placeholder={textPlaceholder}
					errorText={errors?.['sportId']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				{/* <div style={{ marginTop: '15px' }}>
					<Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
						{getMessage('publish')}
					</Button>
				</div> */}
			</FormWrapper>
		</>
	)
}
