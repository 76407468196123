import { useEffect, useState } from 'react'
import { useLineOrTiles } from '@/shared/hooks/useLineOrTiles'
import '@/Assets/sass/Pages/School/Article.sass'
import { FormattedMessage } from 'react-intl'
import ContentPanel from '@/entities/content-panel'
import { useAnalyticsList, useDeleteAnalytic } from '@/entities/analytic/api'
import clsx from 'clsx'
import { AnalyticSchoolCard } from '@/entities/analytic/ui/analytic-card'
import { Loader } from '@/shared/ui/loader'
import { useInView } from 'react-intersection-observer'
import { Confirmation } from '@/shared/ui/confirmation'
import { useTextLocality, useTranslationField } from '@/shared/hooks'
import { Button } from '@/shared/ui/form'
import styles from './AnalyticList.module.scss'
import { IAnalytics } from '@/shared/services/types/analytics.types'
import { AddItemJSX } from '@/shared/assets/common/svg'
const mdata: IAnalytics = {
	id: 0,
	createdAt: '2023-10-20T15:02:56.416Z',
	text_en: 'string',
	text_cn: 'string',
	rating_buy: 0,
	price: 0,
	author_id: 0,
	author_name: 'string',
	game_start: '2023-10-20T15:02:56.416Z',
	isDeleted: true,
	tournament: {
		tournament_id: 0,
		image: 'string',
		name_en: 'string',
		name_cn: 'string',
		sport: {
			id: 0,
			name_en: 'string',
			name_cn: 'string'
		}
	},
	game: {
		main_const_sport_event_id: 0,
		start_date: '2023-10-20T15:02:56.416Z',
		opponent_one_image: ['string'],
		opponent_two_image: ['string'],
		opponent_one_name_en: 'string',
		opponent_one_name_cn: 'string',
		opponent_two_name_en: 'string',
		opponent_two_name_cn: 'string'
	},
	market: [
		{
			pk: 'string',
			market: 0,
			name_en: 'string',
			name_cn: 'string',
			full_name_en: 'string',
			full_name_cn: 'string',
			updated_at: '2023-10-20T15:02:56.416Z',
			event_id: 0,
			is_blocked: true,
			previous_updated_at: '2023-10-20T15:02:56.416Z'
		}
	]
}

export const AnalyticsList: (props: {}) => JSX.Element = () => {
	const { getMessage } = useTextLocality()
	const [selectedShop, setSelectedShop] = useState<number[]>([])
	const { getField } = useTranslationField()
	const { onDeleteAnalytic } = useDeleteAnalytic()
	const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
		useAnalyticsList(12)
	const [analyticsNowID, setAnalyticsNowID] = useState<number>()

	const { ref, inView } = useInView({
		threshold: 0
	})

	const [confirmationDeletion, setConfirmationDeletion] = useState(false)

	const { confirmationRow, setConfirmationRow } = useLineOrTiles()

	useEffect(() => {
		if (!confirmationDeletion) setAnalyticsNowID(0)
	}, [confirmationDeletion])

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	})

	const onAlSelected = () => {
		const res = data?.map(item => item.id) || []
		if (selectedShop.length === data?.length) return setSelectedShop([])
		setSelectedShop(res)
	}

	const onAlSelectedCard = (id: number) => {
		const isActive = selectedShop.find(val => val === id)
		const newSelected = selectedShop.filter(val => val !== id)
		if (isActive) return setSelectedShop(newSelected)
		setSelectedShop([...selectedShop, id])
	}

	return (
		<>
			<ContentPanel
				setConfirmationDeletion={setConfirmationDeletion}
				confirmationRow={confirmationRow}
				setConfirmationRow={setConfirmationRow}
				checked={!!data?.length && selectedShop.length === data?.length}
				onChecked={onAlSelected}
				selectedItems={selectedShop}
				newTemplateBtn={
					<Button
						modifiers={['inverse']}
						svg={<AddItemJSX />}
						link='/content/analytics/new'
					>
						{getMessage('new_analytic')}
					</Button>
				}
			/>
			<div className={clsx(styles.wrapperList, confirmationRow)}>
				{data?.map(itemAnalytic => (
					<AnalyticSchoolCard
						isLine={confirmationRow}
						checkedCard={!!selectedShop.find(id => id === itemAnalytic.id)}
						onChecked={onAlSelectedCard}
						setAnalyticsID={setAnalyticsNowID}
						key={itemAnalytic.id}
						setConfirmationDeletion={setConfirmationDeletion}
						text={getField(itemAnalytic, 'text')}
						image={getField(itemAnalytic, 'image')}
						game={itemAnalytic.game}
						id={itemAnalytic.id}
					/>
				))}
			</div>

			{!isLoading && !data?.length && <FormattedMessage id='not_found' />}
			<div ref={ref} />

			{isLoading && <Loader />}
			{isFetchingNextPage && <Loader isMini />}

			<Confirmation
				title={getMessage('confirm_ask')}
				isOpen={confirmationDeletion}
				setConfirmationState={() => {
					const arr = analyticsNowID ? [analyticsNowID] : selectedShop

					arr.forEach(item => {
						onDeleteAnalytic(item)
					})
				}}
				setConfirmationOpen={setConfirmationDeletion}
			/>
		</>
	)
}
