import { ShopCard } from '@/entities/shop/ui'
import {
	useLineOrTiles,
	useTextLocality,
	useTranslationField
} from '@/shared/hooks'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'

import styles from './ShopList.module.scss'
import ContentPanel from '@/entities/content-panel'
import { useDeleteProduct, useShopList } from '@/entities/shop/api'
import { Loader } from '@/shared/ui/loader'
import { FormattedMessage } from 'react-intl'
import { useInView } from 'react-intersection-observer'
import { Confirmation } from '@/shared/ui/confirmation'
import { Button } from '@/shared/ui/form'
import { AddItemJSX } from '@/shared/assets/common/svg'

export const ShopList: FC = () => {
	const { getMessage } = useTextLocality()
	const { getField } = useTranslationField()
	const [selectedShop, setSelectedShop] = useState<number[]>([])

	const [confirmationDeletion, setConfirmationDeletion] = useState(false)
	const [productID, setProductID] = useState<number | null>(null)

	useEffect(() => {
		if (!confirmationDeletion) setProductID(null)
	}, [confirmationDeletion])

	const { ref, inView } = useInView({
		threshold: 0
	})
	const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
		useShopList(12)

	const { onDeleteProduct } = useDeleteProduct()

	const { confirmationRow, setConfirmationRow } = useLineOrTiles()

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	})

	const onAlSelected = () => {
		const res = data?.map(item => item.id) || []
		if (selectedShop.length === data?.length) return setSelectedShop([])
		setSelectedShop(res)
	}

	const onAlSelectedCard = (id: number) => {
		const isActive = selectedShop.find(val => val === id)
		const newSelected = selectedShop.filter(val => val !== id)
		if (isActive) return setSelectedShop(newSelected)
		setSelectedShop([...selectedShop, id])
	}

	return (
		<>
			<ContentPanel
				confirmationRow={confirmationRow}
				setConfirmationDeletion={setConfirmationDeletion}
				setConfirmationRow={setConfirmationRow}
				selectedItems={selectedShop}
				checked={!!data?.length && selectedShop.length === data?.length}
				onChecked={onAlSelected}
				newTemplateBtn={
					<Button
						modifiers={['inverse']}
						svg={<AddItemJSX />}
						link='/content/shop/add-product'
						onClick={onAlSelected}
					>
						{getMessage('new_product')}
					</Button>
				}
			/>
			<div className={clsx(styles.productsList)}>
				{data?.map(item => (
					<ShopCard
						id={item.id}
						key={item.id}
						checkedCard={!!selectedShop.find(id => id === item.id)}
						onChecked={onAlSelectedCard}
						is_active={item.isActive}
						isLine={confirmationRow}
						name={getField(item, 'name')}
						description={getField(item, 'Description')}
						image={getField(item, 'Image')}
						price={item.price}
						promo_code={item.promocode}
						setProductID={setProductID}
						setConfirmationDeletion={setConfirmationDeletion}
					/>
				))}
			</div>
			{!isLoading && !data?.length && <FormattedMessage id='not_found' />}
			<div ref={ref} />

			{isLoading && <Loader />}
			{isFetchingNextPage && <Loader isMini />}

			<Confirmation
				isOpen={confirmationDeletion}
				title={getMessage('confirm_ask')}
				setConfirmationState={() => {
					const arr = productID ? [productID] : selectedShop
					arr.forEach(item => {
						onDeleteProduct(item)
					})
				}}
				setConfirmationOpen={setConfirmationDeletion}
			/>
		</>
	)
}
