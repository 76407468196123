import { FC, useEffect, useRef, useState } from 'react'
import { GeneralStatisticCard } from '@/entities/statistics/ui'
import styles from './GeneralStatistic.module.scss'
import { TColumnsTable, Table } from '@/shared/ui/table'
import { Pagination } from '@/shared/ui/pagination'
import { Button, FormWrapper, RadioGroup } from '@/shared/ui/form'
import { DropDownMenu } from '@/shared/ui/drop-down-menu'
import { FilterIconJSX } from '@/shared/assets/common/svg'
import clsx from 'clsx'
import { FieldValues, useForm } from 'react-hook-form'
import { StatusTable } from '@/entities/users/ui'
import { messages } from '@/i18n/messages'
import store from '@/shared/store/store'
import { useTextLocality } from '@/shared/hooks'
import { useGetBetsAll } from '../api'
import { STATUS_BET } from '@/shared/constants'

interface ITableBets {
	id: number
	date: string
	amount: number
	event: string
	type: string
	bet: string
	isWin: boolean
	bet_status: 'True' | 'False' | string
}

export const GeneralStatistic: FC = () => {
	const { getMessage } = useTextLocality()
	const defaultFilter = {
		page: 1,
		count: 10
	}
	const [filter, setFilter] = useState<any>(defaultFilter)
	const [dataBetList, setDataBetList] = useState<ITableBets[]>([])

	const { data: dataBets, isFetching, refetch, isError } = useGetBetsAll(filter)
	const refFilter = useRef(null)
	const form = useForm()

	const onSubmitFilter = (data: FieldValues) => {
		console.log({ data })
	}

	const onResetFilter = () => {
		form.reset({})
	}

	useEffect(() => {
		refetch()
	}, [filter])

	useEffect(() => {
		const res =
			dataBets?.bets.map(item => ({
				id: item.id,
				date: item.createdAt,
				amount: item.amount,
				event: item.opponent_one_name_en + ' - ' + item.opponent_two_name_en,
				type: item.tournament_name_en,
				bet: item.markets.name_en,
				isWin: item.isWin,
				bet_status: item.bet_status
			})) || []
		setDataBetList(res)
	}, [dataBets])

	const columns: TColumnsTable[] = [
		{
			title: getMessage('id'),
			dataIndex: 'id'
		},
		{
			title: getMessage('date'),
			dataIndex: 'date'
		},
		{
			title: getMessage('amount'),
			dataIndex: 'amount'
		},
		{
			title: getMessage('event'),
			dataIndex: 'event'
		},
		{
			title: getMessage('sport_type'),
			dataIndex: 'type'
		},
		{
			title: getMessage('bet'),
			dataIndex: 'bet'
		},
		{
			title: getMessage('result'),
			dataIndex: 'isWin',
			render: (isWin: boolean, { bet_status }) => {
				const status = isWin
					? 'win'
					: !isWin && STATUS_BET.includes(bet_status)
					? 'loss'
					: 'progress'
				const locale = store.getState().defaultReducer.locale
				const testData = {
					win: { text: messages[locale].win, color: 'active' as 'active' },
					loss: { text: messages[locale].loss, color: 'fall' as 'fall' },
					progress: {
						text: messages[locale].in_progress,
						color: 'progress' as 'progress'
					}
				}

				return (
					<div className={styles.status}>
						<StatusTable
							isActive={isWin}
							text={testData[status].text}
							colors={[testData[status].color]}
						/>
					</div>
				)
			}
		},
		{
			title: '',
			dataIndex: 'filter',
			renderHeader: () => {
				const options = columns
					.filter(item => item.title)
					.map(item => ({
						value: item.dataIndex,
						label: item.title
					}))
				return (
					<div>
						<FormWrapper
							methods={form}
							className={styles.wrapper}
							onSubmit={onSubmitFilter}
						>
							<div className={styles.buttons}>
								<DropDownMenu
									refs={[refFilter]}
									header={
										<button
											className={clsx(
												styles.filterButton,
												form.watch('sort') && styles.activeButton
											)}
										>
											<FilterIconJSX />
										</button>
									}
									modifiers={['shadow', 'arrow', 'right']}
								>
									<div className={styles.wrapperFilter}>
										<div className={styles.title}>{getMessage('filters')}</div>
										<div className={styles.blockFilter}>
											<RadioGroup connect name='sort' options={options} />
										</div>
										<div className={styles.buttons} ref={refFilter}>
											<Button
												modifiers={['inverse']}
												type='reset'
												onClick={onResetFilter}
											>
												{getMessage('clear_filter')}
											</Button>
											<Button modifiers={['inverse', 'red']}>
												{getMessage('filter')}
											</Button>
										</div>
									</div>
								</DropDownMenu>
							</div>
						</FormWrapper>
					</div>
				)
			}
		}
	]

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}
	const onChangeCountItem = (count: number) => {
		setFilter({ ...filter, count, page: 1 })
	}
	return (
		<div className={styles.wrapper}>
			<div className={styles.sections}>
				<GeneralStatisticCard />
			</div>
			<div className={styles.backgroundWrap}>
				<div className={styles.table}>
					<Table
						styleWrapper={{ minHeight: 530, overflow: 'auto', maxHeight: 555 }}
						modifiers={['header', 'border']}
						isLoading={isFetching}
						columns={columns}
						dataSource={dataBetList}
					/>
				</div>
			</div>

			<Pagination
				pageCount={(dataBets?.total_bets || 10) / filter.count}
				onPageChange={onChangePage}
				current={isError ? 0 : filter.page - 1}
				countItems={filter.count}
				setCountItems={onChangeCountItem}
			/>
		</div>
	)
}
