import { FC, Fragment, ReactNode, useState } from 'react'

import styles from './Table.module.scss'
import { Checkbox } from '../form'
import clsx from 'clsx'
import { Loader } from '../loader'
import { useTextLocality } from '@/shared/hooks'

type TUnknowObject = {
	[key: string]: string | number | undefined | boolean | null
}

export type TColumnsTable = {
	title: string
	dataIndex: string
	width?: string
	align?:
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'match-parent'
	render?: (field: any, allData: any) => ReactNode
	renderHeader?: (column: any) => ReactNode
}

interface IProps {
	dataSource: any[]
	columns: TColumnsTable[]
	isChecked?: boolean
	wrapperClass?: string
	modifiers?: ('border' | 'header')[]
	styleWrapper?: React.CSSProperties
	isLoading?: boolean
}

export const Table: FC<IProps> = ({
	columns,
	dataSource,
	isChecked,
	wrapperClass,
	modifiers,
	styleWrapper,
	isLoading
}) => {
	const { getMessage } = useTextLocality()
	const mod = {
		border: false,
		header: false
	}
	const [checked, setChecked] = useState<any[]>([])

	modifiers?.forEach(item => {
		mod[item] = true
	})

	const allSelected = () => {
		if (checked.length) {
			setChecked([])
		} else {
			setChecked(dataSource.map((item, index) => ({ ...item, index })))
		}
	}

	const selectedItem = (data: any) => {
		const isHaveItem = checked.find(
			item => JSON.stringify(item) === JSON.stringify(data)
		)

		if (isHaveItem) {
			const newChecked = checked.filter(
				item => JSON.stringify(item) !== JSON.stringify(data)
			)
			setChecked(newChecked)
		} else {
			setChecked(prev => [...prev, data])
		}
	}

	return (
		<div
			className={clsx(
				styles.wrapper,
				mod.border && styles.border,
				mod.header && styles.header,
				wrapperClass
			)}
			style={styleWrapper}
		>
			<table className={clsx(styles.table)}>
				<thead>
					<tr>
						{isChecked && (
							<th style={{ width: 56, textAlign: 'center' }}>
								<Checkbox
									name='all'
									checked={dataSource.length === checked.length}
									onChange={allSelected}
									modifier={['red']}
								/>
							</th>
						)}
						{columns.map(item => (
							<th
								style={{ width: item.width, textAlign: item?.align }}
								key={item.dataIndex}
							>
								{item.renderHeader ? item.renderHeader(item) : item.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{dataSource?.map((item, index) => (
						<tr key={index}>
							{isChecked && (
								<td style={{ textAlign: 'center' }}>
									<Checkbox
										modifier={['red']}
										name={`item_${index}`}
										checked={checked.find(
											values =>
												JSON.stringify(values) ===
												JSON.stringify({ ...item, index })
										)}
										onChange={() => {
											selectedItem({ ...item, index })
										}}
									/>
								</td>
							)}

							{columns.map((val, i) => {
								const valueData = item?.[val.dataIndex]
								return (
									<Fragment key={i + String(valueData)}>
										{val.render ? (
											<td style={{ width: val.width, textAlign: val?.align }}>
												{val.render(valueData, item)}
											</td>
										) : (
											<td style={{ width: val.width, textAlign: val?.align }}>
												{valueData && String(valueData)}
											</td>
										)}
									</Fragment>
								)
							})}
						</tr>
					))}
				</tbody>
				{!isLoading && !dataSource.length && (
					<div>{getMessage('not_found')}</div>
				)}
			</table>
			{isLoading && <Loader isMini absolute transparent />}
		</div>
	)
}
