import { useAlertMessage } from '@/shared/hooks'
import NewsService from '@/shared/services/news.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useAddNew = () => {
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const { sendAlertError, sendAlertMessage } = useAlertMessage()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['create new'],
		(data: FormData) => NewsService.create(data)
	)
	const createNew = async (data: FormData, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				sendAlertMessage('Success')
				navigate(`/content/blog/${res.data.id}`)
				queryClient.invalidateQueries({ queryKey: ['news list'] })
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, createNew }
}
