import { DashboardCard } from '@/entities/users/ui/dashboard-card'

import styles from './GeneralStatistic.module.scss'
import { ReactSelect } from '@/shared/ui/form'
import { FC, useEffect, useState } from 'react'
import { SingleValue } from 'react-select'
import { MyOptionType } from '@/shared/ui/form/form.interface'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
	Chart as ChartJS,
	RadialLinearScale,
	ArcElement,
	Tooltip,
	Legend
} from 'chart.js'
import { PolarArea } from 'react-chartjs-2'

import { useTextLocality } from '@/shared/hooks'
import { usePaymentBets, useUsersStats } from '@/features/statistics/api'
import { defaultFilter, filterDataMap } from '../helpers'
import { reFormatNum } from '@/shared/utils'

ChartJS.register(
	RadialLinearScale,
	ArcElement,
	Tooltip,
	Legend,
	ChartDataLabels
)

const filterDataDay = {
	month: 30,
	annually: 365,
	weekly: 7,
	daily: 1
}

type TDays = 'month' | 'annually' | 'weekly' | 'daily'

export const GeneralStatisticCard = () => {
	const { getMessage } = useTextLocality()

	const selectData = [
		{ label: getMessage('monthly'), value: 'month' },
		{ label: getMessage('daily'), value: 'daily' },
		{ label: getMessage('weekly'), value: 'weekly' },
		{ label: getMessage('annually'), value: 'annually' }
	] as { label: string; value: TDays }[]

	const [filterDate, setFilterData] = useState(defaultFilter)
	const { data: betsSum, isLoading } = usePaymentBets(filterDate.current)

	const [selectedActive, setSelectedActive] = useState<
		SingleValue<MyOptionType<TDays>>
	>(selectData[0])

	const { data: usersStatistics, isFetching } = useUsersStats(
		filterDataDay[selectedActive!.value]
	)

	const { data: prevBetsSum } = usePaymentBets(filterDate.prev)

	const totalVolume = betsSum?.sum_bets || 0

	// const differentPercent =
	// (betsSum?.win_percent || 1) - (prevBetsSum?.win_percent || 1)

	const differentPercent =
		(((betsSum?.sum_bets || 0) - (prevBetsSum?.sum_bets || 0)) /
			(betsSum?.sum_bets || 1)) *
		100

	const periodName = {
		month: getMessage('prevMonth'),
		daily: getMessage('prevDay'),
		weekly: getMessage('prevWeek'),
		annually: getMessage('prevYears')
	}

	useEffect(() => {
		const keys = selectedActive!.value
		setFilterData(filterDataMap[keys])
	}, [selectedActive])

	const mockData = {
		title: getMessage('totalValueBet'),
		//@ts-ignore
		periodName: periodName[selectedActive.value],
		number: totalVolume,
		currency: '₿',
		stonks: String(Math.abs(differentPercent).toFixed(2)),
		isFall: differentPercent < 0,
		colors:
			differentPercent < 0
				? {
						main: '#DC0000',
						light: '#FF0A1333',
						boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(255, 10, 19, 0.1)`
				  }
				: {
						main: '#54C86E',
						light: '#54C86E1A',
						boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(84, 200, 110, 0.40)`
				  }
	}

	const onChangeSelected = (data: SingleValue<MyOptionType>) => {
		const select = data as { label: string; value: TDays }
		setSelectedActive(select)
	}

	const data = {
		labels: [
			getMessage('totalUsers'),
			getMessage('active'),
			getMessage('inactive')
		],
		datasets: [
			{
				label: '',
				data: [
					usersStatistics?.totalUsers,
					usersStatistics?.activeUsers,
					usersStatistics?.inactiveUsers
				],
				backgroundColor: ['#4d3cbe', '#695CB8', '#3b3b87'],
				borderWidth: 1
			}
		]
	}

	const res1 = (
		(betsSum?.sum_bets || 0) *
		((betsSum?.win_percent || 100) / 100)
	).toFixed(0)

	return (
		<>
			<div className={styles.list}>
				<DashboardCard
					// isLoading={isLoading}
					data={{
						title: getMessage('statisticsWinAndLoss'),
						number: betsSum?.win_percent || 0,
						isBetting: true,
						tooltipCount: [
							reFormatNum(Number(res1), '₿'),
							reFormatNum(betsSum?.sum_bets || 0, '₿')
						],
						statuses: [
							{
								isActive: (betsSum?.win_percent || 0) > 50,
								name: getMessage('winning')
							},
							{
								isActive: (betsSum?.win_percent || 0) <= 50,
								name: getMessage('loss')
							}
						]
					}}
				/>

				<DashboardCard
					// modifier={['large']}
					// isLoading={isFetching}
					data={{
						title: getMessage('number_users'),
						number: 0,
						isBetting: true,
						statuses: [
							{
								isActive:
									(usersStatistics?.activeUsers || 0) -
										(usersStatistics?.inactiveUsers || 0) >
									0,
								name: getMessage('very_active')
							},
							{
								isActive:
									(usersStatistics?.activeUsers || 0) -
										(usersStatistics?.inactiveUsers || 0) <=
									0,
								name: getMessage('inactive')
							}
						]
					}}
				>
					<div className={styles.area}>
						<PolarArea
							width={'100%'}
							height={'100%'}
							data={data}
							options={{
								plugins: {
									legend: { display: false },

									datalabels: {
										color: '#fff',
										font: { size: 12 },
										formatter: (value, context) => {
											return value
										}
									}
								},
								scales: {
									r: {
										pointLabels: {
											display: false
										},
										ticks: {
											display: false,
											count: 5
										},
										grid: {
											tickLength: 5,
											offset: false
										}
									}
								}
							}}
						/>
					</div>
				</DashboardCard>

				<DashboardCard
					// isLoading={isLoading}
					modifier={['large']}
					stylesWrapper={{ maxWidth: 496 }}
					data={mockData}
					filter={
						<ReactSelect
							name='filter'
							containerClassName={styles.select}
							modifier={['simple']}
							value={selectedActive}
							options={selectData}
							onChange={onChangeSelected}
						/>
					}
				/>
			</div>
		</>
	)
}
