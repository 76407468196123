import { CSSProperties, FC, PropsWithChildren } from 'react'
import styles from './DashboardWrapper.module.scss'
import clsx from 'clsx'

interface IProps extends PropsWithChildren {
	isLoading?: boolean
	modifier?: ('hugo' | 'full' | 'large')[]
	stylesWrapper?: CSSProperties
	color?: 'string'
	classNameWrapper?: 'string'
}

export const DashboardWrapper: FC<IProps> = ({
	isLoading,
	modifier,
	children,
	stylesWrapper,
	color,
	classNameWrapper
}) => {
	const mod = {
		hugo: false,
		large: false,
		full: false
	}

	modifier?.forEach(item => {
		mod[item] = true
	})

	return (
		<div
			className={clsx(
				styles.wrapper,
				classNameWrapper,
				mod.large && styles.large,
				mod.hugo && styles.hugo,
				mod.full && styles.full
			)}
			style={stylesWrapper}
		>
			<div className={clsx(styles.contentWrapper)}>{children}</div>
			<div className={styles.line} style={{ background: color }} />

			{isLoading && <div className={styles.skeleton} />}
		</div>
	)
}
