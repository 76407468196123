export const setLang = (lang: string) => (dispatch: any) => {
	localStorage.setItem('locale', lang)
	dispatch({
		type: 'SET_LOCALE',
		value: lang
	})
	window.location.reload()
	// @ts-ignore
	// document.getElementById("html").setAttribute("lang", lang);
}
export const setLangBackend = (lang: string) => (dispatch: any) => {
	localStorage.setItem('localeInfoWithBackend', lang)
	dispatch({
		type: 'SET_LOCALE_WITH_BACKEND',
		value: lang
	})
}
export const setLoader = (state: boolean) => (dispatch: any) => {
	dispatch({
		type: 'SET_LOADER',
		value: state
	})
}
export const setNavigation = (state: boolean) => (dispatch: any) => {
	dispatch({
		type: 'SET_NAVIGATION',
		value: state
	})
}
