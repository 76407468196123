import { useMainBlockWidth } from '@/shared/hooks'
import { CSSProperties, FC } from 'react'
import clsx from 'clsx'
import styles from './MiniCard.module.scss'
import { ArrowAnalyticJSX } from '@/shared/assets/analytic/svg'

interface IProps {
	title: string
	count: number
	previousCount: number
	colorRight: string
	style?: CSSProperties
}

export const MiniCardAnalytics: FC<IProps> = props => {
	const { title, count, previousCount, colorRight, style } = props
	const percent = ((100 * (count - previousCount)) / previousCount).toFixed(2)
	const { widthFullMainBlock } = useMainBlockWidth()

	return (
		<div
			className={clsx(styles.wrapper, widthFullMainBlock && styles.noneFull)}
			style={{ borderRight: `9px solid ${colorRight}`, ...style }}
		>
			<div className={styles.title}>{title}</div>
			<div className={styles.count}>{count}</div>
			<div className={styles.levelRow}>
				<div
					className={clsx(
						styles.level,
						Number.parseFloat(percent) > 0 && styles.up,
						Number.parseFloat(percent) <= 0 && styles.down
					)}
				>
					<ArrowAnalyticJSX />
					{percent}%
				</div>
				&#160;vs. previous month
			</div>
		</div>
	)
}
