import { ChangeEvent, FC, FocusEventHandler, LegacyRef, useState } from 'react'

import styles from './RadioButton.module.scss'

interface IProps {
	title: string
	name: string
	radioRef?: LegacyRef<HTMLInputElement>
	value?: string
	onChange?: (val: string) => void
	onBlur?: FocusEventHandler<HTMLInputElement>
	checked?: boolean
}

export const RadioButton: FC<IProps> = ({
	title,
	name,
	radioRef,
	value,
	onChange,
	...props
}) => {
	const [inputValue, setInputValue] = useState('')

	const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		onHandlerChange(e)
		setInputValue(e.target.value)
	}
	const onHandlerChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange?.(e.target.value)
	}

	return (
		<div className={styles.wrapper}>
			<label className={styles.label}>
				<input
					className={styles.input}
					ref={radioRef}
					name={name}
					type='radio'
					value={value}
					onChange={onHandlerChange}
					// checked={value === name}
					{...props}
				/>
				<span className={styles.check} />
				{title && <span className={styles.title}>{title}</span>}
			</label>
			{!title && (
				<input
					className={styles.inputValue}
					value={inputValue}
					onChange={onChangeValue}
				/>
			)}
		</div>
	)
}
