import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { ConnectForm } from './ConnectForm'
import { IProsEditor } from '../form.interface'
import { EditorFieldDefault } from '../editor-field/editor-field-default'

export const ConnectEditor: FC<IProsEditor> = ({
	registerConfig,
	...props
}) => {
	return (
		<ConnectForm>
			{({ register, control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name}
					render={({ field: { onChange, value, ref } }) => {
						return (
							<EditorFieldDefault
								{...props}
								onChange={onChange}
								value={value}
								editorRef={ref}
							/>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}
