import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'
import { ITransactionList } from '@/shared/services/types/statistic.types'
import { useAlertMessage } from '@/shared/hooks'

export const useTransactionGame = (data: ITransactionList) => {
	const { sendAlertError } = useAlertMessage()
	return useQuery(
		['get game transaction list' + data],
		() => StatisticService.getTransactionList({ tr_filter: 'shop', ...data }),
		{
			select: ({ data }) => data,
			onError: (error: any) => {
				sendAlertError(error)
			}
		}
	)
}
