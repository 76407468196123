import { FormWrapper, Input } from '@/shared/ui/form'
import styles from './Limitations.module.scss'
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { SearchInput } from '@/shared/assets/common/svg'
import { FC, useEffect } from 'react'
import { CalendarInput } from '@/shared/ui/form/calendar-input'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	form: UseFormReturn<any>
	onSubmit: SubmitHandler<FieldValues>
}

export const LimitationsFilter: FC<IProps> = ({ form, onSubmit }) => {
	const { watch, getValues } = form

	const fromDate = watch('fromDate')
	const toDate = watch('toDate')
	const { getMessage } = useTextLocality()

	const resetFilter = () => {
		form.reset({
			...getValues(),
			filter: undefined,
			fromDate: undefined,
			toDate: undefined
		})
	}

	return (
		<div>
			<FormWrapper
				methods={form}
				className={styles.wrapper}
				onSubmit={onSubmit}
			>
				<div className={styles.filter}>
					<Input
						containerClassName={styles.input}
						name='search'
						modifiers={['small']}
						connect
						placeholder={getMessage('searchByEvent')}
						icon={<SearchInput />}
					/>

					<div className={styles.calendar}>
						<CalendarInput
							connect
							name='fromDate'
							containerClassName={styles.calendarInput}
							selectsStart
							maxDate={toDate}
							startDate={fromDate}
							selected={fromDate}
							endDate={toDate}
							placeholder={getMessage('from_date')}
						/>
						<span>-</span>
						<CalendarInput
							connect
							name='toDate'
							containerClassName={styles.calendarInput}
							selectsEnd
							startDate={fromDate}
							endDate={toDate}
							selected={toDate}
							minDate={fromDate}
							placeholder={getMessage('to_date')}
						/>
					</div>
				</div>
			</FormWrapper>
		</div>
	)
}
