import { IPaginationData, IPaginationDataWithDate } from './types/global.types'
import { axiosInstance } from '@/shared/api'
import {
	IBet,
	ISport,
	IGetAllTournamentsData,
	IGetTournamentEventListData,
	IMarket,
	IGame,
	ITournament
} from './types/sport.types'
import { getSportUrl } from '../api/config'

const SportService = {
	async getSports({ page = 1, count = 21 }: IPaginationData) {
		return axiosInstance.get<ISport[]>(
			getSportUrl(`/list/sports?page=${page}&count=${count}`)
		)
	},

	async getBets({
		page = 1,
		count = 21,
		fromDate = new Date('2023-01-01T00:00:01'),
		toDate = new Date()
	}: IPaginationDataWithDate) {
		return axiosInstance.get<IBet[]>(
			getSportUrl(
				`/bet/getAll?page=${page}&count=${count}&fromDate=${fromDate}&toDate=${toDate}`
			)
		)
	},

	async getTournaments({ page, count, sportId }: IGetAllTournamentsData) {
		return axiosInstance.get<ITournament[]>(
			getSportUrl(
				sportId
					? `/list/tournaments?page=${5}&count=${21}&sport=${sportId}`
					: `/list/tournaments?page=${page}&count=${count}`
			)
		)
	},

	async getTournamentById(id: number) {
		return axiosInstance.get<ITournament>(getSportUrl(`/get/tournament/${id}`))
	},

	async getTournamentEventList({
		tournamentId,
		page = 1,
		count = 21,
		fromDate = new Date('2023-01-01T00:00:01').toISOString(),
		toDate = new Date().toISOString()
	}: IGetTournamentEventListData) {
		return axiosInstance.get<IGame[]>(
			getSportUrl(
				`/list/games?tournament=${tournamentId}&page=${page}&count=${count}`
			)
		)
	},

	async getGameInfo(id: number) {
		return axiosInstance.get<IGame>(getSportUrl(`/get/game/${id}`))
	},

	async getSearchedMatch(searchTerm: string) {
		return axiosInstance.get<Omit<IGame, 'markets'>[]>(
			getSportUrl(`/search/match`),
			{
				params: {
					query: searchTerm
				}
			}
		)
	},

	async getMarketsByGameId(id: number) {
		return axiosInstance.get<IMarket[]>(getSportUrl(`/get/market/${id}`))
	},

	async betOdinar(id: string, amount: FormData) {
		return axiosInstance.post<IBet>(
			getSportUrl(`/bet/odinar?marketId=${id}`),
			amount
		)
	}
}

export default SportService
