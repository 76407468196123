import clsx from 'clsx'
import { FC } from 'react'

import { ConnectedRadioGroup } from '../form-connect'
import { IPropsRadioGroup } from '../form.interface'
import { RadioButton } from '../radio-button'

import styles from './RadioGroup.module.scss'

export const RadioGroupDefault: FC<IPropsRadioGroup> = ({
	options,
	onChange,
	...props
}) => {
	return (
		<>
			{options.map(item => (
				<RadioButton
					{...props}
					key={item.value}
					name={props.name}
					onChange={onChange}
					title={item.label}
					value={item.value}
					checked={!!props.checked ? item.value === props.checked : undefined}
				/>
			))}
		</>
	)
}

export const RadioGroup: FC<IPropsRadioGroup> = ({
	connect,
	name,
	containerClassName,
	options,
	registerConfig,
	value,
	onChange,
	onBlur,
	...props
}) => {
	const RadioGroupComponent = connect ? ConnectedRadioGroup : RadioGroupDefault

	return (
		<div className={clsx(styles.wrapper, containerClassName)}>
			<RadioGroupComponent
				name={name}
				options={options}
				connect={connect}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				{...(connect
					? {
							registerConfig
					  }
					: {})}
				{...props}
			/>
		</div>
	)
}
