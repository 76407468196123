import {
	Button,
	EditorFields,
	FormWrapper,
	Input,
	InputImage
} from '@/shared/ui/form'
import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useArticleDetail } from '../../api/article'
import { Loader } from '@/shared/ui/loader'
import { IArticle } from '@/shared/services/types/school.types'
import { useTextLocality } from '@/shared/hooks'
import { PublishIconJSX } from '@/shared/assets/common/svg'
import { messages } from '@/i18n/messages'
import styles from './SchoolForm.module.scss'
import { useAppSelector } from '@/shared/model'

export interface FormProps {
	lang: string
	headingPlaceholder: string
	previewPlaceholder: string
	textPlaceholder: string
	onSubmit: (values: FieldValues) => void
	short: string
	form: UseFormReturn<FieldValues, any, undefined>
}

interface IFormEditOrNew extends FormProps {
	setArticleItem: React.Dispatch<React.SetStateAction<IArticle | undefined>>
}

export const SchoolForm: FC<IFormEditOrNew> = ({
	lang,
	headingPlaceholder,
	previewPlaceholder,
	textPlaceholder,
	onSubmit,
	short,
	form,
	setArticleItem
}) => {
	const { getMessage } = useTextLocality()

	const { id_article, id } = useParams()
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { data, isFetching } = useArticleDetail(id_article!)
	const {
		formState: { errors }
	} = form

	const defaultValues = {
		lang: short,
		typeSubmit: id_article ? 'article' : 'new_article',
		id_article,
		category_id: Number(id)
	}

	const onSubmitForm = (values: FieldValues) => {
		onSubmit(values)
	}

	useEffect(() => {
		if (data) {
			form.reset({
				...defaultValues,
				...data
			})
			setArticleItem(data)
		} else {
			form.reset({
				...defaultValues
			})
		}
	}, [data])

	return (
		<>
			<FormWrapper
				className={clsx('PageEditOrNewForm')}
				methods={form}
				onSubmit={onSubmitForm}
			>
				{isFetching && <Loader absolute />}
				<div className='langTitle'>{lang}</div>
				<div className='required'>* {getMessage('required_field_tool')}</div>
				<Input
					name={short + 'Title'}
					connect
					placeholder={headingPlaceholder}
					label={getMessage('title')}
					required
					errorText={errors?.[short + 'Title']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<Input
					name={short + 'Short'}
					connect
					placeholder={previewPlaceholder}
					label={getMessage('description_short')}
					required
					errorText={errors?.[short + 'Short']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<EditorFields
					connect
					name={short + 'Body'}
					label={getMessage('description')}
					required
					placeholder={textPlaceholder}
					errorText={errors?.[short + 'Body']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<div className={'row'}>
					<InputImage
						connect
						lang={lang}
						type='image/webp'
						name={short + `Image`}
						required={id ? false : true}
						size='450х300'
					/>
					<InputImage
						connect
						lang={lang}
						type='video/'
						name={short + `Video`}
						required={id ? false : true}
						size='3500x2500'
					/>
				</div>
				{/* <Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish')}
				</Button> */}
			</FormWrapper>
		</>
	)
}
