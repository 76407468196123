import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'
import { ISummBets } from '@/shared/services/types/statistic.types'

export const useSumBetsUsers = (data: ISummBets) => {
	return useQuery(
		['get sum bets users' + data],
		() => StatisticService.getSumBetsUsers(data),
		{
			select: ({ data }) => data
		}
	)
}
