import styles from './Bonuses.module.scss'
import { TColumnsTable, Table } from '@/shared/ui/table'
import { DashboardCard, StatusTable } from '@/entities/users/ui'
import { FC, useEffect, useState } from 'react'
import { ITransactionList } from '@/shared/services/types/statistic.types'
import { Pagination } from '@/shared/ui/pagination'
import { useTransactionBonus, useTransactionReferral } from '../../api'
import { transformDateLocale } from '@/shared/utils'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	id: number
}

export const Bonuses: FC<IProps> = ({ id }) => {
	const defaultFilter = {
		user_id: id,
		page: 1,
		count: 10
	} as ITransactionList
	const { getMessage } = useTextLocality()

	const [filter, setFilter] = useState<ITransactionList>(defaultFilter)
	const [bonusList, setBonusList] = useState<any[]>([])
	const { data: bonusData, refetch, isFetching } = useTransactionBonus(filter)
	const { data: dataReferral, isLoading } = useTransactionReferral({
		user_id: id
	})

	useEffect(() => {
		const res =
			bonusData?.transactions.map(item => ({
				id: item.id,
				amount: item.amount,
				type: item.model_by,
				timestamp: transformDateLocale(item.timestamp)
			})) || []
		setBonusList(res)
	}, [bonusData])

	useEffect(() => {
		refetch()
	}, [filter])

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}
	const number =
		((dataReferral?.total_transactions || 0) /
			(dataReferral?.transactions?.filter(item => item.amount).length || 0)) *
			100 || 0

	const data = {
		title: getMessage('numberReferrals'),
		number: number,
		isBetting: true,
		statuses: [
			{ name: getMessage('received'), isActive: number > 50 },
			{ name: getMessage('not_received'), isActive: number <= 50 }
		]
	}
	const columns: TColumnsTable[] = [
		{
			title: getMessage('id'),
			dataIndex: 'id'
		},
		{
			title: getMessage('name'),
			dataIndex: 'type'
		},

		// {
		// 	title: getMessage('timestamp'),
		// 	dataIndex: 'timestamp'
		// }
		{
			title: 'Status',
			dataIndex: 'status',
			width: '150px',
			align: 'center',
			render: (data: 'received') => {
				if (!data) return null
				// return (
				// 	<div className={styles.status}>
				// 		<StatusTable
				// 			isActive={data === 'received'}
				// 			text={messages[locale].received}
				// 		/>
				// 	</div>
				// )
			}
		}
	]

	return (
		<div className={styles.wrapper}>
			<div className={styles.background}>
				<div className={styles.table}>
					<Table
						styleWrapper={{ height: 690 }}
						isLoading={isFetching}
						columns={columns}
						dataSource={bonusList}
						wrapperClass={styles.table}
						modifiers={['border', 'header']}
					/>
				</div>
				<DashboardCard data={data} isLoading={isLoading} />
			</div>
			<div className={styles.pagination}>
				<Pagination
					pageCount={(bonusData?.total_transactions || 10) / filter.count!}
					onPageChange={onChangePage}
				/>
			</div>
		</div>
	)
}
