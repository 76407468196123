import { TColumnsTable, Table } from '@/shared/ui/table'
import styles from './Limitations.module.scss'
import { useGetBets } from '@/features/users/api'
import { FC, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { transformDateLocale, transformDateToString } from '@/shared/utils'
import { Pagination } from '@/shared/ui/pagination'
import { RadioGroup, ReactSelect } from '@/shared/ui/form'
import { SingleValue } from 'react-select'
import { MyOptionType } from '@/shared/ui/form/form.interface'
import { LimitationsFilter } from './components/limitations-filter'
import { DashboardWrapper } from '@/shared/ui/dashboard-wrapper'
import { DashBoardsBlock } from './components'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	id: number
}

interface ITableBets {
	match: string
	date: string
	amount: number
	type: string
	betting_amount: string
	coefficient: string
}

export const Limitations: FC<IProps> = ({ id }) => {
	const { getMessage } = useTextLocality()

	const columns: TColumnsTable[] = [
		{
			title: getMessage('match'),
			dataIndex: 'match',
			width: '400px'
		},
		{
			title: getMessage('timestamp'),
			dataIndex: 'date'
		},
		{
			title: getMessage('amount'),
			dataIndex: 'amount',
			render: (amount: string) => {
				return `${amount}  ₿`
			}
		},
		{
			title: getMessage('coefficient'),
			dataIndex: 'coefficient',
			render: (isWin: boolean, { coefficient }) => {
				const options = [
					{
						label: '0%',
						value: '0'
					},
					{
						label: '3%',
						value: '3'
					},
					{
						label: '5%',
						value: '5'
					},
					{
						label: '7%',
						value: '7'
					}
				]

				const onChangeCoefficient = (data: SingleValue<MyOptionType>) => {}
				return (
					<ReactSelect
						containerClassName={styles.select}
						defaultValue={options[0]}
						modifier={['simple', 'circle']}
						name='select'
						options={options}
						onChange={onChangeCoefficient}
					/>
				)
			}
		}
	]

	const defaultFilter = {
		user_id: id,
		page: 1,
		count: 5
	}
	const [filter, setFilter] = useState<any>(defaultFilter)
	const form = useForm({ mode: 'onBlur' })
	const [dataBetList, setDataBetList] = useState<ITableBets[]>([])
	const {
		data: betsData,
		refetch,
		isFetching,
		isError
	} = useGetBets(filter, true)
	const fromDate = form.watch('fromDate')
	const toDate = form.watch('toDate')

	const onSearchHandler = (form: FieldValues) => {
		const res = {
			...form,
			fromDate: form.fromDate && transformDateToString(form.fromDate),
			toDate: form.toDate && transformDateToString(form.toDate, true)
		}
		setFilter({ ...defaultFilter, ...res })
	}

	const onChangePage = (page: { selected: number }) => {
		setFilter({ ...filter, page: page.selected + 1 })
	}

	useEffect(() => {
		const res = {
			fromDate: fromDate && transformDateToString(fromDate),
			toDate: toDate && transformDateToString(toDate, true)
		}

		setFilter({ ...defaultFilter, ...res })
	}, [fromDate, toDate])

	useEffect(() => {
		refetch()
	}, [filter])

	useEffect(() => {
		const res =
			betsData?.bets.map(item => ({
				id: item.id,
				date: transformDateLocale(item.createdAt, true),
				amount: item.amount,
				match: item.opponent_one_name_en + ' - ' + item.opponent_two_name_en,
				type: item.tournament_name_en,
				betting_amount: item.markets.name_en,
				coefficient: item.markets.name_en
			})) || []
		if (isError) {
			setDataBetList([])
		}
		if (!isError) setDataBetList(res)
	}, [betsData, isError])

	return (
		<div className={styles.wrapper}>
			<div className={styles.background}>
				<DashBoardsBlock />
				<LimitationsFilter form={form} onSubmit={onSearchHandler} />
				<Table
					isLoading={isFetching}
					columns={columns}
					dataSource={dataBetList}
					wrapperClass={styles.table}
					modifiers={['border', 'header']}
				/>
			</div>
			<div className={styles.pagination}>
				<Pagination
					current={isError ? 0 : filter.page - 1}
					countItems={filter.count}
					pageCount={isError ? 1 : (betsData?.total_bets || 10) / filter.count}
					onPageChange={onChangePage}
				/>
			</div>
		</div>
	)
}
