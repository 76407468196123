import { FC, PropsWithChildren } from 'react'
import { Provider as AlertProvider, positions, transitions } from 'react-alert'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'

import { ErrorInterceptor } from './ErrorInterceptor'
import { QueryClientProvider } from './QueryClientProvider'
import store from '@/shared/store/store'
import { LOCALES } from '@/i18n/locales'
import { messages } from '@/i18n/messages'
import { AlertTemplate } from '@/shared/ui/alert-template'

const optionsAlert = {
	position: positions.BOTTOM_RIGHT,
	timeout: 3000, // 5000,
	offset: '30px',
	transition: transitions.SCALE,
	containerStyle: { zIndex: 1000 }
}

const MainProvider: FC<PropsWithChildren> = ({ children }) => {
	const { locale } = store.getState().defaultReducer

	return (
		<Provider store={store}>
			<QueryClientProvider>
				<IntlProvider
					messages={messages[locale]}
					locale={locale}
					defaultLocale={LOCALES.CHINESE}
				>
					<AlertProvider template={AlertTemplate} {...optionsAlert}>
						<ErrorInterceptor>{children}</ErrorInterceptor>
					</AlertProvider>
				</IntlProvider>
			</QueryClientProvider>
		</Provider>
	)
}

export default MainProvider
