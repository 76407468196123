import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { ICustomHeader } from './calendar.interface'
import { ArrowLeftJSX, ArrowRightJSX } from '@/shared/assets/common/svg'

const months = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december'
]

const CustomHeader: FC<ICustomHeader> = ({
	date,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	onChange,
	nextMonthButtonDisabled
}) => {
	return (
		<div className='custom-header-datepicker'>
			<button
				type='button'
				onClick={decreaseMonth}
				disabled={prevMonthButtonDisabled}
			>
				<ArrowLeftJSX className='arrow-icon-datepicker' />
			</button>

			<span>
				<FormattedMessage id={months[date.getMonth()]} /> {date.getFullYear()}
			</span>

			<button
				type='button'
				onClick={increaseMonth}
				disabled={nextMonthButtonDisabled}
			>
				<ArrowRightJSX className='arrow-icon-datepicker' />
			</button>
		</div>
	)
}

export default CustomHeader
