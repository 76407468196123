import { useQuery } from '@tanstack/react-query'

import AuthService from '@/shared/services/auth.service'

export const useGetUserDetail = (id: number) => {
	return useQuery(
		['get user details', id],
		() => AuthService.getUsersList({ user_id: id }),
		{
			select: ({ data }) => {
				return data.users[0]
			}
		}
	)
}
