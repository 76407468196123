import { useMemo, useState } from 'react'

export const useLineOrTiles = () => {
	const confirmationRowLocale = localStorage.getItem('LineOrTiles')
	const [confirmationRow, setConfirmationRow] = useState<boolean>(
		confirmationRowLocale === 'true'
	)

	useMemo(() => {
		if (confirmationRow) {
			localStorage.setItem('LineOrTiles', 'true')
		} else localStorage.setItem('LineOrTiles', 'false')
	}, [confirmationRow])

	return { confirmationRow, setConfirmationRow }
}
