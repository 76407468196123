import { FC, PropsWithChildren } from 'react'

import '@/Assets/sass/Pages/School/PageEditOrNew.scss'

import styles from './PageForm.module.scss'
import { useMainBlockWidth, useTextLocality } from '@/shared/hooks'
import { Confirmation } from '@/shared/ui/confirmation'
import clsx from 'clsx'
import { FieldValues } from 'react-hook-form'
import { useEditProduct, useNewProduct } from '@/entities/shop/api'
import { useAddCategory, useEditCategory } from '@/entities/school/api/category'
import { useAddArticle, useEditArticle } from '@/entities/school/api/article'
import { useCreateAnalytic, useEditAnalytic } from '@/entities/analytic/api'
import { useAddNew, useEditNew } from '@/entities/blog/api'

export interface IProps extends PropsWithChildren {
	title?: string
	componentFormHeading?: JSX.Element
	confirmationSubmit: boolean
	setConfirmationSubmit: React.Dispatch<React.SetStateAction<boolean>>
	eventForm: FieldValues
}

export const PageForm: FC<IProps> = ({
	componentFormHeading,
	confirmationSubmit,
	eventForm,
	setConfirmationSubmit,
	children
}) => {
	const { getMessage } = useTextLocality()

	const interactiveContent = ['enImage', 'cnImage', 'enVideo', 'cnVideo']
	const { editProduct } = useEditProduct(eventForm!.id)
	const { editCategory } = useEditCategory(eventForm.category_id)
	const { onEditNew } = useEditNew(eventForm.id)
	const { onEditArticle } = useEditArticle(eventForm.id_article)
	const { onEditAnalytic } = useEditAnalytic(eventForm.id)

	const { onAddNewProduct } = useNewProduct()
	const { createCategory } = useAddCategory()
	const { createNew } = useAddNew()
	const { createArticle } = useAddArticle(eventForm.category_id)
	const { onCreateAnalytic } = useCreateAnalytic()

	const { widthFullMainBlock } = useMainBlockWidth()

	const onSubmit = () => {
		const typeSubmit = eventForm?.typeSubmit
		const form = new FormData()
		for (const key in eventForm) {
			form.append(key, eventForm[key])
			if (eventForm[key] === undefined || eventForm[key] === null) {
				form.delete(key)
			}
			if (
				interactiveContent.includes(key) &&
				typeof eventForm[key] === 'string'
			) {
				form.delete(key)
			}
		}

		form.delete('typeSubmit')
		form.delete('id')
		form.delete('lang')

		const form_image = form.get('image')
		// @ts-ignore
		if (form_image?.name === '') {
			form.delete('image')
		}

		console.log(typeSubmit)

		switch (typeSubmit) {
			case 'category':
				editCategory(form)
				break
			case 'new_category':
				createCategory(form)
				break
			case 'article':
				onEditArticle(form)
				break
			case 'new_article':
				createArticle(form)
				break
			case 'new_product':
				onAddNewProduct(form)
				break
			case 'edit_product':
				editProduct(form)
				break
			case 'new_blog':
				createNew(form)
				break
			case 'edit_blog':
				onEditNew(form)
				break
			case 'analytic':
				onEditAnalytic(form)
				break
			case 'new_analytic':
				onCreateAnalytic(form)
				break
			default:
				break
		}
	}

	return (
		<>
			{!!componentFormHeading && componentFormHeading}
			<div
				className={clsx(styles.wrapper, widthFullMainBlock && styles.noneFull)}
			>
				{children}
				<Confirmation
					isOpen={confirmationSubmit}
					title={getMessage('confirm_ask_publish')}
					event={eventForm}
					setConfirmationState={onSubmit}
					setConfirmationOpen={setConfirmationSubmit}
				/>
			</div>
		</>
	)
}
