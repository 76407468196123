import { messages } from '@/i18n/messages'
import { useAppSelector } from '../model'

type TValues = keyof typeof messages.ru

/**
 * Возвращает функцию, которая в возвращает локализированное статическое сообщение
 * @param message принимает текстовое поле сообщения
 * */
export const useTextLocality = () => {
	
	const { locale } = useAppSelector(state => state.defaultReducer)
	const getMessage = (message: TValues) => {
		return messages[locale][message]
	}
	return { getMessage }
}
