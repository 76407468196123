import { AlertTemplateProps } from 'react-alert'
import { FormattedMessage } from 'react-intl'

import { IconError, IconInfo, IconSuccess } from './AlertSvg'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'

import styles from './AlertTemplate.module.scss'
import { clsx } from 'clsx'

// Компонент алерта
export const AlertTemplate: (props: AlertTemplateProps) => JSX.Element = ({
	style,
	options,
	message,
	close
}) => {
	const { locale } = useAppSelector(state => state.defaultReducer)

	const getParsedMessage = (message: any) => {
		if (Array.isArray(message)) {
			return (
				<span>
					{message.map(message => (
						<div key={message.toString()}>
							<span> {message.loc.toString()}</span> <br />
							<span> {message.msg}</span> <br />
						</div>
					))}
				</span>
			)
		}
		return message
	}

	return (
		<div style={style} className={clsx(styles.wrapper, styles[options.type!])}>
			<div className={styles.container}>
				{options.type === 'info' ? (
					<IconInfo />
				) : options.type === 'success' ? (
					<IconSuccess />
				) : options.type === 'error' ? (
					<IconError />
				) : null}
				{typeof message === 'string' && (
					<>
						<FormattedMessage id={message} />{' '}
						{message === 'message_login_success'
							? `${{ name: 'admin' }.name}`
							: ''}
					</>
				)}
				{typeof message === 'object' && <>{getParsedMessage(message)}</>}
				<button className='primaryButton' onClick={close}>
					{messages[locale].okay}
				</button>
			</div>
		</div>
	)
}
