import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate } from 'react-intl'

import { InputCheckbox } from '@/shared/ui/input-checkbox'

import { useTextLocality } from '@/shared/hooks'
import { Button, Checkbox } from '@/shared/ui/form'
import clsx from 'clsx'
import { DeleteIconJSX, EditIconJSX } from '@/shared/assets/common/svg'
import '@/Assets/sass/Pages/School/Card.sass'

import styles from './SchoolCard.module.scss'

interface IProps {
	setConfirmationDeletion?: any
	setCategoryID?: any
	updated_at?: string
	image?: string
	title?: string
	short?: string
	detailList: boolean
	editLink: string
	activeLink?: any
	status_edit: boolean
	isActive?: boolean
	price?: number
	isLine?: boolean
	id: number
	checkedCard: boolean
	onChecked: (id: number) => void
}

export const SchoolCard: FC<IProps> = ({
	id,
	title,
	short,
	isActive,
	status_edit,
	image,
	updated_at,
	detailList,
	editLink,
	activeLink,
	setConfirmationDeletion,
	isLine,
	setCategoryID,
	checkedCard,
	onChecked
}) => {
	const { getMessage } = useTextLocality()
	const [checked, setChecked] = useState(isActive)

	const toggleStatusProduct = () => {
		setChecked(!checked)
		activeLink(id, !checked)
	}

	return (
		<div className={clsx(styles.wrapper, isLine && styles.isLineWrapper)}>
			<div className={styles.select}>
				<Checkbox
					name='selectAll'
					modifier={['red']}
					checked={checkedCard}
					onChange={() => onChecked(id)}
				/>
			</div>
			<div className={styles.content}>
				<div className={styles.image}>
					<img src={image} alt='img product' />
				</div>
				<div className={styles.groupButton}>
					<Button modifiers={['inverse']} disabled={!isActive} svg={<EditIconJSX />} link={editLink}>
						{getMessage('edit')}
					</Button>

					{!!setConfirmationDeletion && (
						<Button
							modifiers={['inverse']}
							svg={<DeleteIconJSX />}
							onClick={() => {
								setCategoryID(id)
								setConfirmationDeletion(true)
							}}
						>
							{getMessage('delete')}
						</Button>
					)}
				</div>
			</div>
			<div className={styles.info}>
				<div
					className={clsx(
						styles.headerArticle,
						!!updated_at && styles.withDate
					)}
				>
					{!!updated_at && (
						<div className={styles.date}>
							<FormattedDate value={new Date(updated_at)} />
						</div>
					)}
					{!false && <div className={styles.category}>Football</div>}
					{!!status_edit && (
						<div className={styles.status}>
							<Checkbox
								name='status'
								checked={checked}
								title={checked ? getMessage('show') : getMessage('hide')}
								modifier={['card']}
								onChange={toggleStatusProduct}
							/>
						</div>
					)}
				</div>
				<div className={styles.title}>{title}</div>
				<div className={styles.description}>{short}</div>

				{!!detailList && (
					<div className={styles.moreDetailed}>
						<Link to={`/content/school/${id}/article`} className='moreDetailed'>
							{getMessage('show_articles')}
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}
