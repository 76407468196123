import '@/Assets/sass/Components/Header.scss'
import '@/Assets/sass/Pages/Dashboard.scss'
import { Layout } from '@/shared/ui/layout'
import {AnalyticDashBoard} from "@/widgets/analytic-dashboard";


const Dashboard = () => {
	return (
		<Layout position='center' title='dashboard' description='welcomeBack'>
			<AnalyticDashBoard />
		</Layout>
	)
}

export default Dashboard
