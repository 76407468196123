import {typeAction, typePaginator} from "../typesStore";

export declare module School {

    export interface ItemCategory {
        id: number;
        price: number;
        is_active: boolean;
        title: string;
        short: string;
    }



    export interface ListCategory extends typePaginator {
        items: ItemCategory[];
        total_count: number;
    }

    export interface ItemArticle {
        id: number;
        is_active: boolean;
        title: string;
        short: string;
        body: string;
        image?: any;
        second_image?: any;
        video: string;
        second_video?: any;
        rating_read_text: number
        rating_view_video: number
        price: number
    }

    export interface ListArticleByCategory extends typePaginator {
        items: ItemArticle[];
        total_count: number;
    }

    export interface RootObject {
        listCategory: ListCategory;
        listArticlesByCategory: ListArticleByCategory
    }

}

const defaultBlogState: School.RootObject = {
    listCategory: {
        items: [],
        total_count: 0,
        page: 1,
        count: 12,
    },
    listArticlesByCategory: {
        items: [],
        total_count: 0,
        page: 1,
        count: 12,
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultBlogState, action: typeAction) {
    const {type, value} = action;
    switch (type) {
        case "SET_CATEGORY_LIST":
            return {...state, listCategory: value}
        case "SET_ARTICLES_LIST":
            return {...state, listArticlesByCategory: value}
        default:
            return state
    }
}
