import { useMainBlockWidth } from '@/shared/hooks'
import { FC, useState } from 'react'
import clsx from 'clsx'
import styles from './MiddleCard.module.scss'
import { DataButton } from '@/shared/ui/picker-custom/DataButton'
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'

interface IProps {
	title: string
	description: string
	startDateState: any
	endDateState: any
	points: Array<any>
	calendar?: boolean
}

export const MiddleCardAnalytics: FC<IProps> = ({
	title,
	description,
	points,
	calendar = false
}) => {
	const dataButtons = ['All', '1M', '6M', '1Y']
	const [startDate, setStartDate] = useState(new Date('2014/02/08'))
	const [endDate, setEndDate] = useState(new Date('2014/02/10'))
	const { widthFullMainBlock } = useMainBlockWidth()

	return (
		<div
			className={clsx(styles.wrapper, widthFullMainBlock && styles.noneFull)}
		>
			<div className={styles.headerRow}>
				<div className={styles.leftBlock}>
					<div className={styles.title}>{title}</div>
					<div className={styles.description}>{description}</div>
				</div>
				<div className={styles.rightBlock}>
					{calendar ? (
						<>
							<DataButton
								selectedDate={startDate}
								setDate={setStartDate}
								startDate={startDate}
								endDate={endDate}
							/>
							&#160;-&#160;
							<DataButton
								selectedDate={endDate}
								setDate={setEndDate}
								startDate={startDate}
								endDate={endDate}
							/>
						</>
					) : (
						<>
							{dataButtons.map(item => (
								<button key={item}>{item}</button>
							))}
						</>
					)}
				</div>
			</div>
			<div className={styles.chart}>
				<ResponsiveContainer width={'100%'} height={200}>
					<LineChart
						height={200}
						data={points}
						margin={{
							top: 0,
							right: 0,
							left: -10,
							bottom: 0
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='name' />
						<YAxis />
						<Tooltip />
						<Legend />
						<Line
							type='monotone'
							dataKey='pv'
							stroke='#8884d8'
							activeDot={{ r: 8 }}
						/>
						<Line
							type='monotone'
							dataKey='gv'
							stroke='red'
							activeDot={{ r: 8 }}
						/>
						<Line type='monotone' dataKey='uv' stroke='#82ca9d' />
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}
