import { useAlertMessage } from '@/shared/hooks'
import ShopService from '@/shared/services/shop.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteProduct = () => {
	const { sendAlertError } = useAlertMessage()
	const client = useQueryClient()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['delete product shop'],
		(id: number) => ShopService.delete(id)
	)
	const onDeleteProduct = async (id: number, callback?: () => void) => {
		mutateAsync(id)
			.then(res => {
				callback?.()
				client.invalidateQueries({ queryKey: ['shop list'] })
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, onDeleteProduct }
}
