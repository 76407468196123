import { DashboardCard } from '@/entities/users/ui/dashboard-card'
import { useUsersStats } from '@/features/users/api'

import styles from './RegistrationStatic.module.scss'
import { TStaticCard } from '@/entities/users/ui/dashboard-card/dashboard-card.interface'
import { ReactSelect } from '@/shared/ui/form'
import { useState } from 'react'
import { SingleValue } from 'react-select'
import { MyOptionType } from '@/shared/ui/form/form.interface'
import { useTextLocality } from '@/shared/hooks'

export const RegistrationStatic = () => {
	const { getMessage } = useTextLocality()

	const daysWeek = 7
	const daysTwoWeek = 14
	const daysMonth = 30
	const daysTwoMonth = 30
	const { data: dataWeek } = useUsersStats(daysWeek)
	const { data: dataTwoWeek } = useUsersStats(daysTwoWeek)
	const { data: dataMonth } = useUsersStats(daysMonth)
	const { data: dataTwoMonth, isLoading } = useUsersStats(daysTwoMonth)

	const regWeek = dataWeek?.[`New Registered users in ${daysWeek} day(s)`] || 0
	const regMonth =
		dataMonth?.[`New Registered users in ${daysMonth} day(s)`] || 0

	const regTwoWeek =
		dataTwoWeek?.[`New Registered users in ${daysTwoWeek} day(s)`] || 0
	const regTwoMonth =
		dataTwoMonth?.[`New Registered users in ${daysTwoMonth} day(s)`] || 0

	const regPrevWeek = regTwoWeek - regWeek
	const regPrevMonth = regTwoMonth - regMonth

	const percentRegWeek =
		(regWeek / daysWeek) * 100 - (regPrevWeek / daysWeek) * 100
	const percentRegMonth =
		(regPrevMonth / daysMonth) * 100 - (regMonth / daysMonth) * 100

	const dashboardList: TStaticCard[] = [
		{
			title: getMessage('totalRegistrations'),
			isBetting: true,
			number: dataWeek?.['Total Registered Users'] || 0
		},
		{
			title: getMessage('activeUserWeek'),
			number: regMonth,
			stonks: percentRegMonth.toFixed(2) || '50',
			periodName: getMessage('prevMonth'),
			isFall: percentRegMonth < 0,
			colors:
				percentRegMonth < 0
					? {
							main: '#DC0000',
							light: '#ff0a1333',
							boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(255, 10, 19, 0.1)`
					  }
					: {
							main: '#54C86E',
							light: '#54c86e1a',
							boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(84, 200, 110, 0.40)`
					  }
		},
		{
			title: getMessage('newUserWeek'),
			number: regWeek,
			stonks: percentRegWeek.toFixed(2),
			periodName: getMessage('prevWeek'),
			isFall: percentRegWeek < 0,
			colors:
				percentRegWeek < 0
					? {
							main: '#DC0000',
							light: '#FF0A1333',
							boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(255, 10, 19, 0.1)`
					  }
					: {
							main: '#54C86E',
							light: '#54C86E1A',
							boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(84, 200, 110, 0.40)`
					  }
		}
	]
	const mockData = {
		title: 'Total volume of bets',
		periodName: `previous week`,
		number: 1230000,
		stonks: '3.51',
		colors: false
			? {
					main: '#DC0000',
					light: '#FF0A1333',
					boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(255, 10, 19, 0.1)`
			  }
			: {
					main: '#54C86E',
					light: '#54C86E1A',
					boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(84, 200, 110, 0.40)`
			  }
	}
	const selectData = [
		{ label: 'Monthly', value: 'Monthly' },
		{ label: 'Daily', value: 'Daily' },
		{ label: 'Weekly', value: 'Weekly' },
		{ label: 'Annually', value: 'Annually' }
	]
	const [selectedActive, setSelectedActive] = useState<
		SingleValue<MyOptionType>
	>(selectData[0])

	const onChangeSelected = (data: SingleValue<MyOptionType>) => {
		setSelectedActive(data)
	}

	return (
		<>
			<div className={styles.list}>
				{dashboardList.map((item, index) => (
					<DashboardCard isLoading={isLoading} data={item} key={index} />
				))}
			</div>
		</>
	)
}
