import {combineReducers} from "redux";
import message from "./message";
import defaultReducer from './default'
import auth from "./auth";
import shop from "./shop";
import analytic from "./analytic"
import blog from "./blog";
import school from "./school";

export default combineReducers({
    defaultReducer,
    message,
    auth,
    shop,
    analytic,
    blog,
    school
});
