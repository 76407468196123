import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import styles from './Button.module.scss'

type TProps = PropsWithChildren & {
	onClick?: () => void
	modifiers?: ('gold' | 'success' | 'full' | 'small' | 'inverse' | 'red')[]
	title?: string
	disabled?: boolean
	svg?: JSX.Element
	className?: string
	link?: string
	type?: 'submit' | 'reset' | 'button'
}

export const Button: FC<TProps> = ({
	type = 'submit',
	children,
	onClick,
	modifiers = [],
	title,
	disabled,
	className,
	link,
	svg
}) => {
	const mod = {
		gold: false,
		success: false,
		full: false,
		small: false,
		red: false,
		inverse: false
	}

	modifiers.forEach(item => {
		mod[item] = true
	})

	if (link && !disabled)
		return (
			<a
				className={clsx(
					styles.button,
					className,
					disabled && styles.disabled,
					mod.success && styles.success,
					mod.gold && styles.gold,
					mod.small && styles.small,
					mod.full && styles.fullWidth,
					mod.inverse && styles.inverse,
					mod.red && styles.red
				)}
				href={link}
				data-content={title}
				onClick={onClick}
				type={type}
			>
				{svg && <div className={styles.icon}>{svg}</div>}
				<span className={styles.text}>{children}</span>
			</a>
		)

	return (
		<button
			className={clsx(
				styles.button,
				className,
				disabled && styles.disabled,
				mod.success && styles.success,
				mod.gold && styles.gold,
				mod.small && styles.small,
				mod.full && styles.fullWidth,
				mod.inverse && styles.inverse,
				mod.red && styles.red
			)}
			data-content={title}
			onClick={onClick}
			disabled={disabled}
			type={type}
		>
			{svg && <div className={styles.icon}>{svg}</div>}
			{children && <span className={styles.text}>{children}</span>}
		</button>
	)
}
