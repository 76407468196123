import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SchoolCategoryForm } from '../school-category-form'
import styles from './TotalFormCategory.module.scss'

import '@/Assets/sass/Pages/School/Form.sass'
import { Button, Checkbox, FormWrapper, Input } from '@/shared/ui/form'
import { FieldValues, useForm } from 'react-hook-form'
import { clsx } from 'clsx'
import { useCategoryDetail } from '../../api/category'
import { Loader } from '@/shared/ui/loader'
import { useTextLocality } from '@/shared/hooks'
import { typeLangForm } from '@/shared/constants.interface'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { PublishIconJSX } from '@/shared/assets/common/svg'
import { SchoolForm } from '../school-form'

interface ITotalFormCategory {
	languages: typeLangForm[]
	onSubmit: (val: FieldValues) => void
}

export const TotalFormCategory: FC<ITotalFormCategory> = ({
	languages,
	onSubmit
}) => {
	const { id } = useParams()
	const { getMessage } = useTextLocality()
	const form = useForm({ mode: 'onSubmit' })
	const { locale } = useAppSelector(state => state.defaultReducer)

	const defaultValues = {
		typeSubmit: id ? 'category' : 'new_category',
		category_id: id,
		isActive: true
	}

	const {
		formState: { errors }
	} = form

	const { data: category, isFetching } = useCategoryDetail(Number(id))

	const onSubmitForm = (values: FieldValues) => {
		const data = { ...values }
		onSubmit(data)
	}

	useEffect(() => {
		if (!category) {
			form.reset({
				...defaultValues
			})
		} else {
			form.reset({
				...defaultValues,
				...category
			})
		}
	}, [category])

	return (
		<div className={styles.wrapper}>
			{isFetching && <Loader absolute />}
			<FormWrapper
				className={clsx('generalFormBlock', styles.form)}
				methods={form}
				onSubmit={onSubmitForm}
			>
				<div className={styles.wrapperHead}>
					<Input
						name={'price'}
						disabled={isFetching}
						type='number'
						connect
						placeholder={'0'}
						label={getMessage('price')}
						required={true}
						errorText={errors?.['price']?.message}
						registerConfig={{
							required: messages[locale].required_field
						}}
					/>
					<Input
						name={'rating_buy'}
						type='number'
						disabled={isFetching}
						connect
						placeholder={'0'}
						label={getMessage('rating_read_buy')}
						required={true}
						errorText={errors?.['rating_buy']?.message}
						registerConfig={{
							required: messages[locale].required_field
						}}
					/>
					{/* <Checkbox
						name='isActive'
						title={getMessage('publish_now')}
						connect
						disabled={isFetching}
					/> */}
				</div>
				<div className={styles.formWrapper}>
					{languages.map((item, index) => (
						<SchoolForm
							key={index}
							form={form}
							setArticleItem={()=>{}}
							headingPlaceholder={item.headingPlaceholder}
							lang={item.lang}
							short={item.short}
							onSubmit={onSubmitForm}
							previewPlaceholder={item.previewPlaceholder}
							textPlaceholder={item.textPlaceholder}
						/>
					))}
				</div>
				<Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish_all')}
				</Button>
			</FormWrapper>
		</div>
	)
}
