import cn, { clsx } from 'clsx'
import en from 'date-fns/locale/en-US'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/src/stylesheets/datepicker.scss'

import { IPropCalendar } from '../form.interface'

import './Calendar.scss'
import CustomHeader from './CustomHeader'
import CustomInput from './CustomInput'
import styles from './CustomInput.module.scss'
import { useAppSelector } from '@/shared/model'
import { format } from 'date-fns'

export const Calendar: FC<IPropCalendar> = ({
	value,
	onChange,
	placeholder,
	inputClassName,
	calendarClassName,
	calendarRef,
	onBlur,
	...restProps
}) => {
	const [isOpen, setIsOpen] = useState<boolean | undefined>()

	const onChangeCustomInput = (e: ChangeEvent<HTMLInputElement> | null) => {
		console.log({ e })

		if (e === null) return onChange!(e)
		const date = new Date(Date.parse(e!.target.value))
		const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
		onChange!(date)
	}

	const { locale } = useAppSelector(state => state.defaultReducer)

	const onChangeDate = (date: Date | null) => {
		// const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")

		onChange!(date)
		// setIsOpen(false)
	}

	const onClickLabel = () => {
		// setIsOpen(true)
	}

	const onClearDate = () => {
		onChange!(null)
		setIsOpen(false)

	}

	return (
		<div className={clsx('calendarContainer', styles.wrapper)} onBlur={onBlur}>
			<DatePicker
				ref={calendarRef}
				locale={locale}
				{...(Array.isArray(value)
					? {
							startDate: value[0],
							endDate: value[1]
					  }
					: {
							selected: value
					  })}
				onChange={onChangeDate}
				customInput={
					<CustomInput
						value={Array.isArray(value) ? value : value?.toISOString()}
						onClick={onClickLabel}
						onClickHandler={onClickLabel}
						onClearDate={onClearDate}
						onChange={onChangeCustomInput}
						className={inputClassName}
					/>
				}
				placeholderText={placeholder}
				dateFormat='MM/dd/yyyy'
				// shouldCloseOnSelect={false}
				calendarClassName={cn('calendar', calendarClassName)}
				popperClassName='calendarPopout'
				{...restProps}
				renderCustomHeader={({
					date,
					changeYear,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled
				}) => (
					<CustomHeader
						date={date}
						changeYear={changeYear}
						onChange={onChangeDate}
						decreaseMonth={decreaseMonth}
						increaseMonth={increaseMonth}
						prevMonthButtonDisabled={prevMonthButtonDisabled}
						nextMonthButtonDisabled={nextMonthButtonDisabled}
					/>
				)}
			/>
		</div>
	)
}
