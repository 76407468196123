import { useAlertMessage } from '@/shared/hooks'
import SportService from '@/shared/services/sport.service'
import { useQuery } from '@tanstack/react-query'

export const useSearchMath = (search: string) => {
	const { sendAlertError } = useAlertMessage()

	const { data, isFetching, isLoading } = useQuery(
		['math get by search', search],
		() => SportService.getSearchedMatch(search),
		{
			select: ({ data }) => data,
			enabled: !!search,
			onError: (error: any) => {
				sendAlertError(error)
			},
			retry: 0
		}
	)

	return {
		data: data || null,
		isFetching,
		isLoading
	}
}
