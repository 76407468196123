import { reFormatNum } from '@/shared/utils'
import { FC } from 'react'
import styles from './PaymentStatistics.module.scss'


export const CustomTooltip: FC<any> = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<div className={styles.tooltip}>
				<div className={styles.label}>
					<span>{reFormatNum(payload[1].value, `₿`)}</span>
					{/* additional text */}
				</div>
			</div>
		)
	}

	return null
}
