import { PropsWithChildren, useState } from 'react'

import { ForgotPassword, Login } from '@/features/auth/ui'
import NotFound from '@/pages/nof-found'

export type TStepAuth = 'login' | 'registration' | 'forgot-password' | ''

export const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
	const [pageName, setPageName] = useState<TStepAuth>('registration')
	const [email, setEmail] = useState('')

	const refresh = localStorage.getItem('refresh')

	// Проверка авторизации
	if (!refresh) {
		switch (pageName) {
			case 'registration':
				return <Login setPageName={setPageName} />

			case 'forgot-password':
				return <ForgotPassword setPageName={setPageName} />

			default:
				return <NotFound />
		}
	}
	return <>{children}</>
}
