import {typeAction, typePaginator} from "../typesStore";

export interface typeProductProps {
    "id": number;
    "price": number;
    "promo_code": string;
    "rating_buy": number;
    "enName": string;
    "enDescription": string;
    "enImage": string;
    "is_active": boolean;
    "cnName"?: string;
    "cnDescription"?: string;
    "cnImage"?: string;
    "count": number;
}
export interface typeProductPropsCard {
    "id": number,
    "name": string,
    "description": string,
    "price": number,
    "promo_code": string,
    "rating_buy"?: number,
    "image"?: string,
    "video"?: string,
    "is_active": boolean
}

export interface typeDefaultShopState extends typePaginator {
    items: Array<typeProductProps>,
}


const defaultShopState:typeDefaultShopState = {
    items: [],
    total_count: 0,
    page: 1,
    count: 12,
}


// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultShopState, action: typeAction) {
    const {type, value} = action;
    switch (type) {
        case "SET_PRODUCTS":
            return {...value}
        default:
            return state
    }
}
