import { useQuery } from '@tanstack/react-query'
import { useAlertMessage } from '@/shared/hooks'
import AuthService from '@/shared/services/auth.service'

export const useUserData = () => {
	const { sendAlertError } = useAlertMessage()

	return useQuery(['get user data'], () => AuthService.getMe(), {
		select: ({ data }) => data,
		onError: (error: any) => {
			sendAlertError(error)
		}
	})
}
