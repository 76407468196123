import { useAlertMessage } from '@/shared/hooks/'
import AuthService from '@/shared/services/auth.service'
import { useMutation } from '@tanstack/react-query'

export const useLogin = () => {
	const { sendAlertError } = useAlertMessage()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['login '],
		(data: FormData) => AuthService.getTokens(data)
	)
	const loginUser = async (data: FormData, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, loginUser }
}
