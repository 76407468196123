import { HeadingShopForm, ShopProductForm } from '@/entities/shop/ui'
import { PublishIconJSX } from '@/shared/assets/common/svg'
import { languages } from '@/shared/constants'
import { useTextLocality } from '@/shared/hooks'
import { Button, FormWrapper } from '@/shared/ui/form'
import React, { FC, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import styles from './ShopForm.module.scss'
import { useParams } from 'react-router-dom'

interface IProps {
	setConfirmationSubmit: React.Dispatch<React.SetStateAction<boolean>>
	setEventForm: React.Dispatch<React.SetStateAction<FieldValues>>
}

export const ShopForm: FC<IProps> = ({
	setConfirmationSubmit,
	setEventForm
}) => {
	const { id } = useParams()

	const defaultValues = {
		typeSubmit: id ? 'edit_product' : 'new_product'
	}
	const [valuesHead, setValuesHead] = useState<FieldValues>()
	const { getMessage } = useTextLocality()

	const form = useForm()

	const onSubmitHandler = (values: FieldValues) => {
		setEventForm({ ...values, ...defaultValues })
		setConfirmationSubmit(true)
	}

	return (
		<>
			<FormWrapper
				className={styles.wrapper}
				methods={form}
				onSubmit={onSubmitHandler}
			>
				<HeadingShopForm setEventForm={setValuesHead} form={form} />

				<div className={styles.forms}>
					{languages.map((item, index) => (
						<ShopProductForm
							key={index}
							form={form}
							{...item}
							onSubmit={(values: FieldValues) => {
								setConfirmationSubmit(true)
								setEventForm({ ...values, ...valuesHead })
							}}
						/>
					))}
				</div>
				<div>
					<Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
						{getMessage('publish_all')}
					</Button>
				</div>
			</FormWrapper>
		</>
	)
}
