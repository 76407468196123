import { useAlertMessage } from '@/shared/hooks'
import ShopService from '@/shared/services/shop.service'
import { useMutation } from '@tanstack/react-query'

export const useSwitchStatusProduct = (id: number) => {
	const { sendAlertError } = useAlertMessage('success')
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['switch status product shop', id],
		(isActive: boolean) => ShopService.switchStatus(id, isActive)
	)
	const switchStatusProduct = async (
		isActive: boolean,
		callback?: () => void
	) => {
		mutateAsync(isActive)
			.then(res => {
				callback?.()
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, switchStatusProduct }
}
