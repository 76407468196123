import { useAppSelector } from '@/shared/model'
import { Header } from '@/widgets/header'
import { Navigation } from '@/widgets/navigation'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Link } from 'react-router-dom'

export interface typePage {
	title?: string
	description?: string
	position: string
	children?: JSX.Element
	ad_block?: JSX.Element
}

export const Layout: FC<typePage> = ({
	ad_block,
	description,
	position,
	children,
	title
}) => {
	const [breadCrumbs, setBreadCrumbs] = useState<string[]>([])
	const showNavigation = useAppSelector(
		state => state.defaultReducer.showNavigation
	)

	useEffect(() => {
		const path = window.location.pathname
		let arrPath = path.slice(1, path.length)
		if (arrPath.length > 0) {
			setBreadCrumbs(
				arrPath
					.split('/')
					.filter((item: string) => isNaN(Number.parseInt(item)))
			)
		} else setBreadCrumbs([])
	}, [])

	const generatePath = (breadCrumbsArr: string[]) => {
		return breadCrumbsArr.reduce((prev, current) => `${prev}/${current}`, '')
	}

	const isLastItem = (index: number, arrayLength: number) => {
		return index !== arrayLength - 1
	}

	

	return (
		<div
			id='App'
			className={clsx(
				showNavigation && 'App nav_active',
				!showNavigation && 'App'
			)}
		>
			<Navigation />
			<Header />
			<div id='AppMainBlock' className='AppMainBlock'>
				<div
					className={clsx(
						`App-mainBlock`,
						position,
						!ad_block && 'fullWidth',
						ad_block && `noneFull`
					)}
				>
					<div className='startLineInfo'>
						<div className='titlePage'>
							{title ? <FormattedMessage id={title} /> : null}
						</div>
						{!!breadCrumbs.length && (
							<ul>
								{breadCrumbs.map((item, index) => (
									<li key={index}>
										{isLastItem(index, breadCrumbs.length) ? (
											<>
												<Link
													to={generatePath(breadCrumbs.slice(1, index + 1))}
												>
													<FormattedMessage id={item} />
												</Link>
												&nbsp;&nbsp;&gt;&nbsp;&nbsp;
											</>
										) : (
											<FormattedMessage id={item} />
										)}
									</li>
								))}
							</ul>
						)}
					</div>
					{!!description && (
						<p className='description'>
							<FormattedMessage id={description} />
						</p>
					)}
					{children}
				</div>
				{!!ad_block && <div className='ad-block'>{ad_block}</div>}
			</div>
		</div>
	)
}
