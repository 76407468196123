import React from 'react'
import {
	BigCardAnalytics,
	MiddleCardAnalytics,
	MiniCardAnalytics
} from '@/entities/analytic'

import styles from './AnalyticDashboard.module.scss'

const data = [
	{
		name: '100',
		uv: 4000,
		pv: 2400,
		gv: 1200,
		amt: 2400
	},
	{
		name: '200',
		uv: 3000,
		pv: 1398,
		gv: 1500,
		amt: 2210
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		gv: 1200,
		amt: 2290
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		gv: 1200,
		amt: 2000
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		gv: 1200,
		amt: 2181
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		gv: 1200,
		amt: 2500
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		gv: 1200,
		amt: 2100
	}
]

export const AnalyticDashBoard = () => {
	const dashboardData = {
		mini: [
			{
				title: 'Registrations today',
				count: 2045,
				previousCount: 2630,
				color: 'red'
			},
			{
				title: 'Registrations today',
				count: 2045,
				previousCount: 420,
				color: 'green'
			},
			{
				title: 'Registrations today',
				count: 2045,
				previousCount: 42112,
				color: '#62ACE4'
			},
			{
				title: 'Registrations today',
				count: 2045,
				previousCount: 1212,
				color: '695CB8'
			}
		],
		middle: [
			{
				title: 'New registrations',
				description: 'New registrations from new users',
				points: data,
				isCalendar: true
			},
			{
				title: 'New users',
				description: 'New registrations from new users',
				points: data,
				isCalendar: false
			}
		],
		big: [
			{
				title: 'Histogram Produced',
				description: 'Statistics subinformation',
				points: data,
				isCalendar: false
			},
			{
				title: 'History Produced',
				description: 'Statistics subinformation',
				points: data,
				isCalendar: true
			}
		]
	}
	return (
		<>
			<div className={styles.containerOne}>
				{dashboardData.mini.map(item => (
					<MiniCardAnalytics
						key={item.previousCount}
						title={item.title}
						count={item.count}
						previousCount={item.previousCount}
						colorRight={item.color}
					/>
				))}
			</div>
			<div className={styles.containerTwo}>
				{dashboardData.middle.map(item => (
					<MiddleCardAnalytics
						key={item.title}
						title={item.title}
						description={item.description}
						points={item.points}
						endDateState={console.log}
						startDateState={console.log}
						calendar={item.isCalendar}
					/>
				))}
			</div>
			<>
				{dashboardData.big.map(item => (
					<BigCardAnalytics
						key={item.title}
						points={item.points}
						title={item.title}
						description={item.description}
						calendar={item.isCalendar}
					/>
				))}
			</>
		</>
	)
}
