import { IAppeal } from '@/shared/services/types/chat.types'
import { FC } from 'react'

import styles from './ItemChat.module.scss'
import clsx from 'clsx'
import { cutMyStringMin } from '@/shared/utils'

interface IProps {
	data: IAppeal
	onClick: () => void
	isActive: boolean
	isRead?: boolean
}

export const ItemChat: FC<IProps> = ({ data, onClick, isActive, isRead }) => {
	const cutMessage = cutMyStringMin(data.message, '<br/>')

	const message =
		cutMessage?.length > 40 ? cutMessage.slice(0, 40) + '...' : cutMessage
	return (
		<div
			className={clsx(styles.wrapper, isActive && styles.active)}
			onClick={onClick}
		>
			<div className={styles.avatar}>
				<svg
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<circle cx='16' cy='16' r='16' fill='#F4F4F9' />
					<path
						d='M16.0147 9.7627C14.388 9.7627 13.0547 11.096 13.0547 12.7227C13.0547 14.3494 14.388 15.6827 16.0147 15.6827C17.6414 15.6827 18.9747 14.3494 18.9747 12.7227C18.9747 11.0961 17.6414 9.7627 16.0147 9.7627Z'
						fill='#695CB8'
					/>
					<path
						d='M20.4147 17.2824C19.6414 16.5091 18.548 16.0557 17.4015 16.0557L14.628 16.0558C13.4813 16.0558 12.388 16.5091 11.6147 17.2825C10.4147 18.4825 9.74805 20.0825 9.74805 21.7892C9.74805 22.0825 9.98805 22.3225 10.2813 22.3225H21.7746C22.0679 22.3225 22.3079 22.0825 22.3079 21.7892C22.2814 20.0558 21.6147 18.4557 20.4147 17.2825L20.4147 17.2824Z'
						fill='#695CB8'
					/>
				</svg>
			</div>
			<div className={styles.info}>
				<div className={styles.userData}>
					<span>{data.room_id}</span>
					<span>{data.user_name || data.user}</span>
				</div>
				<div
					className={styles.message}
					dangerouslySetInnerHTML={{
						__html: `<span>${message}</span>`
					}}
				/>
			</div>
			{isRead && <div className={styles.point} />}
		</div>
	)
}
