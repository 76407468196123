import { useAlertMessage } from '@/shared/hooks'
import ShopService from '@/shared/services/shop.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useEditProduct = (id: number) => {
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const { sendAlertError, sendAlertMessage } = useAlertMessage()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['edit product shop', id],
		(data: FormData) => ShopService.editProduct(id, data)
	)
	const editProduct = async (data: FormData, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				sendAlertMessage('Success')
				navigate(`/content/shop`)
				queryClient.invalidateQueries({ queryKey: ['shop list'] })
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, editProduct }
}
