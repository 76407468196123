import AnalyticsService from '@/shared/services/analytics.service'
import { IAnalytics } from '@/shared/services/types/analytics.types'
import { sortDataInfinityPages } from '@/shared/utils'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export const useAnalyticsList = (value: number) => {
	const [dataList, setDataList] = useState<IAnalytics[] | null>(null)

	const {
		data,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		['analytics list'],
		({ pageParam = 1 }) =>
			AnalyticsService.getAll({ count: value, page: pageParam }),
		{
			getNextPageParam: (lastPage, pages) => {
				if (!lastPage.data.length || lastPage.data.length < value) return null
				return pages.length + 1
			},
			select: data => {
				const res = data.pages.map(item => item.data)
				return { ...data, pages: res }
			}
		}
	)

	useEffect(() => {
		const result = sortDataInfinityPages(data)

		if (!isLoading) setDataList(result)
	}, [data?.pages])

	return {
		data: dataList,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	}
}
