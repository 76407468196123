import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'

export const ShopDetail = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			description={getMessage('shop')}
			title={getMessage('dashboard')}
		/>
	)
}
