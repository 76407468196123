import { FC, useEffect, useState } from 'react'
import { BlogForm } from '../blog-form'
import { FieldValues, useForm } from 'react-hook-form'
import { Button, Checkbox, FormWrapper, Input } from '@/shared/ui/form'
import { clsx } from 'clsx'

import styles from './TotalForm.module.scss'
import { ISingleNews } from '@/shared/services/types/news.types'
import { useTextLocality } from '@/shared/hooks'
import { typeLangForm } from '@/shared/constants.interface'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { PublishIconJSX } from '@/shared/assets/common/svg'

const defaultValues = {
	rating_read_text: 0,
	rating_view_video: 0,
	isHidden: false,
	isActive: false,
	typeSubmit: 'edit_blog'
}

interface ITotalForm {
	languages: typeLangForm[]
	onSubmit: (val: FieldValues) => void
}

export const TotalBlogForm: FC<ITotalForm> = ({ languages, onSubmit }) => {
	const { getMessage } = useTextLocality()
	const { locale } = useAppSelector(state => state.defaultReducer)
	const [blogData, setBlogData] = useState<ISingleNews>()

	const form = useForm({
		mode: 'onChange',
		defaultValues: {
			...defaultValues,
			typeSubmit: blogData ? 'edit_blog' : 'new_blog'
		}
	})

	const {
		formState: { errors }
	} = form

	useEffect(() => {
		if (!blogData) return
		form.reset({
			...defaultValues,
			...blogData,
			typeSubmit: blogData ? 'edit_blog' : 'new_blog',
			rating_read_text: blogData.rating_read_text,
			rating_view_video: blogData.rating_view_video,
			isHidden: blogData.isHidden,
			isActive: blogData.isActive
		})
	}, [form, blogData])

	const onSubmitForm = (values: FieldValues) => {
		onSubmit({ ...values })
	}

	return (
		<div className={styles.wrapper}>
			<FormWrapper
				className={clsx('generalFormBlock', styles.form)}
				methods={form}
				onSubmit={onSubmitForm}
			>
				<div className={styles.wrapperHead}>
					<Input
						name={'rating_read_text'}
						connect
						placeholder={'0'}
						label={getMessage('rating_read_text')}
						required
						errorText={errors?.['rating_read_text']?.message}
						registerConfig={{
							required: messages[locale].required_field
						}}
					/>
					<Input
						name={'rating_view_video'}
						connect
						placeholder={'0'}
						label={getMessage('rating_view_video')}
						required
						errorText={errors?.['rating_view_video']?.message}
						registerConfig={{
							required: messages[locale].required_field
						}}
					/>

					<Checkbox name='isHidden' title={getMessage('is_hidden')} connect />

					<Checkbox name='isActive' title={getMessage('publish_now')} connect />
				</div>
				<div className={styles.wrapperForm}>
					{languages.map(item => (
						<BlogForm
							key={item.lang}
							form={form}
							setBlogData={setBlogData}
							props={{
								headingPlaceholder: item.headingPlaceholder,
								lang: item.lang,
								short: item.short,
								onSubmit: onSubmitForm,
								previewPlaceholder: item.previewPlaceholder,
								textPlaceholder: item.textPlaceholder
							}}
						/>
					))}
				</div>
				<Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish_all')}
				</Button>
			</FormWrapper>
		</div>
	)
}
