import React, { FC } from 'react'

import styles from './UserItem.module.scss'

interface IProps {
	title?: string
	value?: string | null | number
	isLoading?: boolean
}

export const UserItem: FC<IProps> = ({ title, value, isLoading }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				{title || ''}
				{isLoading && <div className={styles.skeleton} />}
			</div>
			<div className={styles.value}>
				{value || 'UNKNOW'}
				{isLoading && <div className={styles.skeleton} />}
			</div>
		</div>
	)
}
