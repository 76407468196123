import { Button, FormWrapper, Input, RadioGroup } from '@/shared/ui/form'
import styles from './BettingFilter.module.scss'
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { FilterIconJSX, SearchInput } from '@/shared/assets/common/svg'
import { DropDownMenu } from '@/shared/ui/drop-down-menu'
import clsx from 'clsx'
import { FC, useRef } from 'react'
import { CalendarInput } from '@/shared/ui/form/calendar-input'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	form: UseFormReturn<any>
	onSubmit: SubmitHandler<FieldValues>
}

export const BettingHistoryFilter: FC<IProps> = ({ form, onSubmit }) => {
	const { getMessage } = useTextLocality()

	const filterRef = useRef(null)
	const { watch, getValues } = form

	const options2 = [
		{
			label: getMessage('win'),
			value: 'win'
		},
		{
			label: getMessage('loss'),
			value: 'loss'
		},
		{
			label: getMessage('not_calculated'),
			value: 'wait'
		}
	]

	const resetFilter = () => {
		form.reset({
			...getValues(),
			filter: undefined,
			fromDate: undefined,
			toDate: undefined
		})
	}

	return (
		<div>
			<FormWrapper
				methods={form}
				className={styles.wrapper}
				onSubmit={onSubmit}
			>
				<Input
					containerClassName={styles.input}
					name='search'
					modifiers={['small']}
					connect
					placeholder={getMessage('searchById')}
					icon={<SearchInput />}
				/>
				<div className={styles.buttons}>
					<DropDownMenu
						refs={[filterRef]}
						header={
							<button
								type='button'
								className={clsx(
									styles.filterButton,
									(watch('filter') || watch('toDate') || watch('fromDate')) &&
										styles.activeButton
								)}
							>
								<FilterIconJSX />
							</button>
						}
						modifiers={['arrow']}
					>
						<div className={styles.wrapperFilter}>
							<div className={styles.title}>{getMessage('filters')}</div>
							<div className={styles.blockFilter}>
								<div className={styles.titleFilter}>
									{getMessage('activity')}
								</div>
								<RadioGroup connect name='filter' options={options2} />
							</div>
							<div className={styles.blockFilter}>
								<div className={styles.titleFilter}>
									{getMessage('by_date')}
								</div>
								<div className={styles.calendar}>
									<CalendarInput
										connect
										name='fromDate'
										containerClassName={styles.calendarInput}
										selectsStart
										maxDate={watch('toDate')}
										startDate={watch('fromDate')}
										selected={watch('fromDate')}
										endDate={watch('toDate')}
										placeholder={getMessage('from_date')}
									/>
									<span>-</span>
									<CalendarInput
										connect
										name='toDate'
										containerClassName={styles.calendarInput}
										selectsEnd
										startDate={watch('fromDate')}
										endDate={watch('toDate')}
										selected={watch('toDate')}
										minDate={watch('fromDate')}
										placeholder={getMessage('to_date')}
									/>
								</div>
							</div>
							<div className={styles.buttons} ref={filterRef}>
								<Button modifiers={['inverse']} onClick={resetFilter}>
									{getMessage('clear')} {getMessage('filters')}
								</Button>
								<Button modifiers={['inverse', 'red']}>
									{getMessage('save')}
								</Button>
							</div>
						</div>
					</DropDownMenu>
				</div>
			</FormWrapper>
		</div>
	)
}
