import { useAlertMessage } from '@/shared/hooks'
import ChatService from '@/shared/services/chat.service'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export const useWorkspaceAppeals = () => {
	const client = useQueryClient()

	const { sendAlertError, sendAlertMessage } = useAlertMessage('info')
	return useQuery(
		['get workspace appeals'],
		() => ChatService.getWorkplaceAppeals(),
		{
			select: ({ data }) => {
				return data
			},
			retry: 0,
			onSuccess: () => {
				client.invalidateQueries({ queryKey: ['get new appeals'] })
			},
			onError: (error: any) => {
				if (error.response?.status === 404)
					return sendAlertMessage(error.response.data.detail || '')
				sendAlertError(error)
			}
		}
	)
}
