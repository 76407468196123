import { FC, useState } from 'react'
import { BarChart, Bar, ResponsiveContainer } from 'recharts'

import 'react-datepicker/dist/react-datepicker.css'
import '@/Assets/sass/Components/BigCardAnalytics.sass'
import { DataButton } from '@/shared/ui/picker-custom/DataButton'

import styles from './BigCard.module.scss'

interface IProps {
	title: string
	description: string
	startDateState?: any
	endDateState?: any
	points: Array<any>
	calendar?: boolean
}

export const BigCardAnalytics: FC<IProps> = ({
	title,
	description,
	points,
	calendar
}) => {
	const dataButtons = ['All', '1M', '6M', '1Y']

	const [startDate, setStartDate] = useState(new Date('2014/02/08'))
	const [endDate, setEndDate] = useState(new Date('2014/02/10'))

	return (
		<div className={styles.wrapper}>
			<div className={styles.headerRow}>
				<div className={styles.leftBlock}>
					<div className={styles.title}>{title}</div>
					<div className={styles.description}>{description}</div>
				</div>
				<div className={styles.rightBlock}>
					{calendar ? (
						<>
							<DataButton
								selectedDate={startDate}
								setDate={setStartDate}
								startDate={startDate}
								endDate={endDate}
							/>{' '}
							-{' '}
							<DataButton
								selectedDate={endDate}
								setDate={setEndDate}
								startDate={startDate}
								endDate={endDate}
							/>
						</>
					) : (
						<>
							{dataButtons.map(item => (
								<button key={item}>{item}</button>
							))}
						</>
					)}
				</div>
			</div>
			<div className={styles.chart}>
				<ResponsiveContainer width={'100%'} height={200}>
					<BarChart height={190} data={points}>
						<Bar dataKey='uv' fill='#8884d8' />
						<Bar dataKey='pv' fill='red' />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}
