import axiosInstance from '@/shared/store/axiosGlobal'
import { IPaginationData } from './types/global.types'

import { INews, ISingleNews, IGetSearchedNewsData } from './types/news.types'
import { getNewsUrl } from '../api/config'

const NewsService = {
	async getAll({ page = 1, count = 21 }: IPaginationData) {
		return axiosInstance.get<INews[]>(
			getNewsUrl(`/list?page=${page}&count=${count}`)
		)
	},

	async getById(id: number) {
		return axiosInstance.get<ISingleNews>(getNewsUrl(`/get/${id}`))
	},

	async getSearched({
		searchTerm,
		page = 1,
		count = 21
	}: IGetSearchedNewsData) {
		return axiosInstance.get<ISingleNews[]>(
			getNewsUrl(`/search?page=${page}&count=${count}&query1=${searchTerm}`)
		)
	},

	async create(data: FormData) {
		return axiosInstance.post<ISingleNews>(getNewsUrl('/new'), data)
	},

	async edit(id: number, data: Partial<FormData>) {
		return axiosInstance.patch<string>(getNewsUrl(`/edit/${id}`), data)
	},

	async switchVisibility(id: number, isActive: boolean) {
		return axiosInstance.patch<ISingleNews>(
			getNewsUrl(`/is_active/${id}?isActive=${isActive}`)
		)
	},

	async delete(id: number) {
		return axiosInstance.delete<INews>(getNewsUrl(`/delete/${id}`))
	}
}

export default NewsService
