import { TStepAuth } from '@/app/providers/protected-route'
import { useAppSelector } from '@/shared/model'
import { Dispatch, FC, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AuthWrapper } from '../auth-wrapper'
import { FormWrapper, Input } from '@/shared/ui/form'
import { useForm } from 'react-hook-form'

import styles from './ForgotPassword.module.scss'
import { LogoIconJSX } from '@/shared/assets/auth/svg'

interface IProps {
	setPageName: Dispatch<React.SetStateAction<TStepAuth>>
}
export const ForgotPassword: FC<IProps> = ({ setPageName }) => {
	const loader = useAppSelector(state => state.defaultReducer.loader)

	const formLogin = useForm({ mode: 'onBlur' })
	const {
		formState: { errors }
	} = formLogin

	// const dispatch = useDispatch();

	const onSubmit = (e: any) => {
		e.preventDefault()
		// let form: FormData = new FormData(e.target);
		// dispatch(login(form))
		e.target.reset()
	}

	if (loader) return null
	return (
		<AuthWrapper>
			<FormWrapper
				methods={formLogin}
				onSubmit={onSubmit}
				className={styles.loginForm}
			>
				<LogoIconJSX />

				<div className='title'>Забыли пароль</div>
				<div className='inputBlock'>
					<FormattedMessage id='login_email'>
						{message => (
							<Input
								connect
								name='email'
								placeholder={message.toString()}
								registerConfig={{
									required: 'messages[locale].required_field'
								}}
							/>
						)}
					</FormattedMessage>
				</div>
				<button type='submit'>
					<FormattedMessage id='recover' />
				</button>
				<span onClick={() => setPageName('registration')}>
					<FormattedMessage id='forgot_pass' />
				</span>
			</FormWrapper>
		</AuthWrapper>
	)
}
