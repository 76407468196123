import { TotalFormCategory } from '@/entities/school/ui'
import { languages } from '@/shared/constants'
import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { PageForm } from '@/widgets/page-form'
import { useState } from 'react'
import { FieldValues } from 'react-hook-form'

const SchoolNewCategory = () => {
	const [confirmationSubmit, setConfirmationSubmit] = useState<boolean>(false)
	const [eventForm, setEventForm] = useState<FieldValues>({})
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			description={getMessage('new-category')}
			title={getMessage('school')}
		>
			<PageForm
				confirmationSubmit={confirmationSubmit}
				eventForm={eventForm}
				setConfirmationSubmit={setConfirmationSubmit}
			>
				<>
					<TotalFormCategory
						languages={languages}
						onSubmit={(values: FieldValues) => {
							setConfirmationSubmit(true)
							setEventForm(values)
						}}
					/>
				</>
			</PageForm>
		</Layout>
	)
}

export default SchoolNewCategory
