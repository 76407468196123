import { useAppSelector } from '@/shared/model'
import { Editor } from '@tinymce/tinymce-react'
import { FC } from 'react'
import { IProsEditor } from '../../form.interface'
import { API_KEY_EDITOR } from '@/shared/constants'

export const EditorFieldDefault: FC<IProsEditor> = ({
	onChange,
	value,
	editorRef
}) => {
	const { locale } = useAppSelector(state => state.defaultReducer)

	return (
		<div>
			<Editor
				key={locale}
				onEditorChange={val => {
					onChange?.(val)
				}}
				apiKey={API_KEY_EDITOR}
				onInit={(evt, editor) => {
					if (editorRef) editorRef.current = editor
				}}
				textareaName='body'
				value={value}
				init={{
					height: 500,
					menubar: false,
					language: locale === 'cn' ? 'zh_CN' : locale,
					plugins:
						'advlist charmap emoticons help image insertdatetime link ' +
						'lists powerpaste save visualblocks wordcount',
					toolbar:
						'undo redo | blocks | bold italic underline | ' +
						'alignleft aligncenter alignright | bullist numlist| forecolor | insert | help',
					toolbar_groups: {
						insert: {
							icon: 'plus',
							tooltip: 'Insert',
							items: 'link | charmap emoticons | image'
						}
					},

					content_css: 'writer'
				}}
			/>
		</div>
	)
}
