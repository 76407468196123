import React from 'react'
import { FormattedMessage } from 'react-intl'

import '@/Assets/sass/Elements/Search.sass'

interface typeSearchInputProps {
	onResult?: any
}

export const SearchInput: (props: typeSearchInputProps) => JSX.Element = () => {
	return (
		<FormattedMessage id='search'>
			{message => (
				<input
					className={'search-input'}
					type='text'
					placeholder={message.toString() + '...'}
				/>
			)}
		</FormattedMessage>
	)
}
