import { useAlertMessage } from '@/shared/hooks'
import SchoolService from '@/shared/services/school.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface IDataMutate {
	id: number
	isActive: boolean
}

export const useSwitchStatusArticle = () => {
	const { sendAlertError } = useAlertMessage()
	const client = useQueryClient()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['switch status article shop'],
		(data: IDataMutate) => SchoolService.activateArticle(data.id, data.isActive)
	)
	const switchStatusArticle = async (
		id: number,
		isActive: boolean,
		callback?: () => void
	) => {
		mutateAsync({ id, isActive })
			.then(res => {
				client.invalidateQueries({ queryKey: ['articles list'] })

				callback?.()
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, switchStatusArticle }
}
