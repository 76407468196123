import { IPaginationData } from './types/global.types'
import { axiosInstance } from '@/shared/api'

import { getShopUrl } from '../api/config'
import {
	IProduct,
	ISingleProduct,
	IGetSearchedProductsData,
	IStatusSwitchedProduct
} from './types/shop.types'

const ShopService = {
	async getAll({ count, page }: IPaginationData) {
		const res = axiosInstance.get<IProduct[]>(getShopUrl('/list'), {
			params: {
				page,
				count
			}
		})

		return res
	},

	async getById(id: number) {
		return axiosInstance.get<ISingleProduct>(getShopUrl(`/${id}`))
	},

	async buyProduct(id: number) {
		return axiosInstance.get<{ promo_code: string }>(
			getShopUrl(`/item/buy/${id}`)
		)
	},

	async getSearchedProducts({
		searchTerm,
		page = 1,
		count = 21
	}: IGetSearchedProductsData) {
		return axiosInstance.get<IProduct[]>(
			getShopUrl(`/search?query1=${searchTerm}&page=${page}&count=${count}`)
		)
	},

	async addProduct(data: FormData) {
		return axiosInstance.post<IProduct>(getShopUrl('/new'), data)
	},

	async editProduct(id: number, data: FormData) {
		return axiosInstance.patch<IProduct>(getShopUrl(`/edit/${id}`), data)
	},

	async switchStatus(id: number, isActive: boolean) {
		const form = new FormData()
		form.append('isActive', String(isActive))
		return axiosInstance.patch<IStatusSwitchedProduct>(
			getShopUrl(`/status/${id}`),
			form
		)
	},

	async delete(id: number) {
		return axiosInstance.delete<string>(getShopUrl(`/delete/${id}`))
	}
}

export default ShopService
