import { FC } from 'react'

import styles from './UserDetailCard.module.scss'
import { avatarUser } from '@/shared/assets/common/img'
import { Button } from '@/shared/ui/form'
import clsx from 'clsx'
import { useGetUserDetail } from '@/features/users/api'
import { reFormatNum, transformDateLocale } from '@/shared/utils'
import { useCloseRoom, useNewAppeals } from '@/features/chat/api'
import { useTextLocality } from '@/shared/hooks'

interface IProps {
	classWrapper?: string
	id?: number
}

export const UserDetailCard: FC<IProps> = ({ classWrapper, id }) => {
	const { refetch, isSuccess } = useCloseRoom(id)
	const { getMessage } = useTextLocality()
	const { data: news } = useNewAppeals(true)

	const onCloseRoomChat = () => {
		refetch()
	}
	const { data, isLoading } = useGetUserDetail(Number(id))

	const dataUser = {
		[getMessage('name')]: data?.name || '-',
		[getMessage('user_id')]: data?.id || '-',
		[getMessage('email')]: data?.email || '-',
		[getMessage('geography')]: '-' || '-',
		[getMessage('balance')]: `${reFormatNum(data?.wallet, '₿')}` || '-',
		[getMessage('experience')]: data?.rating || '-',
		[getMessage('date_registration')]:
			transformDateLocale(data?.registeredAt) || '-',
		[getMessage('activity_time')]:
			transformDateLocale(data?.lastVisit, true) || '-'
	}
	return (
		<div className={clsx(styles.wrapper, classWrapper)}>
			<div className={styles.userWrapper}>
				<div className={styles.userInfo}>
					<div className={styles.image}>
						<img src={avatarUser} alt='avatar' />
						{isLoading && <div className={styles.skeleton} />}
					</div>
					<div className={styles.list}>
						{Object.entries(dataUser).map(([val, key]) => (
							<div key={val} className={styles.field}>
								<div className={styles.name}>
									{val}
									{isLoading && <div className={styles.skeleton} />}
								</div>
								<div className={styles.value}>
									{key}
									{isLoading && <div className={styles.skeleton} />}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className={styles.userButtons}>
				{news && !isSuccess && (
					<Button disabled={isLoading} onClick={() => onCloseRoomChat()}>
						{getMessage('close_appeals')}
					</Button>
				)}

				<Button disabled={isLoading} modifiers={['inverse']}>
					{getMessage('redirect')}
				</Button>
			</div>
		</div>
	)
}
