import { useState, useEffect, FC } from 'react'
import { useLineOrTiles } from '@/shared/hooks/useLineOrTiles'
import { useTextLocality, useTranslationField } from '@/shared/hooks'
import ContentPanel from '@/entities/content-panel'
import { SchoolCard } from '@/entities/school/ui'
import {
	useNewsList,
	useSwitchStatusNew,
	useDeleteNew
} from '@/entities/blog/api'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { Loader } from '@/shared/ui/loader'
import { useInView } from 'react-intersection-observer'
import { Confirmation } from '@/shared/ui/confirmation'
import { Button } from '@/shared/ui/form'
import { AddItemJSX } from '@/shared/assets/common/svg'
import { INews } from '@/shared/services/types/news.types'
import styles from './BlogList.module.scss'

const mockData: INews = {
	cnBody: 'fdafsdafsdfsdfs‰',
	cnShort: 'asfdsafsa',
	cnTitle: 'ttttt',
	enBody: 'xxvsdfsd',
	enShort: 'dsfsdfsd',
	id: 1,
	isActive: true,
	enTitle: '21312'
}

// Список категорий статей
export const BlogList: FC = () => {
	const [selectedShop, setSelectedShop] = useState<number[]>([])
	const { getField } = useTranslationField()
	const { switchStatusNews } = useSwitchStatusNew()
	const { onDeleteNew } = useDeleteNew()
	const { getMessage } = useTextLocality()

	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useNewsList(21)

	const { ref, inView } = useInView({
		threshold: 0
	})

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	})

	const [confirmationDeletion, setConfirmationDeletion] = useState(false)
	const [newDetailId, setDetailId] = useState<number>()

	useEffect(() => {
		if (!confirmationDeletion) setDetailId(0)
	}, [confirmationDeletion])

	const { confirmationRow, setConfirmationRow } = useLineOrTiles()

	const onAlSelected = () => {
		const res = data?.map(item => item.id) || []
		if (selectedShop.length === data?.length) return setSelectedShop([])
		setSelectedShop(res)
	}

	const onAlSelectedCard = (id: number) => {
		const isActive = selectedShop.find(val => val === id)
		const newSelected = selectedShop.filter(val => val !== id)
		if (isActive) return setSelectedShop(newSelected)
		setSelectedShop([...selectedShop, id])
	}

	return (
		<>
			<ContentPanel
				setConfirmationDeletion={setConfirmationDeletion}
				confirmationRow={confirmationRow}
				setConfirmationRow={setConfirmationRow}
				checked={!!data?.length && selectedShop.length === data?.length}
				onChecked={onAlSelected}
				selectedItems={selectedShop}
				newTemplateBtn={
					<Button
						modifiers={['inverse']}
						svg={<AddItemJSX />}
						link='/content/blog/new'
					>
						{getMessage('new-article')}
					</Button>
				}
			/>
			<div className={clsx(styles.wrapperList)}>
				{data?.map(item => (
					<SchoolCard
						isLine={confirmationRow}
						key={item.id}
						editLink={`/content/blog/${item.id}`}
						checkedCard={!!selectedShop.find(id => id === item.id)}
						onChecked={onAlSelectedCard}
						setConfirmationDeletion={setConfirmationDeletion}
						setCategoryID={setDetailId}
						id={item.id}
						title={getField(item, 'title')}
						short={getField(item, 'short')}
						image={getField(item, 'image')}
						detailList={false}
						activeLink={switchStatusNews}
						updated_at={new Date().toString()}
						isActive={item.isActive}
						status_edit={true}
					/>
				))}
			</div>

			{!isLoading && !data?.length && <FormattedMessage id='not_found' />}
			<div ref={ref} />
			{isFetchingNextPage && <Loader isMini />}

			{isLoading && <Loader />}
			<Confirmation
				isOpen={confirmationDeletion}
				title={getMessage('confirm_ask')}
				setConfirmationState={() => {
					const arr = newDetailId ? [newDetailId] : selectedShop

					arr.forEach(item => {
						onDeleteNew(item)
					})
				}}
				setConfirmationOpen={setConfirmationDeletion}
			/>
		</>
	)
}
