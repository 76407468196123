import { useQuery } from '@tanstack/react-query'
import { useAlertMessage } from '@/shared/hooks'
import NewsService from '@/shared/services/news.service'

export const useNewDetail = (id: string) => {
	const { sendAlertError } = useAlertMessage()

	return useQuery(['detail news', id], () => NewsService.getById(+id!), {
		select: ({ data }) => data,
		enabled: !!id,
		onError: (error: any) => {
			sendAlertError(error)
		}
	})
}
