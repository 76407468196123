import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EditorFields, FormWrapper, Input, InputImage } from '@/shared/ui/form'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { Loader } from '@/shared/ui/loader'
import { useNewDetail } from '../../api'
import { useTextLocality } from '@/shared/hooks'
import { typeLangForm } from '@/shared/constants.interface'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'

interface IFormEditOrNew {
	props: typeLangForm
	setBlogData: any
	form: UseFormReturn<any, any, undefined>
}

export const BlogForm: FC<IFormEditOrNew> = ({ props, setBlogData, form }) => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	const {
		lang,
		headingPlaceholder,
		previewPlaceholder,
		textPlaceholder,
		short,
		onSubmit
	} = props
	const { getMessage } = useTextLocality()
	const { id_blog } = useParams()
	const { data, isFetching } = useNewDetail(id_blog!)

	const defaultValues = {
		lang: short,
		typeSubmit: id_blog ? 'edit_blog' : 'new_blog',
		id: id_blog
	}
	const {
		formState: { errors }
	} = form

	const onSubmitForm = (values: FieldValues) => {
		onSubmit(values)
	}

	useEffect(() => {
		if (!data) return
		form.reset({ ...defaultValues, ...(data || {}) })
		setBlogData(data)
	}, [data])

	return (
		<>
			<FormWrapper
				className={'PageEditOrNewForm'}
				methods={form}
				onSubmit={onSubmitForm}
			>
				{isFetching && <Loader absolute />}
				<div className='langTitle'>{lang}</div>
				<div className='required'>* {getMessage('required_field_tool')}</div>
				<Input
					name={short + 'Title'}
					connect
					placeholder={headingPlaceholder}
					label={getMessage('title')}
					required
					errorText={errors?.[short + 'Title']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<Input
					name={short + 'Short'}
					connect
					placeholder={previewPlaceholder}
					label={getMessage('description_short')}
					required
					errorText={errors?.[short + 'Short']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<EditorFields
					connect
					name={short + 'Body'}
					label={getMessage('description')}
					required
					placeholder={textPlaceholder}
					errorText={errors?.[short + 'Body']?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<div className='row'>
					<InputImage
						connect
						lang={lang}
						type='image/webp'
						name={short + `Image`}
						required={id_blog ? false : true}
						size='450х300'
					/>
					<InputImage
						connect
						lang={lang}
						type='video/'
						name={short + `Video`}
						required={id_blog ? false : true}
						size='3500x2500'
					/>
				</div>
				{/* <Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish')}
				</Button> */}
			</FormWrapper>
		</>
	)
}
