import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { AnalyticsList } from '@/widgets/analytic-list'

const Analytics = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description={getMessage('analytics')}
		>
			<AnalyticsList />
		</Layout>
	)
}

export default Analytics
