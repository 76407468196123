import { useAlertMessage } from '@/shared/hooks'
import ChatService from '@/shared/services/chat.service'
import { useQuery } from '@tanstack/react-query'

export const useHistoryAppeals = () => {
	const { sendAlertError, sendAlertMessage } = useAlertMessage('info')
	return useQuery(
		['get history appeals'],
		() => ChatService.getHistoryAppeals(),
		{
			select: ({ data }) => {
				return data
			},
			retry: 0,
			onError: (error: any) => {
				if (error.response?.status === 404)
					return sendAlertMessage(error.response.data.detail || '')

				sendAlertError(error)
			}
		}
	)
}
