import { FC, PropsWithChildren, Suspense } from 'react'
import { Loader } from '../loader'
import { ProtectedRoute } from '@/app/providers/protected-route'

export const SuspenseWrapper: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Suspense fallback={<Loader />}>
			<ProtectedRoute>{children}</ProtectedRoute>
		</Suspense>
	)
}
