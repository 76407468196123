import React, { useState } from 'react'

import styles from './DashboardBlock.module.scss'
import { DashboardWrapper } from '@/shared/ui/dashboard-wrapper'
import { RadioGroup } from '@/shared/ui/form'
import clsx from 'clsx'
import { useTextLocality } from '@/shared/hooks'

export const DashBoardsBlock = () => {
	const { getMessage } = useTextLocality()

	const [selectedItem, setSelectedItem] = useState<number | null>(null)
	return (
		<div className={styles.wrapper}>
			<DashboardWrapper classNameWrapper={styles.dashboardWrapper}>
				<div className={styles.dashboard}>
					<h2 className={styles.dashboardTitle}>
						{getMessage('betting_amount')}{' '}
					</h2>
					<RadioGroup
						containerClassName={styles.listOne}
						name='xxxx'
						options={[
							{ label: getMessage('no_limited'), value: 'false' },
							{ label: '1000%', value: '1000' }
						]}
					/>
				</div>
			</DashboardWrapper>
			<DashboardWrapper classNameWrapper={styles.dashboardWrapper}>
				<div className={styles.dashboard}>
					<h2 className={styles.dashboardTitle}>{getMessage('coefficient')}</h2>
					<div className={styles.list}>
						{[12, 55, 75, 100].map(item => (
							<div
								key={item}
								onClick={() => setSelectedItem(item)}
								className={clsx(
									styles.item,
									selectedItem === item && styles.active
								)}
							>
								<span>{item}%</span>
							</div>
						))}
					</div>
				</div>
			</DashboardWrapper>
		</div>
	)
}
