import { FC } from 'react'
import { IProsEditor } from '../form.interface'
import { EditorFieldDefault } from './editor-field-default'
import { ConnectEditor } from '../form-connect'

import styles from './EditorField.module.scss'
import clsx from 'clsx'

export const EditorFields: FC<IProsEditor> = ({
	connect,
	label,
	required,
	errorText,
	...restProps
}) => {
	const EditorComponent: FC<IProsEditor> = connect
		? ConnectEditor
		: EditorFieldDefault

	return (
		<div
			className={clsx(
				styles.wrapper,
				errorText && styles.errorWrapper,
				false && styles.simple
			)}
		>
			{label && (
				<label className={styles.label}>
					<span>
						{label} {required && '*'}
					</span>
				</label>
			)}
			<EditorComponent {...restProps} />
			{errorText && typeof errorText === 'string' && (
				<div className={styles.error}>
					<span>{errorText}</span>
				</div>
			)}
		</div>
	)
}
