import React, { FC } from 'react'

import styles from './PercentSectionCard.module.scss'

interface IProps {
	data: { title: string; value: number }
}

export const PercentSectionCard: FC<IProps> = ({ data }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{data.title}</div>
			<div className={styles.percent}>
				<span>{data.value} %</span>
			</div>
		</div>
	)
}
