import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'

export const useUsersStats = (id: number) => {
	return useQuery(
		['get use static' + id],
		() => StatisticService.getUsersInfo(id),
		{
			select: ({ data }) => data
		}
	)
}
