import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'
import { ISummBets } from '@/shared/services/types/statistic.types'
import { useAlertMessage } from '@/shared/hooks'

export const usePaymentBets = (data: ISummBets) => {
	const { sendAlertError } = useAlertMessage()
	return useQuery(
		['get payment bets' + data.fromDate],
		() => StatisticService.getSumBetsUsers(data),
		{
			select: ({ data }) => data,
			retry: 0,
			onError: (e: any) => {
				sendAlertError(e)
			}
		}
	)
}
