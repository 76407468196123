import { messages } from '@/i18n/messages'
import { typeLangForm } from './constants.interface'
import store from './store/store'
const { locale } = store.getState().defaultReducer

export const API_KEY_EDITOR = 'va3dd3egb29yy6hsnegrna9u55ljt433t320uq8gpv2vo7pv'

export const STATUS_BET = ['Closed', 'True', 'False', 'None']

export const languages: Array<typeLangForm> = [
	{
		lang: messages[locale].english,
		short: 'en',
		headingPlaceholder: 'Heading',
		previewPlaceholder:
			'This analytical section will provide you not only the results of recent matches, but also a detailed statistic of previous meetings with a period of as many as 10 years|',
		textPlaceholder:
			'This analytical section will provide you not only the results of recent matches, but also a detailed statistic of previous meetings with a period of as many as 10 years. This analytical section will provide you not only the results of recent matches, but also a detailed statistic of previous meetings with a period of as many as 10 years. This analytical section will provide you not only the results of recent matches, but also a detailed statistic of previous meetings with a period of as many as 10 years'
	},
	{
		lang: messages[locale].chinese,
		headingPlaceholder: '标题',
		short: 'cn',
		previewPlaceholder:
			'这个分析部分不仅会为您提供最近比赛的结果，还会为您提供前几次会议的详细统计数据，为期多达10年|',
		textPlaceholder:
			'这个分析部分不仅会为您提供最近比赛的结果，还会为您提供之前长达10年的会议的详细统计数据。 这个分析部分不仅将为您提供最近比赛的结果，还将为您提供长达10年的以前会议的详细统计数据。 这个分析部分不仅会为你提供最近比赛的结果，还会为你提供一个长达10年的历次会议的详细统计数字。'
	}
]

export const LANGUAGE_CODE = {
	en: 'en',
	ru: 'ru',
	cn: 'cn'
}
