import { InputCheckbox } from '@/shared/ui/input-checkbox'
import { typeProductPropsCard } from '@/shared/store/reducers/shop'
import clsx from 'clsx'
import { FC, useState } from 'react'

import styles from './ShopCard.module.scss'
import { useSwitchStatusProduct } from '../../api'
import { Button, Checkbox } from '@/shared/ui/form'
import { useTextLocality } from '@/shared/hooks'
import { DeleteIconJSX, EditIconJSX } from '@/shared/assets/common/svg'

interface IProps extends typeProductPropsCard {
	setConfirmationDeletion: any
	setProductID: any
	isLine?: boolean
	checkedCard: boolean
	onChecked: (id: number) => void
}

export const ShopCard: FC<IProps> = ({
	id,
	name,
	description,
	price,
	setConfirmationDeletion,
	setProductID,
	image,
	is_active,
	isLine,
	checkedCard,
	onChecked
}) => {
	const { switchStatusProduct } = useSwitchStatusProduct(id)
	const { getMessage } = useTextLocality()

	const [checked, setChecked] = useState(is_active)

	const toggleStatusProduct = () => {
		setChecked(!checked)
		switchStatusProduct(!checked)
	}
	return (
		<div className={clsx(styles.wrapper, isLine && styles.isLine)}>
			{isLine && (
				<div className={styles.select}>
					<Checkbox
						name='selectAll'
						modifier={['red']}
						checked={checkedCard}
						onChange={() => onChecked(id)}
					/>
				</div>
			)}

			<div className={styles.topBlock}>
				<div className={styles.image}>
					<img src={image || ''} alt='product img' />
				</div>
				{isLine && (
					<div className={styles.groupButton}>
						<Button
							modifiers={['inverse']}
							svg={<EditIconJSX />}
							link={`/content/shop/${id}/edit-product`}
						>
							{getMessage('edit')}
						</Button>
						<Button
							modifiers={['inverse']}
							svg={<DeleteIconJSX />}
							onClick={() => {
								setConfirmationDeletion(true)
								setProductID(id)
							}}
						>
							{getMessage('delete')}
						</Button>
					</div>
				)}
			</div>
			<div className={styles.info}>
				<div className={styles.title}>{name}</div>
				<div className={styles.description}>{description}</div>
				<div className={styles.price}>{price}₿</div>
			</div>
			{isLine && (
				<div className={styles.status}>
					<Checkbox
						name='status'
						checked={checked}
						title={checked ? getMessage('show') : getMessage('hide')}
						modifier={['card']}
						onChange={toggleStatusProduct}
					/>
				</div>
			)}
			{!isLine && (
				<div className={styles.groupButton}>
					<Button
						modifiers={['inverse']}
						svg={<EditIconJSX />}
						link={`/content/shop/${id}/edit-product`}
					>
						{getMessage('edit')}
					</Button>
					<Button
						modifiers={['inverse']}
						svg={<DeleteIconJSX />}
						onClick={() => {
							setConfirmationDeletion(true)
							setProductID(id)
						}}
					>
						{getMessage('delete')}
					</Button>
					<Checkbox
						name='selectAll'
						modifier={['red']}
						checked={checkedCard}
						onChange={() => onChecked(id)}
					/>
				</div>
			)}
		</div>
	)
}
