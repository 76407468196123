import { FormWrapper, Input, InputImage } from '@/shared/ui/form'
import { FC, useEffect } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useProductDetail } from '../../api'
import { Loader } from '@/shared/ui/loader'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { useTextLocality } from '@/shared/hooks'
import { FormProps } from '@/shared/constants.interface'

interface IPropsDefaultForm {
	lang: string
	typeSubmit: string
	enName: string
	cnName: string
	cnDescription: string
	enDescription: string
	enImage: string
	cnImage: string
}

interface IProps extends FormProps {
	form: UseFormReturn<FieldValues, any, undefined>
}

export const ShopProductForm: FC<IProps> = ({
	lang,
	short,
	headingPlaceholder,
	previewPlaceholder,
	form,
	onSubmit
}) => {
	const { id } = useParams()
	const { getMessage } = useTextLocality()

	const defaultValues = {
		lang: short,
		typeSubmit: id ? 'edit_product' : 'new_product'
	} as IPropsDefaultForm
	const { locale } = useAppSelector(state => state.defaultReducer)

	const { data, isFetching } = useProductDetail(Number(id))

	const name = (short + 'Name') as 'enName' | 'cnName'
	const description = (short + 'Description') as
		| 'enDescription'
		| 'cnDescription'
	const image = `${short}Image` as 'enImage' | 'cnImage'

	const onSubmitHandler = (values: FieldValues) => {
		onSubmit(values)
	}

	const {
		formState: { errors }
	} = form

	useEffect(() => {
		if (!data) return
		form.reset({
			[name]: data[name],
			[description]: data[description],
			[image]: data[image],
			...defaultValues,
			...data,
		})
	}, [data, short])

	return (
		<>
			<FormWrapper
				className={'PageEditOrNewForm'}
				methods={form}
				onSubmit={onSubmitHandler}
			>
				{isFetching && <Loader absolute />}
				<div className='langTitle'>{lang}</div>
				<div className='required'>* {getMessage('required_field_tool')}</div>
				<Input
					name={name}
					connect
					placeholder={headingPlaceholder}
					label={getMessage('title')}
					required
					errorText={errors?.[name]?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<Input
					name={description}
					connect
					placeholder={previewPlaceholder}
					errorText={errors?.[description]?.message}
					label={getMessage('description_short')}
					required
					registerConfig={{
						required: messages[locale].required_field
					}}
				/>
				<div style={{marginTop:'10px'}}>

				<InputImage
					connect
					lang={lang}
					type='image/webp'
					name={image}
					required={true}
					errorText={errors?.[image]?.message}
					size='450х300'
				/>
				</div>

				{/* <Button modifiers={['small', 'inverse']} svg={<PublishIconJSX />}>
					{getMessage('publish')}
				</Button> */}
			</FormWrapper>
		</>
	)
}
