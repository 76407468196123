import { FC } from 'react'
import styles from './StatusTable.module.scss'
import clsx from 'clsx'
interface IProps {
	isActive: boolean
	text: string
	colors?: ('active' | 'wait' | 'fall' | 'progress')[]
	color?: 'string'
}

export const StatusTable: FC<IProps> = ({
	isActive,
	text,
	colors = [],
	color
}) => {
	const mod = {
		active: false,
		fall: false,
		progress: false,
		wait: false
	}

	colors.forEach(item => {
		mod[item] = true
	})

	return (
		<div
			className={clsx(
				styles.wrapper,
				isActive && styles.active,
				mod.active && styles.active,
				mod.wait && styles.wait,
				mod.progress && styles.progress,
			)}
			style={{ background: color }}
		>
			{text}
		</div>
	)
}
