import { useQuery } from '@tanstack/react-query'

import StatisticService from '@/shared/services/statistics.service'

export const useUsersStats = (day: number) => {
	return useQuery(
		['get users static' + day],
		() => StatisticService.getUsersInfo(day),
		{
			select: ({ data }) => ({
				totalUsers: data['Total Registered Users'],
				newUsers: data[`New Registered users in ${day} day(s)`],
				activeUsers: data[`Active users in ${day} day(s)`] || 0,
				inactiveUsers:
					data['Total Registered Users'] - data[`Active users in ${day} day(s)`] || 0
			})
		}
	)
}
