import face from '@/Assets/img/Components/Header/Rectangle 2.png'
import { useUserData } from '@/features/auth/api'
import { DropDownMenu } from '@/shared/ui/drop-down-menu'
import { FormattedMessage } from 'react-intl'

import styles from './ProfileBlock.module.scss'
import AuthService from '@/shared/services/auth.service'
import { Button } from '@/shared/ui/form'
import { useTextLocality } from '@/shared/hooks'

export const ProfileBlock = () => {
	const { getMessage } = useTextLocality()
	const { data, isLoading } = useUserData()
	const roleName = {
		0: getMessage('systemAdmin'),
		1: getMessage('systemAdmin'),
		2: getMessage('manager'),
		3: getMessage('user')
	}
	type TRole = keyof typeof roleName
	const roleId = (data?.role_id || 0) as TRole

	const logout = () => {
		AuthService.logout()
	}

	return (
		<div className='profileBlock'>
			<div className='blockInfo'>
				<div className='name'>
					{data?.name || 'UNKNOWN'}
					{isLoading && <div className={styles.skeleton} />}
				</div>
				<DropDownMenu
					header={
						<div className={styles.role}>
							{roleName[roleId]}
							{isLoading && <div className={styles.skeleton} />}

							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
							>
								<path
									d='M12.25 5.75L8 10.25L3.75 5.75'
									stroke='#F4F4F9'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</div>
					}
				>
					<Button
						className={styles.menuUser}
						modifiers={['inverse', 'small']}
						onClick={logout}
					>
						<FormattedMessage id='logout' />
					</Button>
				</DropDownMenu>
			</div>
			<div className={styles.icon}>
				{isLoading && <div className={styles.skeleton} />}

				<img src={face} alt='' />
			</div>
		</div>
	)
}
