import { useAlertMessage } from '@/shared/hooks'
import AnalyticsService from '@/shared/services/analytics.service'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useCreateAnalytic = () => {
	const navigate = useNavigate()

	const { sendAlertError, sendAlertMessage } = useAlertMessage()
	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['create analytic'],
		(data: FormData) => AnalyticsService.create(data)
	)
	const onCreateAnalytic = async (data: FormData, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				sendAlertMessage('Success')
				navigate(`/content/analytics/edit/${res.data.item.id}`)
				return res
			})
			.catch(error => {
				sendAlertError(error)
				return error
			})
	}
	return { isLoading, isError, mutate, onCreateAnalytic }
}
