import { useTextLocality } from '@/shared/hooks'
import { Layout } from '@/shared/ui/layout'
import { BlogList } from '@/widgets/blog-list'

const Blogs = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			title={getMessage('dashboard')}
			description={getMessage('article')}
		>
			<BlogList />
		</Layout>
	)
}

export default Blogs
