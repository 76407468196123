import ContentPanel from '@/entities/content-panel'
import { SchoolCard } from '@/entities/school/ui/school-card'
import {
	useLineOrTiles,
	useTextLocality,
	useTranslationField
} from '@/shared/hooks'
import { Confirmation } from '@/shared/ui/confirmation'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import { Loader } from '@/shared/ui/loader'
import {
	useArticlesList,
	useDeleteArticle,
	useSwitchStatusArticle
} from '@/entities/school/api/article'
import { Button } from '@/shared/ui/form'
import { AddItemJSX } from '@/shared/assets/common/svg'

import styles from './ArticlesList.module.scss'

interface IProps {}

export const ArticlesList: FC<IProps> = () => {
	let { id } = useParams()
	const [selectedShop, setSelectedShop] = useState<number[]>([])
	const { onDeleteArticle } = useDeleteArticle()
	const { switchStatusArticle } = useSwitchStatusArticle()
	const { getMessage } = useTextLocality()

	const { getField } = useTranslationField()

	const [confirmationDeletion, setConfirmationDeletion] = useState(false)
	const [articleId, setArticleId] = useState<number>()

	const { confirmationRow, setConfirmationRow } = useLineOrTiles()

	const { ref, inView } = useInView({
		threshold: 0
	})

	const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
		useArticlesList(12, Number(id))

	useEffect(() => {
		if (!confirmationDeletion) setArticleId(0)
	}, [confirmationDeletion])

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	})

	const onAlSelected = () => {
		const res = data?.map(item => item.id) || []
		if (selectedShop.length === data?.length) return setSelectedShop([])
		setSelectedShop(res)
	}

	const onAlSelectedCard = (id: number) => {
		const isActive = selectedShop.find(val => val === id)
		const newSelected = selectedShop.filter(val => val !== id)
		if (isActive) return setSelectedShop(newSelected)
		setSelectedShop([...selectedShop, id])
	}

	return (
		<>
			<ContentPanel
				confirmationRow={confirmationRow}
				setConfirmationRow={setConfirmationRow}
				setConfirmationDeletion={setConfirmationDeletion}
				checked={!!data?.length && selectedShop.length === data?.length}
				selectedItems={selectedShop}
				onChecked={onAlSelected}
				newTemplateBtn={
					<Button
						modifiers={['inverse']}
						svg={<AddItemJSX />}
						link={`/content/school/new-article/${id}`}
					>
						{getMessage('new-article')}
					</Button>
				}
			/>
			<div className={clsx(styles.wrapperList)}>
				{data?.map(item => (
					<SchoolCard
						key={item.id}
						isLine={confirmationRow}
						checkedCard={!!selectedShop.find(id => id === item.id)}
						onChecked={onAlSelectedCard}
						editLink={`/content/school/${id}/article/${item.id}`}
						setConfirmationDeletion={setConfirmationDeletion}
						setCategoryID={setArticleId}
						id={item.id}
						title={getField(item, 'title')}
						short={getField(item, 'short')}
						detailList={false}
						activeLink={switchStatusArticle}
						image={getField(item, 'image')}
						isActive={item.isActive}
						status_edit={true}
						price={0}
					/>
				))}
			</div>
			{!isLoading && !data?.length && <FormattedMessage id='not_found' />}
			<div ref={ref} />

			{isLoading && <Loader />}
			{isFetchingNextPage && <Loader isMini />}

			<Confirmation
				isOpen={confirmationDeletion}
				title={getMessage('confirm_ask')}
				setConfirmationState={() => {
					const arr = articleId ? [articleId] : selectedShop
					arr
						.forEach(item => {
							onDeleteArticle(item)
						})
				}}
				setConfirmationOpen={setConfirmationDeletion}
			/>
		</>
	)
}
