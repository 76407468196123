import { useAlertMessage } from '@/shared/hooks'
import ChatService from '@/shared/services/chat.service'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export const useCloseRoom = (id?: number) => {
	const { sendAlertError } = useAlertMessage()
	const client = useQueryClient()

	return useQuery(['close room'], () => ChatService.getCloseRoom(id!), {
		select: ({ data }) => {
			return data
		},
		onError: (error: any) => {
			sendAlertError(error)
		},
		onSuccess: () => {
			client.invalidateQueries({
				queryKey: ['get history appeals']
			})
			client.invalidateQueries({
				queryKey: ['get new appeals']
			})
			client.invalidateQueries({
				queryKey: ['get workspace appeals']
			})
		},
		enabled: false
	})
}
