import { Layout } from '@/shared/ui/layout'
import { ShopList } from '@/widgets/shop-list'
import { useTextLocality } from '@/shared/hooks'
import '@/Assets/sass/Pages/Shop/Shop.sass'

export const Shop = () => {
	const { getMessage } = useTextLocality()

	return (
		<Layout
			position='center'
			description={getMessage('shop')}
			title={getMessage('dashboard')}
		>
			<ShopList />
		</Layout>
	)
}
