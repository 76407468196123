import { DashboardCard } from '@/entities/users/ui/dashboard-card'

import styles from './PaymentStatistics.module.scss'
import { ReactSelect } from '@/shared/ui/form'
import { FC, useEffect, useState } from 'react'
import { SingleValue } from 'react-select'
import { MyOptionType } from '@/shared/ui/form/form.interface'
import {
	CartesianGrid,
	Line,
	Bar,
	Tooltip,
	XAxis,
	YAxis,
	ComposedChart
} from 'recharts'
import { reFormatNum } from '@/shared/utils'
import { useTextLocality } from '@/shared/hooks'
import { usePaymentBets } from '@/features/statistics/api'
import { defaultFilter, filterDataMap } from '../helpers'
import { CustomTooltip } from './tooltip'

export const PaymentStatistics = () => {
	const { getMessage } = useTextLocality()

	const [filterDate, setFilterData] = useState(defaultFilter)
	const { data: betsSum, isLoading } = usePaymentBets(filterDate.current)
	const { data: prevBetsSum } = usePaymentBets(filterDate.prev)

	const totalVolume = betsSum?.sum_bets || 0

	const differentPercent =
		(betsSum?.win_percent || 1) - (prevBetsSum?.win_percent || 1)


	const selectData = [
		{ label: getMessage('monthly'), value: 'month' },
		{ label: getMessage('daily'), value: 'daily' },
		{ label: getMessage('weekly'), value: 'weekly' },
		{ label: getMessage('annually'), value: 'annually' }
	]
	const [selectedActive, setSelectedActive] = useState<
		SingleValue<MyOptionType>
	>(selectData[0])

	const periodName = {
		month: getMessage('prevMonth'),
		daily: getMessage('prevDay'),
		weekly: getMessage('prevWeek'),
		annually: getMessage('prevYears')
	}

	useEffect(() => {
		const keys = (selectedActive?.value || 'month') as
			| 'month'
			| 'annually'
			| 'weekly'
			| 'daily'
		setFilterData(filterDataMap[keys])
	}, [selectedActive])

	const mockData = {
		title: getMessage('amountDeposits'),
		//@ts-ignore
		periodName: periodName[selectedActive.value],
		number: totalVolume,
		currency:'₿',
		stonks: String(Math.abs(differentPercent).toFixed(2)),
		isFall: differentPercent < 0,
		colors:
			differentPercent < 0
				? {
						main: '#DC0000',
						light: '#FF0A1333',
						boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(255, 10, 19, 0.1)`
				  }
				: {
						main: '#54C86E',
						light: '#54C86E1A',
						boxShadow: `0px 11.820895195007324px 23.64179039001465px rgba(84, 200, 110, 0.40)`
				  }
	}

	const onChangeSelected = (data: SingleValue<MyOptionType>) => {
		setSelectedActive(data)
	}

	const data = [
		{
			name: 'Jan',
			uv: 400,
			pv: 6000
		},
		{
			name: 'Feb',
			pv: 6000,
			uv: 3000
		},
		{
			name: 'Mar',
			pv: 6000,
			uv: 2000
		},
		{
			name: 'Apr',
			pv: 6000,
			uv: 1890
		},
		{
			name: 'May',
			pv: 6000,
			uv: 1890
		},
		{
			name: 'Jun',
			pv: 6000,
			uv: 2390
		}
	]

	return (
		<>
			<div className={styles.list}>
				<DashboardCard
					modifier={['hugo', 'large']}
					stylesWrapper={{ width: '100%', maxWidth: 597 }}
					// isLoading={isLoading}
					data={mockData}
					filter={
						<ReactSelect
							name='filter'
							containerClassName={styles.react}
							modifier={['simple']}
							value={selectedActive}
							options={selectData}
							onChange={onChangeSelected}
						/>
					}
				/>

				<DashboardCard
					modifier={['large', 'hugo']}
					// isLoading={isLoading}
					stylesWrapper={{ width: '100%', maxWidth: 496 }}
					data={{ title: getMessage('purchase'), number: 0 }}
				>
					<ComposedChart width={350} height={189} data={data}>
						<XAxis dataKey='name' />
						<YAxis
							tickCount={3}
							tickFormatter={value => reFormatNum(Number(value), '₿')}
						/>
						<Tooltip content={CustomTooltip} />
						<CartesianGrid stroke='#f5f5f5' />
						<Bar dataKey='pv' barSize={40} fill='#F0F1FD' />
						<Line type='monotone' dataKey='uv' stroke='#62ACE4' dot={false} />
					</ComposedChart>
				</DashboardCard>
			</div>
		</>
	)
}
