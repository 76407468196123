import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { ILinkItemMenuProps } from '../constants.interface'

export function usePageViews(inData: Array<ILinkItemMenuProps>, outData: any) {
	let location = useLocation()
	useEffect(() => {
		inData.find((tab, index) => {
			if (location.pathname.search(tab.link) !== -1) {
				outData((prevState: Array<ILinkItemMenuProps>) => {
					inData.find((item, index_find) => {
						if (item.itemMenu.active) {
							prevState[index_find].itemMenu.active = false
							return true
						} else return false
					})
					prevState[index].itemMenu.active = true
					return [...prevState]
				})
				return true
			} else return false
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])
}
