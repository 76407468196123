import { Checkbox, FormWrapper, Input } from '@/shared/ui/form'
import { FC, useEffect } from 'react'
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useProductDetail } from '../../api'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { useTextLocality } from '@/shared/hooks'
import clsx from 'clsx'

import styles from './HeadingForm.module.scss'

interface IProps {
	setEventForm: React.Dispatch<React.SetStateAction<FieldValues>>
	form: UseFormReturn<FieldValues, any, undefined>
}

export const HeadingShopForm: FC<IProps> = ({ setEventForm, form }) => {
	const { getMessage } = useTextLocality()
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { id } = useParams<{ id: string }>()
	const { data, isFetching } = useProductDetail(Number(id))

	const {
		formState: { errors }
	} = form

	useEffect(() => {
		if (data) {
			const responseData = {
				price: data.price,
				id: data.id,
				rating_buy: data.rating_buy,
				// secrets: data.secrets,
				// count: data.count,
				isActive: data.isActive
			}
			form.reset(responseData)
			setEventForm(responseData)
		}
	}, [data])

	return (
		<>
			<FormWrapper methods={form} className={clsx(styles.wrapper, '')}>
				<Input
					name={'price'}
					type='number'
					disabled={isFetching}
					connect
					placeholder={'34000руб'}
					label={'Цена'}
					required
					errorText={errors?.price?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
					onBlur={() => {
						setEventForm(prev => ({ ...prev, price: form.watch('price') }))
					}}
				/>
				<Input
					name={'rating_buy'}
					type='number'
					connect
					disabled={isFetching}
					placeholder={'0'}
					label={getMessage('rating_read_text')}
					required
					errorText={errors?.rating_buy?.message}
					registerConfig={{
						required: messages[locale].required_field
					}}
					onBlur={() => {
						setEventForm(prev => ({
							...prev,
							rating_buy: form.watch('rating_buy')
						}))
					}}
				/>
				<Input
					name={'secrets'}
					connect
					disabled={isFetching}
					placeholder={'promo'}
					label={getMessage('promocode_list')}
					required
					errorText={errors?.secrets?.message}
					registerConfig={{
						required: data ? false : messages[locale].required_field
					}}
					onBlur={() => {
						setEventForm(prev => ({ ...prev, secrets: form.watch('secrets') }))
					}}
				/>
				<Input
					name={'count'}
					disabled={isFetching}
					connect
					placeholder={'count'}
					label={getMessage('promocode_count')}
					required={id ? false : true}
					errorText={errors?.count?.message}
					registerConfig={{
						required: data ? false : messages[locale].required_field
					}}
					onBlur={() => {
						setEventForm(prev => ({ ...prev, count: form.watch('count') }))
					}}
				/>
				<Checkbox
					name='isActive'
					disabled={isFetching}
					title={getMessage('is_active')}
					connect
					onBlur={() => {
						setEventForm(prev => ({
							...prev,
							isActive: form.watch('isActive')
						}))
					}}
				/>
			</FormWrapper>
		</>
	)
}
