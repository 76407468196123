import { useAlertMessage } from '@/shared/hooks'
import ShopService from '@/shared/services/shop.service'
import { useQuery } from '@tanstack/react-query'

export const useProductDetail = (id: number) => {
	const { sendAlertError } = useAlertMessage()

	return useQuery(['product detail shop', id], () => ShopService.getById(id), {
		select: ({ data }) => data,
		enabled: !!id,
		retry:0,
		onError: (error: any) => {
			sendAlertError(error)
		}
	})
}
