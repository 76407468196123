import { ChangeEvent, DragEvent, FC, useRef } from 'react'
import { FileDrop } from 'react-file-drop'
import { useAppDispatch } from '@/shared/model'
import { setMessage } from '@/shared/store/actions/message'
import clsx from 'clsx'
import styles from './InputImageDef.module.scss'
import { IProsImage } from '../../form.interface'
import { useTextLocality } from '@/shared/hooks'
import { Button } from '../../button'
import { DeleteIconJSX, EditIconJSX } from '@/shared/assets/common/svg'

export const InputImageDefault: FC<IProsImage> = ({
	lang,
	img,
	type,
	name,
	required,
	imageRef,
	onChange,
	value,
	size
}) => {
	const ref = useRef<HTMLInputElement>(null)
	const { getMessage } = useTextLocality()
	const refLabel = useRef<HTMLLabelElement>(null)
	const dispatch = useAppDispatch()

	const labelStyle =
		img && typeof img === 'string'
			? {
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url('${img}')`
			  }
			: {}

	const onSubmitErrorType = () => {
		dispatch(
			setMessage({
				message: 'Необходимый формат .' + type,
				type: 'error'
			})
		)
	}

	const onDrop = (files: FileList | null) => {
		if (!files || !files[0]) return null

		if (files[0].type?.includes(type)) {
			let input = ref.current
			if (input) {
				input.files = files
			}
		} else {
			onSubmitErrorType()
		}
	}

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target
		const files = event.target.files

		if (!files || !files[0]) return null

		if (files[0].type.includes(type)) {
			if (files && files.length > 0) {
				const background = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url('${URL.createObjectURL(
					files[0]
				)}')`

				setTimeout(() => {
					target.labels![0].style.backgroundImage = background
				}, 0)

				onChange?.(files[0])
			}
		} else {
			onSubmitErrorType()
		}
	}

	const onDropLabel = (e: DragEvent<HTMLLabelElement>) => {
		setTimeout(() => {
			const input = ref.current
			let target = e.target as HTMLElement

			if (!input) return null

			if (input.files && input.files.length > 0) {
				target.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url('${URL.createObjectURL(
					input.files[0]
				)}')`
			}
		}, 200)
	}

	return (
		<div className={clsx(styles.wrapper, img && styles.uploadWrapper)}>
			<FileDrop onDrop={onDrop}>
				<label
					ref={refLabel}
					className={clsx(
						styles.label,
						img && styles.upload,
						!img && styles.withoutBackground
					)}
					htmlFor={'img_' + name + lang + type}
					onDrop={onDropLabel}
					style={labelStyle}
				>
					<input
						className={styles.input}
						type='file'
						ref={imageRef}
						id={'img_' + name + lang + type}
						name={name}
						accept={type}
						value={''}
						required={required}
						onChange={onChangeInput}
					/>
					<button
						type='button'
						className={clsx(styles.button)}
						onClick={() => {
							refLabel?.current?.click()
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='15'
							height='16'
							viewBox='0 0 15 16'
							fill='none'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M7.5 0.666504C3.45001 0.666504 0.166672 3.94984 0.166672 7.99984C0.166672 12.0498 3.45001 15.3332 7.5 15.3332C11.55 15.3332 14.8333 12.0498 14.8333 7.99984C14.8333 3.94984 11.55 0.666504 7.5 0.666504ZM8.16667 10.6665C8.16667 10.8433 8.09643 11.0129 7.97141 11.1379C7.84639 11.2629 7.67682 11.3332 7.5 11.3332C7.32319 11.3332 7.15362 11.2629 7.0286 11.1379C6.90358 11.0129 6.83334 10.8433 6.83334 10.6665V8.6665H4.83334C4.65653 8.6665 4.48696 8.59627 4.36193 8.47124C4.23691 8.34622 4.16667 8.17665 4.16667 7.99984C4.16667 7.82303 4.23691 7.65346 4.36193 7.52843C4.48696 7.40341 4.65653 7.33317 4.83334 7.33317H6.83334V5.33317C6.83334 5.15636 6.90358 4.98679 7.0286 4.86177C7.15362 4.73674 7.32319 4.6665 7.5 4.6665C7.67682 4.6665 7.84639 4.73674 7.97141 4.86177C8.09643 4.98679 8.16667 5.15636 8.16667 5.33317V7.33317H10.1667C10.3435 7.33317 10.5131 7.40341 10.6381 7.52843C10.7631 7.65346 10.8333 7.82303 10.8333 7.99984C10.8333 8.17665 10.7631 8.34622 10.6381 8.47124C10.5131 8.59627 10.3435 8.6665 10.1667 8.6665H8.16667V10.6665Z'
								fill='#676C79'
							/>
						</svg>
						{type.includes('video') ? getMessage('video') : getMessage('image')}
					</button>
					<span className={styles.type}>
						.{type} {size} px
					</span>
				</label>
			</FileDrop>
			{img && (
				<div className={styles.tooltip}>
					<div className={styles.buttons}>
						<Button
							type='button'
							modifiers={['inverse']}
							svg={<EditIconJSX />}
							onClick={() => refLabel?.current?.click()}
						/>

						<Button
							type='button'
							modifiers={['inverse']}
							svg={<DeleteIconJSX />}
							onClick={() => {
								onChange?.('')
								if (refLabel?.current?.style) {
									refLabel.current.style.background = ''
								}
							}}
						/>
					</div>
					<div className={styles.nameFile}>
						{' '}
						{typeof img === 'string' ? img : img.name}
					</div>
				</div>
			)}
		</div>
	)
}
