import { useEffect, useState } from 'react'

import '@/Assets/sass/Pages/School/Category.sass'

import { useLineOrTiles } from '@/shared/hooks/useLineOrTiles'
import ContentPanel from '@/entities/content-panel'
import { SchoolCard } from '@/entities/school/ui/school-card'
import {
	useCategoriesList,
	useSwitchStatusCategory
} from '@/entities/school/api/category'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import { Loader } from '@/shared/ui/loader'
import { useTextLocality, useTranslationField } from '@/shared/hooks'
import clsx from 'clsx'
import { Button } from '@/shared/ui/form'
import { AddItemJSX } from '@/shared/assets/common/svg'

import styles from './SchoolList.module.scss'
import { Confirmation } from '@/shared/ui/confirmation'

export const SchoolList: () => JSX.Element = () => {
	const [confirmationDeletion, setConfirmationDeletion] = useState(false)
	const [selectedShop, setSelectedShop] = useState<number[]>([])
	const { switchStatusCategory } = useSwitchStatusCategory()
	const { getField } = useTranslationField()
	const { getMessage } = useTextLocality()

	const { ref, inView } = useInView({
		threshold: 0
	})
	const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
		useCategoriesList(12)

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	})

	const { confirmationRow, setConfirmationRow } = useLineOrTiles()

	const onAlSelected = () => {
		const res = data?.map(item => item.id) || []
		if (selectedShop.length === data?.length) return setSelectedShop([])
		setSelectedShop(res)
	}

	const onAlSelectedCard = (id: number) => {
		const isActive = selectedShop.find(val => val === id)
		const newSelected = selectedShop.filter(val => val !== id)
		if (isActive) return setSelectedShop(newSelected)
		setSelectedShop([...selectedShop, id])
	}

	return (
		<>
			<ContentPanel
				confirmationRow={confirmationRow}
				setConfirmationRow={setConfirmationRow}
				selectedItems={selectedShop}
				checked={!!data?.length && selectedShop.length === data?.length}
				onChecked={onAlSelected}
				setConfirmationDeletion={setConfirmationDeletion}
				newTemplateBtn={
					<Button
						modifiers={['inverse']}
						svg={<AddItemJSX />}
						link='/content/school/new-category'
					>
						{getMessage('new-category')}
					</Button>
				}
			/>

			<div className={clsx(styles.wrapperList)}>
				{data?.map(item => (
					<SchoolCard
						id={item.id}
						isLine={confirmationRow}
						key={item.id}
						title={getField(item, 'title')}
						checkedCard={!!selectedShop.find(id => id === item.id)}
						onChecked={onAlSelectedCard}
						short={getField(item, 'short')}
						image={getField(item, 'image')}
						editLink={`/content/school/${item.id}/edit-category`}
						activeLink={switchStatusCategory}
						detailList={true}
						status_edit={true}
						isActive={item.isActive}
					/>
				))}
			</div>
			{!isLoading && !data?.length && <FormattedMessage id='not_found' />}
			<div ref={ref} />

			{isLoading && <Loader />}
			{isFetchingNextPage && <Loader isMini />}

			<Confirmation
				isOpen={confirmationDeletion}
				title={getMessage('confirm_ask')}
				setConfirmationState={() => {}}
				setConfirmationOpen={setConfirmationDeletion}
			/>
		</>
	)
}
