import { UserDetailCard } from '@/entities/user-detail/ui'
import styles from './UserDetailsDashboard.module.scss'
import { Tab, Tabs } from '../tabs'
import {
	BettingHistory,
	Bonuses,
	GameHistory,
	Limitations,
	TransactionHistory
} from '@/features/user-details/ui'
import { useLocation, useParams } from 'react-router-dom'
import { useTextLocality } from '@/shared/hooks'

export const UserDetailDashboard = () => {
	const { getMessage } = useTextLocality()
	const { search } = useLocation()

	const activeTab = search.replace('?tabs=', '')

	const { id } = useParams()

	return (
		<div className={styles.wrapper}>
			<UserDetailCard classWrapper={styles.info} id={Number(id)} />
			<div className={styles.content}>
				{/* @ts-ignore */}
				<Tabs
					activeTabId={activeTab || 'betting-history'}
					redirect={`/statistics/users/${id}`}
				>
					<Tab id='betting-history' title={getMessage('betting_history')}>
						<BettingHistory id={Number(id)} />
					</Tab>
					<Tab id='limitations' title={getMessage('limitations')}>
						<Limitations id={Number(id)} />
					</Tab>
					<Tab
						id='transaction-history'
						title={getMessage('transaction_history')}
					>
						<TransactionHistory id={Number(id)} />
					</Tab>
					<Tab id='game-history' title={getMessage('game_history')}>
						<GameHistory id={Number(id)} />
					</Tab>
					<Tab id='bonuses' title={getMessage('bonuses')}>
						<Bonuses id={Number(id)} />
					</Tab>
				</Tabs>
			</div>
		</div>
	)
}
