// Локализация
import { useAppSelector } from '@/shared/model'
import en from 'date-fns/locale/en-GB'
import cn from 'date-fns/locale/zh-CN'
//
import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { useAlert } from 'react-alert'
import { registerLocale } from 'react-datepicker'

export const ErrorInterceptor: FC<PropsWithChildren> = ({ children }) => {
	let message = useAppSelector(state => state.message)
	const alert = useAlert() // Хук алертов

	// Локализация календаря
	useEffect(() => {
		registerLocale('cn', cn)
		registerLocale('en', en)
	}, [])

	// Определение типа алерта
	useMemo(() => {
		switch (message.type) {
			case 'info':
				alert.show(message.message)
				break
			case 'success':
				alert.success(message.message)
				break
			case 'error':
				alert.error(message.message)
				break
		}
	}, [message])

	return <>{children}</>
}
