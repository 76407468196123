import { TStepAuth } from '@/app/providers/protected-route'
import { useAppSelector } from '@/shared/model'
import { Dispatch, FC } from 'react'
import { FormattedMessage } from 'react-intl'

import '@/Assets/sass/Auth.sass'
import { LogoIconJSX } from '@/shared/assets/auth/svg'
import { Checkbox, FormWrapper, Input } from '@/shared/ui/form'
import { FieldValues, useForm } from 'react-hook-form'

import styles from './Login.module.scss'
import { AuthWrapper } from '../auth-wrapper'
import { messages } from '@/i18n/messages'
import { validEmail } from '@/shared/regex'
import { useLogin } from '../../api'

interface IProps {
	setPageName: Dispatch<React.SetStateAction<TStepAuth>>
}

export const Login: FC<IProps> = ({ setPageName }) => {
	const defaultValues = { email: '', password: '', rememberMe: true }
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { loginUser, isLoading } = useLogin()

	const formLogin = useForm({
		mode: 'onBlur',
		defaultValues
	})
	const {
		formState: { errors },
		reset
	} = formLogin

	const onLogin = (data: FieldValues) => {
		const form = new FormData()

		for (const key in data) {
			form.append(key, data[key])
		}
		form.delete('rememberMe')

		try {
			loginUser(form, () => setPageName(''))
		} catch (error) {
			setPageName('')
		}
		reset(defaultValues)
	}
	return (
		<AuthWrapper>
			<FormWrapper
				methods={formLogin}
				onSubmit={onLogin}
				className={styles.loginForm}
			>
				<LogoIconJSX />
				<div className='title'>Вход</div>
				<div className='inputBlock'>
					<FormattedMessage id='login_email'>
						{message => (
							<>
								<Input
									connect
									containerClassName={styles.input}
									name='email'
									errorText={errors?.email?.message}
									placeholder={message.toString()}
									label={message.toString()}
									registerConfig={{
										required: messages[locale].required_field,
										pattern: {
											value: validEmail,
											message: messages[locale].correct_email
										}
									}}
								/>
							</>
						)}
					</FormattedMessage>
				</div>
				<div className='inputBlock'>
					<FormattedMessage id='login_pass'>
						{message => (
							<Input
								errorText={errors?.password?.message}
								containerClassName={styles.input}
								connect
								name='password'
								placeholder={message.toString()}
								label={message.toString()}
								registerConfig={{
									required: messages[locale].required_field,
									minLength: {
										value: 8,
										message: messages[locale].minimum_characters + ' - 8'
									}
								}}
							/>
						)}
					</FormattedMessage>
				</div>
				<div className={styles.blockButton}>
					<Checkbox name='rememberMe' title='Запомнить меня' connect />
					<span onClick={() => setPageName('forgot-password')}>
						<FormattedMessage id='forgot_pass' />
					</span>
				</div>
				<button type='submit' disabled={isLoading}>
					<FormattedMessage id='login' />
				</button>
			</FormWrapper>
		</AuthWrapper>
	)
}
