import clsx from 'clsx'
import { FC, PropsWithChildren, useEffect, useState } from 'react'

import styles from './DropDownMenu.module.scss'
import useOutsideClick from '@/shared/hooks/useOutsideClick'

type TProps = PropsWithChildren & {
	header: JSX.Element
	modifiers?: ('arrow' | 'shadow' | 'right')[]
	refs?: any[]
}

export const DropDownMenu: FC<TProps> = ({
	children,
	header,
	refs,
	modifiers = []
}) => {
	const { ref, isActive } = useOutsideClick(false, refs)
	const [isActiveDrop, setIsActiveDrop] = useState(false)

	const mod = {
		arrow: false,
		shadow: false,
		right: false
	}

	modifiers.forEach(item => {
		mod[item] = true
	})

	useEffect(() => {
		if (!isActive) setIsActiveDrop(!!isActive)
	}, [isActive])

	return (
		<div className={styles.wrapper} ref={ref}>
			<div
				className={styles.header}
				onClick={() => setIsActiveDrop(!isActiveDrop)}
			>
				{header}
			</div>

			<div
				className={clsx(
					styles.content,
					isActiveDrop && styles.active,
					mod.arrow && styles.arrow,
					mod.right && styles.rightPosition,
					mod.shadow && styles.shadow
				)}
			>
				{children}
			</div>
		</div>
	)
}
