import { ILinkItemMenuProps } from '@/shared/constants.interface'
import { usePageViews } from '@/shared/hooks'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
	AdvertisingIconJSX,
	AnalyticsIconJSX,
	ChatIconJSX,
	CoinIconJSX,
	DiceIconJSX,
	DictionaryIconJSX,
	GeneralIconJSX,
	InfoBarJSX,
	MarketingIconJSX,
	MoneyIconJSX,
	PaymentsIconJSX,
	SchoolIconJSX,
	ShoppingIconJSX,
	SignUpIconJSX,
	UsersIconJSX
} from '@/shared/assets/navigation/svg'

import '@/Assets/sass/Components/Navigation.scss'
import { NavMenu } from './nav-menu'
import { useNewAppeals } from '@/features/chat/api'

export const dataNavTab: Array<ILinkItemMenuProps> = [
	{
		itemMenu: {
			itemName: 'statistics',
			image: null,
			active: false,
			eventsNumber: 0,
			subMenu: [
				{
					itemMenu: {
						itemName: 'general',
						image: <GeneralIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: '/statistics/general'
				},
				{
					itemMenu: {
						itemName: 'marketing',
						image: <MarketingIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: `/statistics/marketing`
				},
				{
					itemMenu: {
						itemName: 'payments',
						image: <PaymentsIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: `/statistics/payments`
				},
				// {
				// 	itemMenu: {
				// 		itemName: 'deposits',
				// 		image: <CoinIconJSX />,
				// 		active: false,
				// 		eventsNumber: 0
				// 	},
				// 	link: `/statistics/deposits`
				// },
				// {
				// 	itemMenu: {
				// 		itemName: 'rates',
				// 		image: <MoneyIconJSX />,
				// 		active: false,
				// 		eventsNumber: 0
				// 	},
				// 	link: `/statistics/rates`
				// },
				// {
				// 	itemMenu: {
				// 		itemName: 'games',
				// 		image: <DiceIconJSX />,
				// 		active: false,
				// 		eventsNumber: 0
				// 	},
				// 	link: `/statistics/games`
				// },
				{
					itemMenu: {
						itemName: 'users',
						image: <UsersIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: `/statistics/users`
				}
			]
		},
		link: '/statistics'
	},
	{
		itemMenu: {
			itemName: 'content',
			image: null,
			active: false,
			eventsNumber: 0,
			price: 0,
			subMenu: [
				{
					itemMenu: {
						itemName: 'school',
						image: <SchoolIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: '/content/school'
				},
				{
					itemMenu: {
						itemName: 'analytics',
						image: <AnalyticsIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: '/content/analytics'
				},
				{
					itemMenu: {
						itemName: 'blog',
						image: <SchoolIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: '/content/blog'
				},
				// {
				// 	itemMenu: {
				// 		itemName: 'dictionary',
				// 		image: <DictionaryIconJSX />,
				// 		active: false,
				// 		eventsNumber: 0
				// 	},
				// 	link: '/content/dictionary'
				// },
				{
					itemMenu: {
						itemName: 'shop',
						image: <ShoppingIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: '/content/shop'
				},
				{
					itemMenu: {
						itemName: 'advertising',
						image: <AdvertisingIconJSX />,
						active: false,
						eventsNumber: 0
					},
					link: '/content/advertising'
				},
				{
					itemMenu: {
						itemName: 'chat',
						image: <ChatIconJSX />,
						active: false,
						eventsNumber: 0,
						isPoint: true
					},
					link: '/content/chat'
				}
			]
		},
		link: '/content'
	}
]

export const Navigation = () => {
	const { data: news } = useNewAppeals(true)

	const [dataTabMenu, setDataTabMenu] =
		useState<Array<ILinkItemMenuProps>>(dataNavTab)

	usePageViews(dataTabMenu, setDataTabMenu)

	useEffect(() => {
		const res = dataTabMenu.map(item => {
			return {
				...item,
				itemMenu: {
					...item.itemMenu,
					subMenu: item.itemMenu.subMenu?.map(data => {
						if (data.itemMenu.itemName === 'chat')
							return {
								...data,
								itemMenu: { ...data.itemMenu, isPoint: !!news?.length }
							}
						return data
					})
				}
			}
		})
		setDataTabMenu(res)
	}, [Boolean(news?.length)])

	return (
		<div className='App-nav'>
			<div className='nav'>
				<Link to='/' className='nav-logo'>
					<InfoBarJSX />
				</Link>
				<NavMenu dataTabMenu={dataTabMenu} />
			</div>
			<div className='copywriting'>LEBAO BA© {new Date().getFullYear()}</div>
		</div>
	)
}
